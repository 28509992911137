import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RankingReportV2Service } from '../_services/ranking-report-v2.service';
import * as $ from 'jquery';
import { Injectable } from '@angular/core';
import psl from "psl";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { RankCheckerService } from '../_services/rank-checker.service';

declare var loadBookAppointment: Function;

function _window(): any {
  // return the native window obj
  return window;
}

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}

@Injectable({ providedIn: "root" })
export class WindowRef {

  get nativeWindow(): any {
    return _window();
  }
}
@Component({
  selector: 'app-ranking-report-latest',
  templateUrl: './ranking-report-latest.component.html',
  styleUrls: ['./ranking-report-latest.component.scss']
})
export class RankingReportLatestComponent implements OnInit, AfterViewInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public docId: string = 'a';
  stackedData: any;
  stackedOptions: any;
  value: number = 2.5;
  practiceInfoObj: any = {};
  loading: boolean = true;
  showError: boolean = false;
  rankingReportObj: any = {};
  rankingFlag: boolean = false;
  reviews: any;
  competitorsReportObj: any = {};
  competitorsReviewObject: any = [];
  finalGoogleRankingObject: any = [];
  competitorsFlag: boolean = false;
  pageInsightObj: any = {};
  starList: string[] = [];
  googleRankingArray = [];
  reputationArray = [];
  basicData: any;
  basicOptions: any;
  list: any;
  selected: number = 0;
  overallScore: number = 0;

  formatted_address: any;

  domainPageInsightScore: any;
  pageInsightNewObject: any;
  pageInsightReady: boolean = false;
  myPracticeName: string = "";
  practiceWebsite: string = "";
  activeIndex: number = 0;
  enablePageUpForMenu: boolean;
  screenName: string = "overview";
  screens: any = ['overview', 'googleRanking', 'reputation', 'websitePerformance']
  isAdmin: boolean;
  showReprocessCompetitorsActivity: boolean;
  excludeDomainList: any;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code == "ArrowUp" && this.activeIndex > 0 && this.enablePageUpForMenu) {
      this.activeIndex--;
      this.selected = this.activeIndex;
    }
    if (event.code == "ArrowDown" && this.activeIndex < 3 && this.enablePageUpForMenu) {
      this.activeIndex++;
      this.selected = this.activeIndex;
    }
  }



  constructor(
    public route: ActivatedRoute,
    private rankingReportService: RankingReportV2Service,
    private router: Router,
    private winRef: WindowRef,
    private _snackBar: MatSnackBar,
    private rankCheckerService: RankCheckerService
  ) {
    // this.setIgnoreDomainList();
    this.rankingReportService.reportUpdated$.subscribe(report => {
      // console.log("In Subscribe");
      this.practiceInfoObj = this.rankingReportService.practiceInfo;
      if (this.practiceInfoObj) {

        if (psl.isValid(this.practiceInfoObj['website_url'])) {
          this.practiceInfoObj['domain'].toLowerCase();
        } else {
          this.practiceInfoObj['domain'] = psl.get(new URL(this.practiceInfoObj['website_url'].toLowerCase()).hostname);
        }
        if ('practiceName' in this.practiceInfoObj) {
          this.myPracticeName = this.practiceInfoObj.practiceName
          this.practiceWebsite = this.practiceInfoObj?.website_url;
        }
        else if ('practice_name' in this.practiceInfoObj) {
          this.myPracticeName = this.practiceInfoObj.practice_name
          this.practiceWebsite = this.practiceInfoObj?.website_url;
        }
      }
      if (report.name === 'pageInsightNew') {
        this.rankingReportObj = report;
        this.pageInsightNewObject = this.rankingReportObj['results']; //.slice();
        console.log(this.pageInsightNewObject);
        this.pageInsightSetUpNew();
      }
      if (this.practiceInfoObj && 'address' in this.practiceInfoObj) {
        let address = this.practiceInfoObj.address
        this.formatted_address = address.city + " " + address.state + " " + address.country + " " + "-" + address.zipCode;
      }
      if (report.name == 'error') {
        if (report.errorIn == 'rank-checker') {
          this.router.navigateByUrl('');
        }
        else if (report.errorIn == 'resultDocError') {
          this.loading = false;
          this.showError = true
        }
      }
      if (report.name == 'rankings') {
        this.rankingReportObj = report;
        this.rankingFlag = true;
      }

      if (report.name == 'competitors') {
        if (this.practiceInfoObj.reviews)
          this.reviews = this.practiceInfoObj.reviews;
        this.competitorsReportObj = report;
        if (this.competitorsReportObj.status != "error") {
          this.competitorsReportObj.results.push(this.competitorsReportObj.analysis);
          if (psl.isValid(this.practiceInfoObj['website_url'])) {
            this.practiceInfoObj['domain'].toLowerCase();
          } else {
            this.practiceInfoObj['domain'] = psl.get(new URL(this.practiceInfoObj['website_url'].toLowerCase()).hostname);
          }
          this.googleRankingArray = this.competitorsReportObj.results.sort(function (a, b) {
            return b.weightedScore - a.weightedScore;
          });
          this.googleRankingArray.map(element => {
            element.weightedScore = Math.round(element.weightedScore)
            if (this.excludeDomainList.includes(element.domain)) {
              element.ignoredDomain = true;
              this.showReprocessCompetitorsActivity = true;
            }
            else {
              element.ignoredDomain = false;
            }
          });
          this.googleRankingArray = this.googleRankingArray.slice();
          // this.overallScore = this.overallScore + this.googleRankingArray[this.googleRankingArray.findIndex(ele => (ele.name === this.myPracticeName))]['weightedScore']
          this.overallScore = this.overallScore + this.competitorsReportObj.analysis['weightedScore'] + this.competitorsReportObj.analysis['relativeReviewScore']
          // -----stacked bar graph---
          let competitors_labels = this.competitorsReportObj.results.map(a => a.name);
          let googleRanks = this.competitorsReportObj.results.map(a => Math.round(a.weightedScore));
          let barGraphBackGroundColors = googleRanks.map(a => (a > 85 ? 'rgba(52,168, 83, 0.9)' : a > 50 ? 'rgba(251,188,5,0.9)' : 'rgba(234,68,55,0.9)'))
          let competitors_pos_1 = this.competitorsReportObj.results.map(a => a.metrics.organic.pos_1);
          let competitors_pos_2_3 = this.competitorsReportObj.results.map(a => a.metrics.organic.pos_2_3);
          let competitors_pos_4_10 = this.competitorsReportObj.results.map(a => a.metrics.organic.pos_4_10);
          // ---bar-graph----
          this.basicData = {
            labels: competitors_labels,
            datasets: [
              {
                label: 'Score',
                type: 'bar',
                backgroundColor: barGraphBackGroundColors,
                data: googleRanks
              },
            ]
          };

          this.basicOptions = {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            responsive: true,
            legend: {
              display: false,
              labels: {
                fontColor: 'white',
                fontSize: '10px'
              },
            },

            scales: {
              xAxes: [{
                stacked: true,
                gridLines: {
                  display: false
                },
                barPercentage: 0.4,
                ticks: {
                  callback: function (value) {
                    return value.substr(0, 20);//truncate
                  },
                  display: true,
                  autoSkip: true,
                  maxTicksLimit: 4,
                  minRotation: 270,
                  labelOffset: -40,
                  padding: -20,
                  // minRotation: 90,
                  // labelOffset: -30,
                  // padding:-180,
                  fontSize: 14,
                },
              }],
              yAxes: [{
                stacked: true
              }]
            }
          };

          this.stackedData = {
            labels: competitors_labels,
            datasets: [{
              // fontColor: "white",
              // labelMaxWidth: 10,
              // labelWrap: true,
              label: 'Keyowrds ranking on 1st position',
              type: 'bar',
              backgroundColor: '#575EB6',
              data: competitors_pos_1
            }, {
              label: 'Keywords ranking on 2nd to 3rd position',
              type: 'bar',
              backgroundColor: '#8D92E6',
              data: competitors_pos_2_3
            }, {
              label: 'Keywords ranking on 4th to 10th position',
              type: 'bar',
              backgroundColor: '#CBCFFF',
              data: competitors_pos_4_10
            }
            ]
          }
          this.stackedOptions = {
            tooltips: {
              mode: 'index',
              intersect: false
            },
            plugins: {
              datalabels: {
                align: 'left',
                anchor: 'end',
                color: 'white',
                font: {
                  weight: 'bold'
                }
              }
            },
            legend: {
              labels: {
                fontColor: "black",
                labelMaxWidth: 5,
                labelWrap: true,
              },
            },
            responsive: true,
            scales: {
              xAxes: [{
                stacked: true,
                barPercentage: 0.4,
                ticks: {
                  autoSkip: false,
                  // maxRotation: 0,
                  minRotation: 270,
                  labelOffset: -50,
                  padding: -20,
                  fontSize: 20
                },
              }],
              yAxes: [{
                stacked: true
              }]
            }
          };
          this.competitorsFlag = true;
        }
      }

      if (report.name == 'review') {
        if (this.practiceInfoObj.reviews)
          this.reviews = this.practiceInfoObj.reviews;
        this.competitorsReportObj = report;
        if (this.competitorsReportObj.status != "error") {
          this.reputationArray = this.competitorsReportObj.results.sort(function (a, b) {
            return b.relativeReviewScore - a.relativeReviewScore;
          });

          this.reputationArray.map(element => {
            element.relativeReviewScore = Math.round(element.relativeReviewScore)
            if (this.excludeDomainList.includes(element.domain)) {
              element.ignoredDomain = true;
              this.showReprocessCompetitorsActivity = true;
            }
            else {
              element.ignoredDomain = false;
            }
          });
          // this.overallScore = this.overallScore + this.reputationArray[this.reputationArray.findIndex(ele => (ele.name === this.myPracticeName))]['relativeReviewScore']
          this.loading = false
          this.competitorsFlag = true;
        }
      }

      if (report.name == 'competitorsReviews') {
        this.competitorsReviewObject = report;
        this.reviews = this.practiceInfoObj.reviews;
        if (this.competitorsReviewObject.results) {
          for (var i = 0; i < this.competitorsReviewObject.results.length; i++) {
            if (this.competitorsReviewObject.results[i]['user_ratings_total']) {
            }
            else {
              this.competitorsReviewObject.results[i]['user_ratings_total'] = null;
            }
          }
        }

        if (this.practiceInfoObj.reviews)
          this.reviews = this.practiceInfoObj.reviews;

        let newArr = this.competitorsReviewObject.results.sort(function (a, b) {
          if (!b.hasOwnProperty('user_ratings_total'))
            return -1;
          if (!a.hasOwnProperty('user_ratings_total'))
            return 1;
          return b['user_ratings_total'] - a['user_ratings_total']

        });
      }
    });
  }
  ngAfterViewInit(): void {
    // $('html').attr('data-wf-page', '614193bb08de336ce9f9ef7b'); //old id
    // $('html').attr('data-wf-page', '614862e228b43454df48c32b');
    $('html').attr('data-wf-page', '615456f3befa1af0a41ceac9');

    this.winRef.nativeWindow["Webflow"].destroy();
    this.winRef.nativeWindow["Webflow"].ready();
    this.winRef.nativeWindow["Webflow"].require('ix2').init();
    document.dispatchEvent(new Event('readystatechange'));
    loadBookAppointment()
  }
  ngOnInit(): void {
    this.setIgnoreDomainList();
    this.route.paramMap.subscribe(params => {
      this.docId = params.get('docId');
      let resp = this.rankingReportService.getResults(this.docId);
    });
    this.getQueryParameter();

  }

  startVideo(video) {
    video.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  }

  closeVideo(video) {
    video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  }
  async pageInsightSetUpNew() {
    await Promise.all(
      this.pageInsightNewObject.map(data => {
        if (!data.hasOwnProperty('domain')) {
          // console.log("domain not present");
          if (psl.isValid(data['website'])) {
            data['domain'].toLowerCase();
          } else {
            data['domain'] = psl.get(new URL(data['website'].toLowerCase()).hostname);
          }
        }
        // else{
        //   // console.log("domain present",data['domain'],this.practiceInfoObj.domain);

        // }
        if (this.excludeDomainList.includes(data.domain)) {
          data.ignoredDomain = true;
          this.showReprocessCompetitorsActivity = true;
        }
        else {
          data.ignoredDomain = false;
        }
        if (data && data.lighthouseResult && data.lighthouseResult.categories && data.lighthouseResult.categories.performance && data.lighthouseResult.categories.performance.score)
          data.lighthouseResult.categories.performance.score = Math.round((data.lighthouseResult.categories.performance.score) * 100)
        // if (data.website === this.practiceWebsite) {
        if (data.domain === this.practiceInfoObj.domain) {
          this.pageInsightObj = data;
          this.domainPageInsightScore = this.pageInsightObj?.lighthouseResult?.categories?.performance?.score ? this.pageInsightObj.lighthouseResult.categories.performance.score : 0;
          this.overallScore = this.overallScore + this.domainPageInsightScore;
          this.pageInsightObj.websiteImage = this.pageInsightObj?.lighthouseResult?.audits["final-screenshot"]?.details?.data;
          this.pageInsightObj.cardFirst = this.pageInsightObj?.lighthouseResult?.audits["first-contentful-paint"];
          this.pageInsightObj.cardSecond = this.pageInsightObj?.lighthouseResult?.audits["interactive"];
          this.pageInsightObj.cardThree = this.pageInsightObj?.lighthouseResult?.audits["speed-index"];
          this.pageInsightObj.cardFour = this.pageInsightObj?.lighthouseResult?.audits["total-blocking-time"];
          this.pageInsightObj.cardFive = this.pageInsightObj?.lighthouseResult?.audits["largest-contentful-paint"];
          this.pageInsightObj.cardSix = this.pageInsightObj?.lighthouseResult?.audits["cumulative-layout-shift"];
          if (!('displayValue' in this.pageInsightObj.cardFirst))
            this.pageInsightObj.cardFirst['displayValue'] = "s"
          if (!('displayValue' in this.pageInsightObj.cardSecond))
            this.pageInsightObj.cardSecond['displayValue'] = "s"
          if (!('displayValue' in this.pageInsightObj.cardThree))
            this.pageInsightObj.cardThree['displayValue'] = "s"
          if (!('displayValue' in this.pageInsightObj.cardFour))
            this.pageInsightObj.cardFour['displayValue'] = "s"
          if (!('displayValue' in this.pageInsightObj.cardFive))
            this.pageInsightObj.cardFive['displayValue'] = "s"
          if (!('displayValue' in this.pageInsightObj.cardSix))
            this.pageInsightObj.cardSix['displayValue'] = "s"
        }
      })).then(() => {
        this.pageInsightReady = true;
      })
  }

  parseInteger(displayvalue) {
    return parseInt(displayvalue);
  }

  scrollToElement(element: any, index: number): void {
    this.selected = index;
    this.activeIndex = index;
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    this.screenName = this.screens[this.selected]
  }

  isActive(item: number) {
    return this.selected === item;
  };

  move(selectedDiv: string) {
    if (selectedDiv === 'header') {
      this.enablePageUpForMenu = false;
    }
    else {
      this.enablePageUpForMenu = true;

    }
  }

  getQueryParameter() {
    this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        if (params.admin) {
          let parameterValueOfAdmin = params.admin.toLowerCase();
          if (parameterValueOfAdmin == 'true') {
            this.isAdmin = true;
            // this.showReprocessCompetitorsActivity = true;
          }
          else {
            this.isAdmin = false;
          }
        }
        else {
          this.isAdmin = false;
          //console.log("Parametr for admin not passes");
        }
      }
      );
  }

  async ReprocessCompetitors() {
    // console.log("reprocess competitors");
    this.loading = true;
    (await this.rankingReportService.reprocessCompetitors_v2(this.docId)).subscribe
      (res => {
        console.log(res);
        if (res['status'] == "Ok") {
          this.rankingReportService.getResults(this.docId);
          this.openSnackBar("Reprocessing Completed");
        }
        else {
          this.loading = false;
          this.openSnackBar("Something went wrong in reprocessing");
        }
      });
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  async addDomainToIgnoreList(data: any, type: string) {
    let url = data.domain;
    console.log('url:', url);
    if (this.googleRankingArray.length >= 0) {
      let googleRankingArrayIndex = this.googleRankingArray.findIndex(ele => ele.domain === url)
      if (googleRankingArrayIndex >= 0) {
        this.googleRankingArray[googleRankingArrayIndex]['ignoredDomain'] = true;
      }
    }
    if (this.reputationArray.length >= 0) {
      let reputationArrayIndex = this.reputationArray.findIndex(ele => ele.domain === url);
      if (reputationArrayIndex >= 0)
        this.reputationArray[reputationArrayIndex]['ignoredDomain'] = true;
    }
    if (this.pageInsightNewObject.length >= 0) {
      let pageInsightObjectIndex = this.pageInsightNewObject.findIndex(ele => ele.domain === url)
      if (pageInsightObjectIndex >= 0) {
        this.pageInsightNewObject[pageInsightObjectIndex]['ignoredDomain'] = true;
      }
    }

    if (type == "LOCAL") {
      let result = await this.rankCheckerService.addDomainToLocalIgnoreList(this.practiceInfoObj.domain, url);
      if (result['status'] == 'ok') {
        this.openSnackBar("Domain added to Local Ignore list successfully");
        this.showReprocessCompetitorsActivity = true;
      }
      if (result['status'] == 'exists') {
        this.openSnackBar("Domain already exists");
      }
    }
    else if (type == "GLOBAL") {
      let result = await this.rankCheckerService.addDomainToIgnoreList(url);
      if (result['status'] == 'ok') {
        this.openSnackBar("Domain added to ignore list successfully");
        this.showReprocessCompetitorsActivity = true;
      }
      if (result['status'] == 'exists') {
        this.openSnackBar("Domain already exists");
      }
    }

  }

  async removeDomainFromIgnoreList(data: any) {
    let url = data.domain;
    console.log('url:', url);
    if (this.googleRankingArray.length >= 0) {
      let googleRankingArrayIndex = this.googleRankingArray.findIndex(ele => ele.domain === url)
      if (googleRankingArrayIndex >= 0) {
        this.googleRankingArray[googleRankingArrayIndex]['ignoredDomain'] = false;
      }
    }
    if (this.reputationArray.length >= 0) {
      let reputationArrayIndex = this.reputationArray.findIndex(ele => ele.domain === url);
      if (reputationArrayIndex >= 0)
        this.reputationArray[reputationArrayIndex]['ignoredDomain'] = false;
    }
    if (this.pageInsightNewObject.length >= 0) {
      let pageInsightObjectIndex = this.pageInsightNewObject.findIndex(ele => ele.domain === url)
      if (pageInsightObjectIndex >= 0) {
        this.pageInsightNewObject[pageInsightObjectIndex]['ignoredDomain'] = false;
      }
    }
    let result = await this.rankCheckerService.removeDomainFromIgnoreList(this.practiceInfoObj.domain, url);
    this.openSnackBar("Removed from ignored list");

  }

  async setIgnoreDomainList() {
    this.excludeDomainList = [];
    this.excludeDomainList = await this.rankingReportService.getIgnoredDomainList();
    // console.log(this.excludeDomainList);
  }

}
