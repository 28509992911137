
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { environment } from "../../environments/environment";

import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class RankCheckerService {

  source: string = null;
  rankResult: any = {};
  token: any;
  constructor(
    private http: HttpClient,
  ) { }

  async getSettings() {
    let result = await firebase.firestore().collection("rankCheckerSettings").doc('config').get();
    return result.data();
  }
  getAccessToken() {
    let dialogflow = {
      scopes: [
        'https://www.googleapis.com/auth/plus.business.manage'
      ],
      email: "dialogflow-iykntn@mktgbot-181017.iam.gserviceaccount.com",
      key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCW/auLYWueIHR0\nxTe++LFNmf/DrbVKor1xzDX13MEFWES/TKIB4cXfTLGAfh+hcV7lGrr19fsNKnSZ\nr1+fRTjaWFdIcU2B3mr/eMNhwV6llSCG53xmEmt+YmbaDAT5hhUtE83VPrzzm7Ej\nEuoWCeeP6Rf8CBX8ZYw6ge2GjzsiKUsALfh6o9LFftr3MGwnS+OBgbdo5SuknDms\nqFwO9rFjohQjVtYU//KC4qzyDCIAjuV+KIbbEDusBFm18m6gKoVQTWU9Y92mKHwe\nGefiC6bmyFmIU3hwPIvSgUAATTmNJ1EKkRhgh3pn8Sf4ysxGSMOyUC42kHoKJfnc\nK9ofHPH/AgMBAAECggEAAJJFK+nadma5ChgU9ewK/7gtd5gYr4eeH+gd7ChBsrt6\nxx6LHma0UPwBT/MqO3oqWcOYIYYkxQ/8EPjQ69FfPbRH4EZNfMhSr1TNqHkK2qWN\n0fTeS6SWM4w7x2d0PV18hwckKEJshJ96NB/GMv8mmSzQomuFaccYmMAVHgU8vAxa\ndr2fUHSKjDBxWLKrC5C/p+b0Hfw0ZKAoD8MlsCBqnJofGTbwqGXQLAYd1L6Ytgxc\nmP10yNIb5WONN5O3s09zx4GgqmhRyJJbqZKQTHkjz80glK9KJjlJLA/pypvHwnwx\neOTRc8BVHjyG2J63q5VRWS7b/LknfhdD8wF7ZlW/gQKBgQDQVjQfAzdDlSq3Bhr7\ntKZUrXp1JHM24FZXu62YhVVC1xrwssx52zkPaXoUMeeg+3GfGU3pbKMqSoEXeCIC\nnTPj1n19+mRWKjpyBbiSkhOX8tWQDPXDClH/YRRX/6CpFpkzy+ckg8riqf+3fS3v\n8FJ4IFiQH/Nc+weroDLqDX+JGQKBgQC5iNrTMWEzmJi/quAG6pn5guIT4QQldUEL\nUMOW3ir+A4NRi1bhCc6On03oYLvGjEPVg8QjVstLtKnb6kTH2ow3MiqQhAu4HNDl\nAW/q86UvA37HnIgz1fuwNn+jVcLk1ZJJisBOHPWAcewxXRhumyLAuwsOAMpPYvsq\n24b4hAr+1wKBgDwy/IKNklVz79DM8dUTgRBsxDq/Go+byTc2otW0iUt0DnPIpm77\nmoRKjRzywqRwo4c+b5Xob0lUF/MpImKw/AJ0wvQ4+u8u6o5x+sBXd1PrtiwWopkG\nDWWGrrMyyUCyp/ggjhyt/Fz8lEh34mM3hQzzA8Q5hz+xxaig9LVOyzBZAoGAaLIX\neZGl3i3AFHhiTsTX+nqnBLy3UEPoXnBGzr2z2P82fS956SdVjhnLJtRfYd0I8lWW\nurJhqeja6SQ7h+OFuCfw6Pf6fPyVzISRGS5AClWYlx93pHidvIQkZpEgl20FTcak\nWe/xgXfH5ts3zEJyy/tAc7hycXXTcg3LDjXE1qUCgYBH9ATChLEXWsA28euFTkie\nq3UqnjYZdLM+vBENWaZfMcIzbsMkAt7VnQIyZlM0pgftnDq8sX8CBJ0NhrYC3RWM\nIY1cDINs85pu8VM2vaHH6Ad6sYaim2EfUke0cVCCRGZyHc9chjJQQSgPiM3Cvm2i\nrvprVFDb1DWrJ4dnWGHRIw==\n-----END PRIVATE KEY-----\n",
    };
    return firebase.functions().httpsCallable("getAccessToken")(dialogflow).then(resp => {
      return this.token = resp.data.token;
    });
  }

  readCSV(url: string) {
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
      })
    };
    this.http.get(url, header)
      .subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
  }

  fetchGmbLocations(accessToken: any, address: any, speciality: string, locality: String) {

    // console.log(accessToken);
    let header = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      })
    };
    let body = {
      "resultCount": 5,
      "location": {
        "locationName": address,
        "address": {
          "locality": locality
        }

      }
    };
    return this.http.post('https://mybusiness.googleapis.com/v4/googleLocations:search', body, header);

  }

  async addPracticeDetails(practiceDetails: any, ipapi: any, status: string) {
    if (ipapi)
      practiceDetails.ipapi = ipapi;
    practiceDetails.createdOn = moment().toDate();
    practiceDetails.status = status;
    return await firebase.firestore().collection('rank-checker').add(practiceDetails)
      .then(
        practiceDetails => {
          return practiceDetails.id;
        },
        error => {
          console.error(error);
        }
      );
  }

  async generateRankings(data: any) {
    data['reportUrl'] = `${environment.hostUrl}/ranking-report/${data['docId']}`
    console.log("generating v1 report", data);
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'Access-Control-Allow-Origin': '*'
      })
    };
    (firebase.functions().httpsCallable('rankingReport'))(data).then(resp => {
      this.rankResult = resp;
      console.log(this.rankResult);
    });
  }
  async generateRankingsV2(data: any) {
    data['reportUrl'] = `${environment.hostUrl}/ranking-report-v2/${data['docId']}`;
    console.log("generating v2 report", data);
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'Access-Control-Allow-Origin': '*'
      })
    };
    (firebase.functions().httpsCallable('rankingReportV2'))(data).then(resp => {
      this.rankResult = resp;
      console.log(this.rankResult);
    });
  }

  filterKeywords(data: any, type: String) {
    const result = data.filter(option => option.ranked_serp_element.serp_item.type.toLowerCase().includes(type));
    return result;
  }

  async updatePracticeDetails(docId: string, details: any, status: String) {
    console.log(details);
    details.status = status
    return await firebase.firestore().collection('rank-checker').doc(docId).update(details)
      .then(
        practiceDetails => {
          return practiceDetails;
        },
        error => {
          console.error(error);
        }
      );
  }

  async updateStatus(docId: string, status: any) {
    return await firebase.firestore().collection('rank-checker').doc(docId).update(status)
      .then(
        practiceDetails => {
          return practiceDetails;
        },
        error => {
          console.error(error);
        }
      );

  }

  getCopleteLocation() {
    return this.http.get('https://ipapi.co/json');
  }

  generateOtp() {
    let otp = Math.floor(100000 + Math.random() * 900000);
    return otp;
  }

  sendSms(messageObj: any) {
    // messageObj['acc_id'] = sessionStorage.getItem('current_acc_id');
    // console.log(messageObj);
    (firebase.functions().httpsCallable('sendSms'))(messageObj)
      .then(
        result => {
          // console.log(result);
          console.log('success');
        })
      .catch(error => {
        console.log(error);
      });
  }
  sendEmailVerificationOtp(email: string, otp: string, fullName: string) {
    let body = `<p>Hi ${fullName}, </p>
          <p><b>${otp}</b> is the One-Time Verification Code for Rank Checker. Please do not share this with anyone else. If not requested, please inform us.</p>
          <p>Best,<br/>
            The Mktg.Doctor Team<br/>
            +1 332.333.4020
          </p>`
    firebase.functions().httpsCallable('sendEmail')({
      "to_email": email,
      "subject": "Verification Code for Rank Checker",
      "body": body,
      "from": "appointments@nhansmedia.com"
    }).then(resp => {
      console.log(resp);
    },
      err => {
        console.log(err);
      })
  }

  async getTwilioCredentials() {
    let data = await (await firebase.firestore().collection('generalSettings').doc('config').get()).data();
    let twilioCreds = data?.twilio_accounts;
    return twilioCreds;
  }

  async getPreviousResult(doctId: any) {
    let result = await firebase.firestore().collection("rank-checker").doc(doctId).get();
    return result.data();
  }

  async updatePracticeDetails1(docId: string, details: any) {
    return await firebase.firestore().collection('rank-checker').doc(docId).update(details)
      .then(
        practiceDetails => {
          return practiceDetails;
        },
        error => {
          console.error(error);
        }
      );
  }

  async updateGmbSpeciality(docId: string, gmbSpeciality: string) {
    await firebase.firestore().collection('rank-checker').doc(docId).update({ "gmbSpeciality": gmbSpeciality });
  }

  async updateCampaignMonitorSubscriber(data) {
    await firebase.functions().httpsCallable('updateCampaignMonitorSubscriber')(data).then(resp => {
      console.log(resp);
    });
  }

  async addHubspotContact(properties, countryCode) {
    if (environment.production) {
      console.log("Adding contact in hubspot");
      firebase.functions().httpsCallable("createHubspotContact")({ properties: properties });
      let resp = await firebase.firestore().collection("accounts/T7f7d2VEGjqXmQvkHZ6h/contacts").where("email", "==", properties.email.toLowerCase()).get();
      console.log("Contact with same email", resp.docs.length)
      if (resp.docs.length == 0) {
        let contact = {
          active: true,
          countryCode: countryCode + "",
          created_on: new Date(),
          email: properties.email.toLowerCase(),
          first_name: properties.firstname,
          last_name: properties.lastname,
          full_phone: countryCode + properties.phone + "",
          phone: properties.phone,
          send_text_reminders: true,
          source: this.source
        }
        let contactSnap = await firebase.firestore().collection("accounts/T7f7d2VEGjqXmQvkHZ6h/contacts").add(contact);
        console.log(contactSnap.id)
      }
    }
  }

  async getAndStoreLanguageAndLocationDetail() {
    return await firebase.functions().httpsCallable('getLocationAndLanguage')();
    // let header = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json, text/plain, */*',
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
    //   })
    // };
    // return this.http.get('https://us-central1-mktgbot-181017.cloudfunctions.net/getLanguageAndLocations',header).toPromise();
  }

  async addDomainToIgnoreList(url: string) {
    let docRef = firebase.firestore().collection("generalSettings").doc('exclude_domain_list')
    let excludeDomainList = (await docRef.get()).data().domains;
    let isDomainExists = excludeDomainList.includes(url);
    if (!isDomainExists) {
      docRef.update({
        'domains': firebase.firestore.FieldValue.arrayUnion(url)
      })
      return { 'status': 'ok' }
    }
    else {
      console.log("Domains alrady exists");
      return { 'status': 'exists' }
    }
  }

  async addDomainToLocalIgnoreList(practice: string, url: string) {
    let docSnap = await firebase.firestore().collection('seo').where('url', '==', practice).get();
    console.log('doc:', docSnap.docs[0].id);
    let localDomains = docSnap.docs[0].data().ignoredDomains ? docSnap.docs[0].data().ignoredDomains : [];
    if (localDomains.indexOf(url) == -1) {
      localDomains.push(url);
      await docSnap.docs[0].ref.update({ "ignoredDomains": localDomains });
      return { 'status': 'ok' }
    }
    else {
      return { 'status': 'exists' }
    }
  }
  async removeDomainFromIgnoreList(practice: string, url: string) {
    let docRef = firebase.firestore().collection("generalSettings").doc('exclude_domain_list')
    let excludeDomainList = (await docRef.get()).data().domains;
    if (excludeDomainList.indexOf(url) != -1) {
      excludeDomainList.splice(excludeDomainList.indexOf(url), 1);
      await docRef.update({ "domains": excludeDomainList });
    }
    let docSnap = await firebase.firestore().collection('seo').where('url', '==', practice).get();
    console.log('doc:', docSnap.docs[0].id);
    let localDomains = docSnap.docs[0].data().ignoredDomains ? docSnap.docs[0].data().ignoredDomains : [];
    if (localDomains.indexOf(url) != -1) {
      localDomains.splice(localDomains.indexOf(url), 1);
      await docSnap.docs[0].ref.update({ "ignoredDomains": localDomains });
    }
  }

}
