import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-rank-checker-results',
  templateUrl: './rank-checker-results.component.html',
  styleUrls: ['./rank-checker-results.component.scss']
})
export class RankCheckerResultsComponent implements OnInit, OnChanges {

  @Input() rankings;
  rankingDetails:any=[];
  metricArray:any=[];
  topResultCount:number;
  rows=20;
  cols:any = [
   { header:'Ranked keywords',field:'keyword'},
   { header:'competition',field:'competition'},
   { header:'cost per click',field:'cpc'},
   { header:'Estimated traffic volume',field:'etv'},
   { header:'search volume',field:'search_volume'},
   { header:'Group',field:'type'},
   { header:'Group rank',field:'rank_group'},
   { header:'Rank',field:'rank_absolute'},
  //  {"competition":0.6428571343,"cpc":19.8308830261,"etv":1.6200000048,"keyword":"hair fall treatment clinic near me","rank_absolute":2,"rank_group":2,"search_volume":10.0,"type":"local_pack"}
  ];
  constructor() { }
  ngOnChanges(){
    this.rankingDetails = this.rankings;
  }
  ngOnInit(): void {
  }
  isString(val): boolean { return typeof val === 'string'; }
}
