import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-google-listing',
  templateUrl: './google-listing.component.html',
  styleUrls: ['./google-listing.component.scss']
})
export class GoogleListingComponent implements OnInit, OnDestroy {

  @Input() secondFormGroup: FormGroup;
  @Input() firstFormGroup: FormGroup;
  @Input() googleLocations;
  @Input() address;
  @Input() previousSelectedLocation;
  @Output() googleListingEmittor = new EventEmitter<any>();
  hidden: boolean = true;
  showConfirmDetails: boolean = false;
  cardSelection: boolean = true;
  selectedLocation = [];
  // address:any =[];
  flag = 0;

  newArry = [];
  constructor() { }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.newArry = this.googleLocations;
    localStorage.setItem("locations", JSON.stringify(this.newArry));

    if(this.previousSelectedLocation instanceof Array)
    this.selectedLocation=this.previousSelectedLocation;
  }

  selectCard(content, index) {
    content['badgeHidden'] = !content['badgeHidden'];
    this.newArry[index].badgeHidden = content['badgeHidden']
    this.flag = 0;
    for (var i = 0; i < this.googleLocations.length; i++) {
      if (!this.googleLocations[i]['badgeHidden']) {
        this.hidden = false;
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0)
      this.hidden = true;
    this.cardSelection = !this.cardSelection;

    if (content['badgeHidden']) {
      if (index !== -1)
      this.selectedLocation.splice(index, 1);
    } 

    if (!content['badgeHidden']) {
      this.selectedLocation.push(content);
    }
    // else {
    //   const index = this.selectedLocation.indexOf(content);
    //   if (index > -1)
    //     this.selectedLocation.splice(index, 1);
    // }
    console.log(this.selectedLocation);
    let data = {
      status: this.hidden,
      selectedLocation: this.selectedLocation
    }
    let location=[];
    localStorage.setItem("locations", JSON.stringify(this.newArry));
    this.googleListingEmittor.emit(data);

  }



}
