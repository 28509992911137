import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { RankCheckerService } from '../_services/rank-checker.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PracticeInformation } from "../_models/interfaces";
import { MatHorizontalStepper } from '@angular/material/stepper'
import { ActivatedRoute, Router } from '@angular/router';
import { AriaDescriber } from '@angular/cdk/a11y';
import { state } from '@angular/animations';
import { ConfirmationService } from 'primeng/api';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rank-checker',
  templateUrl: './rank-checker.component.html',
  styleUrls: ['./rank-checker.component.scss']
})

export class RankCheckerComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatHorizontalStepper;
  accessToken: any;
  address: any;
  speciality: any;
  speciality1: any;
  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  goNext: boolean = false;
  public specialityList: any = [];
  showResults: boolean = false;
  rankingsDocId: string;// ='GgyvQimGgg6jE4cD8MtM';
  raningResults: any;
  googleLocations: any;
  practiceInformation: PracticeInformation;
  showVerification: boolean = true;
  public practiceDetailsList = [];
  hidden: boolean = true;
  docId: any;
  selectedLocations: any;
  rankingSttings: any;
  locations: any;
  urlreg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;
  public country_code: string;
  isLocation: boolean;
  isCardSelected: boolean;
  googleLocationRenderedStatus: boolean;
  googleAddress: any;
  twilio_to: any = [];
  messageObj: any;
  clientIpapiObj: any = {};
  randomOTP: any;
  full_phone: any;
  noLocation: boolean = false;
  error: boolean = false;
  errorObj: any;
  showGmbListing: boolean = false;
  public countries: any = [];
  public previousLocalStorageObj: any = {};
  public rankcheckerObj: any = {};
  public loading: boolean = false;
  isFirstFormGroupValid: boolean;
  campaignMonitorSubscriberObj: any = {};
  languageaAndLocationInfo: any
  result: any;
  isEnglishLanguagePresent: boolean = false;
  languageaAndLocationInfoArray: any[];
  languageResponse: any;
  generateReport: string = "V2";
  country: any;
  checkEmailVerification: boolean = false;
  constructor(
    private _formBuilder: FormBuilder,
    private rankCheckerService: RankCheckerService,
    private http: HttpClient,
    public route: Router,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {


    this.firstFormGroup = this._formBuilder.group({
      speciality: ['', Validators.required],
      practiceName: ['', Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      practiceName: ['', Validators.required],
      streetAdress: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      country: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern(this.urlreg)]],
      language: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      fullName: ['', Validators.required],
      countryCode: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]],
      cellNumber: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$'), Validators.minLength(10), Validators.maxLength(10)]],
      declaration: [true, [Validators.required]],
    });

  }
  ngOnInit() {

    if (localStorage.getItem('docId')) {
      this.loading = true;

      this.confirmationService.confirm({
        message: "<center>You had already started filling the details.</center> Would you like to continue where you left off or start over?",
        accept: () => {
          this.docId = localStorage.getItem('docId');
          this.onRefereshRankChecker();
          this.setQueryPrameter();
        },
        reject: () => {
          this.loading = false;
          localStorage.clear();
          this.setQueryPrameter();
        }
      });
    }
    else
      this.setQueryPrameter();

    this.rankCheckerService.getSettings().then(
      (result) => {
        this.rankingSttings = result;
        // console.log(this.rankingSttings);
        //if (this.rankingSttings['enableGmbListing']) {
        let returnedObj = this.rankCheckerService.getAccessToken();
        returnedObj.then(data => {
          this.accessToken = data;
          //this.showGmbListing = true;
        });

        // }
        this.specialityList = result.specialities;
      });
    this.rankCheckerService.getCopleteLocation().subscribe(resp => {
      this.clientIpapiObj = resp;
      this.thirdFormGroup.controls.countryCode.patchValue(this.clientIpapiObj.country_calling_code);
      this.country_code = this.clientIpapiObj.country;
    });
    this.getCountries();
    this.getAndStoreLanguageAndLocationDetail();
  }

  async getAndStoreLanguageAndLocationDetail() {
    this.languageResponse = await (await this.rankCheckerService.getAndStoreLanguageAndLocationDetail())['data'];
    // console.log(this.languageResponse);
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  setQueryPrameter() {

    this.activatedRoute.queryParams.subscribe(async params => {
      console.log(params);
      this.rankCheckerService.source = params.source ? params.source : "website";
      this.rankcheckerObj.source = this.rankCheckerService.source;
      if (params.email && params.list) {
        this.campaignMonitorSubscriberObj.email = params.email;
        this.campaignMonitorSubscriberObj.status = "opened";
        this.campaignMonitorSubscriberObj.list = params.list;
        this.rankCheckerService.updateCampaignMonitorSubscriber(this.campaignMonitorSubscriberObj);
      }
      if (params.admin) {
        if (params.admin == "true") {
          this.checkEmailVerification = true;
          console.log("No need of OTP verification...")
          // this.checkOtpVerificationStatus(status);
        }
      } else {
        console.log("Need of OTP verification...")
      }
      if (params.name || params.cell || params.email) {
        this.thirdFormGroup.patchValue({
          fullName: params.name,
          cellNumber: params.cell,
          email: params.email,
        });
        if (params.name)
          this.rankcheckerObj.fullName = params.name;

        if (params.cell)
          this.rankcheckerObj.cellNumber = params.cell;

        if (params.email)
          this.rankcheckerObj.email = params.email;
        if (this.docId == undefined) {
          this.rankCheckerService.getCopleteLocation().subscribe(async resp => {
            this.clientIpapiObj = resp;
            this.thirdFormGroup.controls.countryCode.patchValue(this.clientIpapiObj.country_calling_code);
            this.country_code = this.clientIpapiObj.country;
            this.docId = await this.rankCheckerService.addPracticeDetails(this.rankcheckerObj, this.clientIpapiObj, "opend");
            localStorage.setItem('docId', this.docId);
          });
        }
        else
          await this.rankCheckerService.updatePracticeDetails1(this.docId, this.rankcheckerObj);
      }

    });

    this.activatedRoute.url.subscribe((url) => {
      if (url.length > 0) {
        this.generateReport = url[0].path.toUpperCase();
      }
      else {
        this.generateReport = 'V2';
      }
      console.log(this.generateReport);
    });
  }

  getGmbListing() {
    this.address = this.firstFormGroup['gmbname'];
    this.rankcheckerObj.gmbSpeciality = this.firstFormGroup.value.speciality.name;
    let returnedObj = this.rankCheckerService.fetchGmbLocations(this.accessToken, this.address, this.speciality, this.clientIpapiObj.country_name);
    returnedObj.subscribe(
      data => {
        returnedObj.toPromise().then(data => {
          // console.log(data);
          // console.log(data['googleLocations'][0].location.primaryCategory.displayName);
          // console.log(data['googleLocations'][0].location.websiteUrl);
          // for (var i = 0; i < data['googleLocations'].length; i++) {
          //   if (data['googleLocations'][i].location.websiteUrl == this.practiceInformation.website_url) {
          //     // this.rankcheckerObj.speciality = data['googleLocations'][i].location.primaryCategory.displayName;
          //     this.rankcheckerObj.gmbSpeciality = data['googleLocations'][i].location.primaryCategory.displayName;
          //     console.log(this.rankcheckerObj.gmbSpeciality)
          //     i++;
          //   }
          // }
          this.rankCheckerService.updateGmbSpeciality(this.docId, this.rankcheckerObj.gmbSpeciality);
          // console.log(this.practiceInformation.website_url);
          this.locations = data['googleLocations'];
          this.googleLocations = this.locations.map(function (el) {
            var obj = Object.assign({}, el);
            obj.badgeHidden = true;
            return obj;
          });
          let flag = 0
          if (this.googleLocations) {
            this.googleAddress = [];
            for (var i = 0; i < this.googleLocations.length; i++) {
              let addr = "";
              if (this.googleLocations[i]['location']['address']['addressLines']) {
                for (var j = 0; j < this.googleLocations[i]['location']['address']['addressLines'].length; j++) {
                  flag = 1;
                  if (this.googleLocations[i]['location']['address']['addressLines'][j] != undefined)
                    addr = addr + this.googleLocations[i]['location']['address']['addressLines'][j] + ' ';
                }

                if (this.googleLocations[i]['location']['address']['locality'])
                  addr = addr + ',' + this.googleLocations[i]['location']['address']['locality'];
                if (this.googleLocations[i]['location']['address']['administrativeArea'])
                  addr = addr + ',' + this.googleLocations[i]['location']['address']['administrativeArea'] + ' ';
                if (this.googleLocations[i]['location']['address']['postalCode'])
                  addr = addr + this.googleLocations[i]['location']['address']['postalCode'];
                addr = addr.replace(/  +/g, ' ');
                this.googleAddress.push(addr);
              }
            }

            if (flag == 0) {
              this.noLocation = true;
              this.hidden = false;
            }
          }
          else {
            this.noLocation = true;
            this.hidden = false;
          }
          this.isLocation = true;
          this.hidden = false;
          this.noLocation = false;
          // console.log(this.googleAddress);
        });
      },
      error => {
        // console.log('oops', error.error.error.message);
        this.errorObj = error;
        this.error = true;
        this.hidden = false;
        this.rankCheckerService.updateGmbSpeciality(this.docId, this.rankcheckerObj.gmbSpeciality);
      }
    );

    // // if(!this.googleLocations)
    // // {
    //     this.googleLocations = [{
    //       badgeHidden:true,
    //       location :{
    //         address:{
    //           badgeHidden:true,
    //           addressLines:['anc','sdsds','dsdsdsd','anc','sdsds','dsdsdsd','anc','sdsds','dsdsdsd','anc','sdsds','dsdsdsd',],
    //           administrativeArea:'MAHARASHTRA',
    //           locality:'pune',
    //           postalCode:'424605'
    //         },
    //       locationName:'Pandit Clinic Pune Maharashtra'
    //       }
    //     },
    //     {
    //       badgeHidden:true,
    //       location :{
    //         address:{
    //           badgeHidden:true,
    //           addressLines:['anc','sdsds','dsdsdsd'],
    //           administrativeArea:'MAHARASHTRA',
    //           locality:'pune',
    //           postalCode:'424605'
    //         },
    //       locationName:'Pandit Clinic Pune Maharashtra'
    //       }
    //     }
    //   ]
    // // }
  }

  getGoogleListing(value) {
    this.selectedLocations = value['selectedLocation'];
  }


  getRankings() {
    window.parent.postMessage(JSON.stringify({ event: "rank-checker", source: this.rankCheckerService.source }), "*");
    this.showVerification = false;
    let data = {
      docId: this.docId,
      domain: this.secondFormGroup.controls.website.value,//this.practiceInformation.website_url,
      country: this.practiceInformation.address.country
    }
    if (this.generateReport == "V1") {
      this.rankCheckerService.generateRankings(data).then(
        resp => {
          // console.log(resp);
        });
    }
    else {
      this.rankCheckerService.generateRankingsV2(data).then(
        resp => {
          // console.log(resp);
        });
    }

    let split = this.thirdFormGroup.controls.fullName.value.split(" ");
    let properties = {
      "email": this.thirdFormGroup.controls.email.value,
      "firstname": split[0] ? split[0] : "",
      "lastname": split.length > 1 && split[split.length - 1] ? split[split.length - 1] : "",
      "phone": this.thirdFormGroup.controls.cellNumber.value,
      "mobilephone": this.thirdFormGroup.controls.cellNumber.value,
      "address": this.practiceInformation.formatted_address,
      "city": this.secondFormGroup.controls.city.value,
      "state": this.secondFormGroup.controls.state.value,
      "country": this.secondFormGroup.controls.country.value,
      "zip": this.secondFormGroup.controls.zipCode.value,
      "practice_name": this.firstFormGroup.controls.practiceName.value,
      "speciality": this.firstFormGroup.controls.speciality.value.name,
      "website": this.practiceInformation.website_url,
      "source": this.rankCheckerService.source,
    }
    properties["report_link"] = this.generateReport == "V2" ? `${environment.hostUrl}/ranking-report-v2/${this.docId}` : `${environment.hostUrl}/ranking-report/${this.docId}`;
    properties["notes"] = this.generateReport == "V2" ? `${environment.hostUrl}/ranking-report-v2/${this.docId}` : `${environment.hostUrl}/ranking-report/${this.docId}`;
    console.log(properties);
    this.rankCheckerService.addHubspotContact(properties, this.thirdFormGroup.controls.countryCode.value)

  }

  async addPracticeDetails() {
    this.speciality = this.firstFormGroup.value.speciality;
    this.address = this.firstFormGroup.value.practiceName;
    if (this.selectedLocations)
      this.practiceInformation['googleLocations'] = this.selectedLocations;
    else
      this.practiceInformation['googleLocations'] = "No Listing Avalilabel"
    // console.log(this.practiceInformation);
    if (this.docId == undefined) {
      this.docId = await this.rankCheckerService.addPracticeDetails(this.practiceInformation, this.clientIpapiObj, 'initiated');
      localStorage.setItem('docId', this.docId);
    }
    else {
      await this.rankCheckerService.updatePracticeDetails(this.docId, this.practiceInformation, "initiated");
    }
    this.rankingsDocId = this.docId;
  }

  checkStatus(status) {
    this.isLocation = false;
    this.googleAddress = [];
    this.googleLocations = [];
    this.hidden = true;
    this.noLocation = false;
    this.error = false;
    if (status['isSpeciality'] == true && status['isPracticeName'] == true) {
      if (this.showGmbListing)
        this.getGmbListing();
      else
        this.hidden = false;
      // this.getGmbListing();
      this.googleLocationRenderedStatus = true;
    }
    else
      this.googleLocationRenderedStatus = false;
  }

  getPlacesApiAddress(address) {
    var StreetNumber;
    var StreetName;
    var City = null;
    var State = null;
    var Country = null;
    var PostalCode = null;
    var CountryCode = null;
    //this.getGmbListing(address);
    const addressComponents = address.address_components;
    // console.log(address);
    localStorage.setItem('adress', JSON.stringify(address));
    // StreetName = address.vicinity ? address.vicinity : address.formatted_address;
    StreetName = address.formatted_address;
    addressComponents.forEach(addressComponent => {
      const addressType = addressComponent.types[0]
      if (addressType == 'locality') {
        City = addressComponent.long_name;
      }
      else if (addressType == 'locality') {
        City = addressComponent.long_name;
      }
      if (addressType == 'administrative_area_level_1') {
        State = addressComponent.long_name
      }
      if (addressType == 'postal_code') {
        PostalCode = addressComponent.long_name
      }
      if (addressType == 'country') {
        Country = addressComponent.long_name;
        CountryCode = addressComponent.short_name;
        this.country = Country
        // this.getAndStoreLanguageAndLocationDetail(Country);
      }
    });
    this.practiceInformation = {
      place_id: address.place_id ? address.place_id : '',
      practice_name: address.name, //this.firstFormGroup.value.practiceName,
      speciality: this.firstFormGroup.value.speciality,
      website_url: address.website !== undefined ? address.website : '',
      international_phoneNumber: address.international_phone_number !== undefined ? address.international_phone_number : '',
      formatted_address: address.formatted_address ? address.formatted_address : '',
      types: address.types ? address.types : [],
      location_url: address.url ? address.url : '',
      address: {
        city: City,
        state: State,
        zipcode: PostalCode,
        country: Country,
      },
      country_code: CountryCode,
      location: address.name,
      googleLocations: this.selectedLocations,
      geoCo_ordinates: typeof (address.geometry.location.lat) == 'number' ? address.geometry.location.lat + ',' + address.geometry.location.lng : address.geometry.location.lat() + ',' + address.geometry.location.lng()
    };
    // console.log(this.practiceInformation)
    this.practiceInformation['ratings'] = address.rating ? address.rating : 0;
    this.practiceInformation['reviews'] = address.reviews ? address.reviews : [];
    this.practiceInformation['user_ratings_total'] = address.user_ratings_total ? address.user_ratings_total : 0;

    if (StreetName != undefined) {
      this.practiceInformation['address']['street'] = StreetName;
    }
    this.secondFormGroup.setValue({
      practiceName: this.practiceInformation.practice_name,
      streetAdress: this.practiceInformation.address.street ? this.practiceInformation.address.street : '',
      city: this.practiceInformation.address.city,
      state: this.practiceInformation.address.state,
      zipCode: this.practiceInformation.address.zipcode,
      country: this.practiceInformation.address.country,
      website: this.practiceInformation.website_url ? this.practiceInformation.website_url : "",
      language: this.practiceInformation.language ? this.practiceInformation.language : ""
    });
    // console.log(this.practiceInformation);
  }
  // get f1() { return this.firstFormGroup.controls; }
  saveContactInfo() {
    this.full_phone = this.thirdFormGroup.controls.countryCode.value + this.thirdFormGroup.controls.cellNumber.value
    let contactDetails = this.thirdFormGroup.value;
    this.rankCheckerService.updatePracticeDetails(this.docId, { contactInfo: contactDetails }, 'verification sent');
    this.showResults = true;

  }
  updatePracticeDetails() {
    let practiceData = this.secondFormGroup.value;
    this.practiceInformation.address.street = practiceData.streetAdress;
    this.practiceInformation.website_url = practiceData.website;
    this.practiceInformation.address.zipcode = practiceData.zipCode;
    this.practiceInformation.language = practiceData.language
    this.rankCheckerService.updatePracticeDetails(this.docId, this.practiceInformation, 'practice confirmed');
  }

  checkOtpVerificationStatus(status) {
    if (status.action == 'otpVerification' && status.verified == true) {
      this.showVerification = false;
      this.rankCheckerService.updateStatus(this.docId, { status: 'verified' });
      if (this.campaignMonitorSubscriberObj.list && this.campaignMonitorSubscriberObj.email) {
        this.campaignMonitorSubscriberObj.status = "verified";
        this.rankCheckerService.updateCampaignMonitorSubscriber(this.campaignMonitorSubscriberObj);
      }
      this.getRankings();
      let path = this.generateReport == 'V1' ? 'ranking-report' : 'ranking-report-v2'

      // this.route.navigate(['/ranking-report/' + this.docId])
      this.route.navigate([`/${path}/` + this.docId])

    }
    else {
      if (status.action == 'changeEmail') {
        this.showResults = false;
        this.stepper.selectedIndex = 2;
      }
    }
  }
  speciLityValidate(control: FormControl): { [s: string]: boolean } {
    if (this.specialityList.indexOf(control.value) == -1) {
      return {
        'nameIsForbidden': true
      }
    }
    return null;
  }

  getCountries() {
    return this.http.get<any>('assets/countries.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => {
        this.countries = data;
      });
  }

  countryCodeExists(dial_code) {
    let valid: boolean = false
    this.countries.some(function (option) {
      if (option.dial_code === dial_code)
        valid = true
    });
    return valid
  }

  validateCountryCode(control: FormControl): { [s: string]: boolean } {
    {
      if (!this.countryCodeExists(control.value)) {
        return {
          'nameIsForbidden': true
        }
      }
      return null;
    }
  }


  ///////new code

  onRefereshRankChecker() {
    this.rankCheckerService.getPreviousResult(this.docId).then(
      (result) => {
        this.rankcheckerObj = result;
        this.getPlacesApiAddress(JSON.parse(localStorage.getItem('adress')));
        if (this.rankcheckerObj.status == 'initiated') {
          this.firstFormGroupSetValue();
          this.stepper.selectedIndex = 1;
        }

        if (this.rankcheckerObj.status == 'practice confirmed') {
          this.firstFormGroupSetValue();
          this.secondFormGroupSetValue();
          this.stepper.next();
          this.stepper.next();
        }

        if (this.rankcheckerObj.status == 'verification sent') {
          this.firstFormGroupSetValue();
          this.secondFormGroupSetValue();
          this.thirdFormGroupSetValue();
          this.stepper.next();
          this.stepper.next();
        }

        this.loading = false;
        if (this.rankcheckerObj.status == 'verified' || this.rankcheckerObj.status == 'in-progress' || this.rankcheckerObj.status == 'completed') {
          this.route.navigate(['/ranking-report-v2/' + this.docId])
          // this.route.navigate(['/ranking-report/' + this.docId])
        }
      });

  }

  firstFormGroupSetValue() {
    this.isFirstFormGroupValid = true;
    this.firstFormGroup.setValue({
      speciality: this.rankcheckerObj.speciality,
      practiceName: this.rankcheckerObj.practiceName
    });
    if (localStorage.getItem('locations'))
      this.extractGmbAdresss(localStorage.getItem('locations'));
  }

  secondFormGroupSetValue() {
    this.secondFormGroup.setValue({
      practiceName: this.rankcheckerObj.practice_name,
      streetAdress: this.rankcheckerObj.address.street,
      city: this.rankcheckerObj.address.city,
      state: this.rankcheckerObj.address.state,
      zipCode: this.rankcheckerObj.address.zipCode,
      country: this.rankcheckerObj.address.country,
      website: this.rankcheckerObj.website_url,
      language: this.rankcheckerObj.language
    });
  }

  thirdFormGroupSetValue() {
    this.thirdFormGroup.patchValue({
      fullName: this.rankcheckerObj.contactDetails.fullName,
      cellNumber: this.rankcheckerObj.contactDetails.cellNumber,
      email: this.rankcheckerObj.contactDetails.email,
      countryCode: this.rankcheckerObj.contactDetails.countryCode,
    });
  }

  extractGmbAdresss(googleLocations) {
    this.googleLocations = JSON.parse(googleLocations)
    // console.log(this.googleLocations);
    if (this.googleLocations) {
      this.showGmbListing = true;
      this.googleLocationRenderedStatus = true;
      this.isLocation = true;
      this.hidden = false;
      this.googleAddress = [];
      for (var i = 0; i < this.googleLocations.length; i++) {
        let addr = "";
        if (this.googleLocations[i]['location']['address']['addressLines']) {
          for (var j = 0; j < this.googleLocations[i]['location']['address']['addressLines'].length; j++) {
            if (this.googleLocations[i]['location']['address']['addressLines'][j] != undefined)
              addr = addr + this.googleLocations[i]['location']['address']['addressLines'][j] + ' ';
          }

          if (this.googleLocations[i]['location']['address']['locality'])
            addr = addr + ',' + this.googleLocations[i]['location']['address']['locality'];
          if (this.googleLocations[i]['location']['address']['administrativeArea'])
            addr = addr + ',' + this.googleLocations[i]['location']['address']['administrativeArea'] + ' ';
          if (this.googleLocations[i]['location']['address']['postalCode'])
            addr = addr + this.googleLocations[i]['location']['address']['postalCode'];
          addr = addr.replace(/  +/g, ' ');
          this.googleAddress.push(addr);
        }
      }
    }
    // console.log(this.googleAddress);
  }

  async addPracticeDetails1() {
    this.rankcheckerObj.place_id = this.practiceInformation.place_id;
    this.rankcheckerObj.international_phoneNumber = this.practiceInformation.international_phoneNumber;
    this.rankcheckerObj.international_phoneNumber = this.practiceInformation.international_phoneNumber;
    this.rankcheckerObj.formatted_address = this.practiceInformation.formatted_address;
    this.rankcheckerObj.types = this.practiceInformation.types;
    this.rankcheckerObj.location_url = this.practiceInformation.location_url;
    this.rankcheckerObj.country_code = this.practiceInformation.country_code;
    this.rankcheckerObj.user_ratings_total = this.practiceInformation["user_ratings_total"] ? this.practiceInformation["user_ratings_total"] : 0;
    this.rankcheckerObj.reviews = this.practiceInformation["reviews"] ? this.practiceInformation["reviews"] : [];
    this.rankcheckerObj.ratings = this.practiceInformation.ratings ? this.practiceInformation.ratings : 0;
    this.rankcheckerObj.practiceName = this.firstFormGroup.value.practiceName;
    this.rankcheckerObj.geoCo_ordinates = this.practiceInformation.geoCo_ordinates;
    this.rankcheckerObj.speciality = this.firstFormGroup.value.speciality;
    if (this.selectedLocations instanceof Array && this.selectedLocations.length > 0)
      this.rankcheckerObj.googleLocations = this.selectedLocations;
    else
      this.rankcheckerObj.googleLocations = "No Listing Avalilabel"
    if (this.docId == undefined) {
      this.docId = await this.rankCheckerService.addPracticeDetails(this.rankcheckerObj, this.clientIpapiObj, 'initiated');
      localStorage.setItem('docId', this.docId);
    }
    else {
      this.rankcheckerObj.status = 'initiated';
      await this.rankCheckerService.updatePracticeDetails1(this.docId, this.rankcheckerObj);
    }

    if (this.campaignMonitorSubscriberObj.list && this.campaignMonitorSubscriberObj.email) {
      this.campaignMonitorSubscriberObj.status = "initiated";
      this.rankCheckerService.updateCampaignMonitorSubscriber(this.campaignMonitorSubscriberObj);
    }
    this.rankingsDocId = this.docId;
  }

  async updatePracticeDetails1() {
    // console.log("------->",this.secondFormGroup);
    let address: any = {};
    address.city = this.secondFormGroup.value.city;
    address.state = this.secondFormGroup.value.state;
    address.street = this.secondFormGroup.value.streetAdress;
    address.zipCode = this.secondFormGroup.value.zipCode;
    address.country = this.secondFormGroup.value.country;
    this.rankcheckerObj.practice_name = this.secondFormGroup.value.practiceName;
    this.rankcheckerObj.website_url = this.secondFormGroup.value.website;
    this.rankcheckerObj.address = address;
    this.rankcheckerObj.status = 'practice confirmed';
    this.rankcheckerObj.language = this.secondFormGroup.value.language;
    // this.rankcheckerObj.country_code = this.secondFormGroup
    await this.rankCheckerService.updatePracticeDetails1(this.docId, this.rankcheckerObj);

    if (this.campaignMonitorSubscriberObj.list && this.campaignMonitorSubscriberObj.email) {
      this.campaignMonitorSubscriberObj.status = "practice confirmed";
      this.rankCheckerService.updateCampaignMonitorSubscriber(this.campaignMonitorSubscriberObj);
    }
  }

  saveContactInfo1() {
    this.full_phone = this.thirdFormGroup.controls.countryCode.value + this.thirdFormGroup.controls.cellNumber.value
    this.rankcheckerObj.contactDetails = this.thirdFormGroup.value;
    this.rankcheckerObj.status = 'verification sent'
    this.rankCheckerService.updatePracticeDetails1(this.docId, this.rankcheckerObj);
    this.showResults = true;
    if (this.campaignMonitorSubscriberObj.list && this.campaignMonitorSubscriberObj.email) {
      this.campaignMonitorSubscriberObj.status = "verification sent";
      this.rankCheckerService.updateCampaignMonitorSubscriber(this.campaignMonitorSubscriberObj);
    }
    if (this.checkEmailVerification) {
      let status = {
        action: 'otpVerification',
        verified: true
      }
      this.checkOtpVerificationStatus(status);
    }
  }

  onStepChange(event) {
    if (event.previouslySelectedIndex == 0) {
      if (this.firstFormGroup.valid)
        this.addPracticeDetails1();
    } else if (event.previouslySelectedIndex == 1) {
      if (this.secondFormGroup.valid)
        this.updatePracticeDetails1();
    }
  }
}
