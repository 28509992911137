import { AfterContentInit, AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {  FormBuilder, Validators, AbstractControl, FormControl, FormArray } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { RankCheckerService } from '../../_services/rank-checker.service';
import { PracticeInformation } from "../../_models/interfaces";
// import { flattenDiagnosticMessageChain } from '@angular/compiler-cli';

@Component({
  selector: 'app-confirm-practice-details',
  templateUrl: './confirm-practice-details.component.html',
  styleUrls: ['./confirm-practice-details.component.scss']
})
export class ConfirmPracticeDetailsComponent implements OnInit,AfterViewInit {

  @Input() secondFormGroup: FormGroup;
  @Input() firstFormGroup: FormGroup;
  @Input() googleLocations;
  @Input() country_code: string;
  @Input() languageResponse:any;
  @Input() country:any;
  @Input() practiceInformation
  locations: any;
  hidden: boolean = true;
  showConfirmDetails: boolean = false;
  flag = 0;
  language:any={'language_name':'','language_code':''};
  available_languages: any;
  isEnglishLanguagePresent: boolean = false;
  showDropDownForLanguage: boolean;

  constructor(public rankCheckerService: RankCheckerService) {}

  ngOnChanges(changeRecord: SimpleChanges) {
    this.showDropDownForLanguage = false;
    console.log('onChange fired');
    console.log(changeRecord);
    if(changeRecord['country']){
      if(!changeRecord['country']['firstChange']){
        this.getCountry(changeRecord['country']['currentValue']);
        // console.log("Chnaging");
      }
    }
    else if(changeRecord['practiceInformation'])
    {
      if(!changeRecord['practiceInformation']['currentValue']['firstChange']){
        this.getCountry(changeRecord['practiceInformation']['currentValue']['address']['country']);
        // console.log("Chnaging");
      }
    }
  }

  ngAfterViewInit(): void {
    // console.log(this.secondFormGroup);
    // console.log("In");
    // this.getCountry(this.secondFormGroup.value.country);
  }

  ngOnInit(): void {
    // console.log(this.secondFormGroup);
    this.languageResponse.resp = this.languageResponse.resp.sort((a, b) => a.location_name.localeCompare(b.location_name));
    this.getCountry(this.secondFormGroup.value.country);
    // this.secondFormGroup.get('country').valueChanges.subscribe(data=>{
    //   this.getCountry(this.secondFormGroup.value.country);
    // })
  }

  selectCard(content) {
    content['badgeHidden'] = !content['badgeHidden'];
    this.flag = 0;
    for (var i = 0; i < this.googleLocations.length; i++) {
      if (!this.googleLocations[i]['badgeHidden']) {
        this.hidden = false;
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0)
      this.hidden = true;
    // console.log(this.googleLocations);
  }

  showForm() {
    this.showConfirmDetails = true;
  }

  validateZipCode() {
    if (!Number(this.secondFormGroup.controls.zipCode.value)) {
      let str = this.secondFormGroup.controls.zipCode.value
      str = str.substring(0, str.length - 1);
      this.secondFormGroup.controls.zipCode.patchValue(str);
    }
  }


  getCountry(country){
    console.log(country)
    if(country){      
      this.showDropDownForLanguage = false;
      this.secondFormGroup.controls.country.patchValue(country);
      let location_index = this.languageResponse.resp.findIndex(item=>item['location_name'] === country);
      // console.log(this.languageResponse.resp[location_index]);
      this.available_languages = this.languageResponse.resp[location_index].available_languages;
      console.log(this.available_languages);
      this.available_languages = this.available_languages.sort((a, b) => parseInt(b.keywords) - parseInt(a.keywords));
      console.log(this.available_languages);
      let language_index = this.available_languages.findIndex(item=>item['language_name'] === "English");
      // console.log(language_index);
      if(this.available_languages.length>1){
        if(language_index>=0){
          this.showDropDownForLanguage = false;
          this.secondFormGroup.controls.language.patchValue({'language_name':this.available_languages[language_index]['language_name'],'language_code':this.available_languages[language_index]['language_code']});
          // console.log(this.secondFormGroup);
        }
        else{
          this.showDropDownForLanguage = true;
          this.secondFormGroup.controls.language.patchValue({'language_name':this.available_languages[0]['language_name'],'language_code':this.available_languages[0]['language_code']});
        }
      }
      else if(this.available_languages.length == 1){
        this.showDropDownForLanguage = false;
        this.secondFormGroup.controls.language.patchValue({'language_name':this.available_languages[0]['language_name'],'language_code':this.available_languages[0]['language_code']});
        // console.log(this.secondFormGroup);
      }
    }
    else
      console.log('Country not valid')
  }

  getLanguage(language){
    let language_index = this.available_languages.findIndex(item=>item['language_name'] === language);
    this.secondFormGroup.controls.language.patchValue({'language_name':this.available_languages[language_index]['language_name'],'language_code':this.available_languages[language_index]['language_code']});
    console.log(this.secondFormGroup);

  }
  get f2() { return this.secondFormGroup.controls; }
}
