<div [formGroup]="firstFormGroup" fxLayout="column" autocomplete="off" autofill="off">
    <section class="py-3" fxFlex="50%">
        <mat-form-field class="mx-2" fxFlex="100%">
            <input type="text" placeholder="Select Speciality" aria-label="Number" matInput formControlName="speciality"
                [matAutocomplete]="auto" (input)="onSpecialityChange($event.target.value)" required>
            <mat-autocomplete (optionSelected)='getSpeciality($event.option.value)' autoActiveFirstOption
                #auto="matAutocomplete" [displayWith]='displaySecialityFn'>
                <mat-option *ngFor="let option of specialityFilteredOptions | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f1.speciality.invalid">Please Select Proper Practice
            </mat-error>
        </mat-form-field>
    </section>
    <section class="py-3" fxFlex="50%">
        <mat-form-field class="mx-2" fxFlex="100%">
            <input matInput #addressInput ngx-google-places-autocomplete [options]="options"
                [(ngModel)]="firstFormGroup.value.practiceName" (input)="onPracticeChange($event.target.value)"
                #placesRef="ngx-places" placeholder="Enter your Practice name" formControlName="practiceName" required
                (onAddressChange)="handleAddressChange($event)" />
            <mat-error *ngIf="f1.practiceName.invalid && (f1.practiceName.dirty || f1.practiceName.touched)">
                This is required field</mat-error>
            <!-- =======
            <input type="text" placeholder="Enter your Practice name" aria-label="Number" matInput
                formControlName="practiceName" [matAutocomplete]="auto1" required>
            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"  [displayWith]="displayPracticeFn">
                <mat-option *ngFor="let option of practiceFilteredOptions | async" [value]="option">
                    <i class="fas fa-map-marker-alt"></i><b>{{option.practiceName}}, </b>{{option.streetAdress}},<span style="font-size:small;">{{option.city +" , " + option.state+"."}}</span>
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f1.practiceName.invalid && (f1.practiceName.dirty || f1.practiceName.touched)">
                This is required field</mat-error>
>>>>>>> 7ea7828898dd09dd67ab5dc6837a1603eed3b5a6 -->
        </mat-form-field>
    </section>
</div>

<!-- <div class="row" *ngIf="this.status.isSpeciality && this.status.isPracticeName">
    <div class="row" *ngIf="(isLocation && googleLocations) else loading">
        <app-google-listing [googleLocations]="googleLocations" (googleListingEmittor)="getGoogleListing($event)"></app-google-listing>
    </div>
    <ng-template #loading>
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
    </ng-template>
</div> -->