import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/firestore";
import { Subject } from 'rxjs/internal/Subject';
import psl from "psl";
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RankingReportService {

  reportId: string;
  resultDocRef;
  public practiceInfo: any;

  overallRankingsAnalysis: {};
  rankings: boolean = false;
  organic_keywords = [];
  localPac_keywords = [];
  featuredSnippets_keywords = [];
  paid_keywords = [];

  //for storing top page results
  toppages: boolean = false;
  overall_TopPages_analysis = {};
  top_pages_results = []

  //for storing competitors results
  competitors: boolean = false;
  overall_competitors_analysis = {};
  competitors_results = [];

  // creating new observable for listning updates of reportData
  private reportData = new Subject<any>();
  reportUpdated$ = this.reportData.asObservable();
  snapListner;
  onPage: boolean = false;
  onPageSummary: any = {};
  pageInsights: boolean = false;
  pageInsightsResults: any = {};
  resumeFetching = false;
  competitorsReviews: boolean = false;
  competitorReviewsResults: any = [];
  localPack: boolean = false;

  constructor(
    private http: HttpClient,
  ) {

  }

  async getResultsCollectionRef(url, resultsDocId) {
    let seoColSnap = await firebase.firestore().collection('seo').where('url', '==', url).get();
    if (seoColSnap.empty) {
      this.reportData.next({ name: "error", errorIn: "resultDocError" });
    }
    else {
      let seoColRef = await seoColSnap.docs[0].ref;
      let snap = await seoColRef.collection('results').doc(resultsDocId).get()
      // console.log("in geting document reference");
      if (snap.exists) {
        // console.log("document reference exist");
        this.resultDocRef = await snap.ref;
      }
      else {
        // console.log("document reference not exist");
        this.reportData.next({ name: "error", errorIn: "resultDocError" });
      }
    }
    // .ref;
  }

  getResults(docId) {
    this.reportId = docId;
    this.snapListner = firebase.firestore().collection('rank-checker').doc(this.reportId).onSnapshot(async docsnap => {
      // console.log(docsnap);
      if (docsnap.exists) {
        // }
        let data = docsnap.data();
        // console.log(data);
        this.practiceInfo = data;
        let url = null;
        // console.log("PSL", psl.isValid(data['website_url']))
        if (psl.isValid(data['website_url'])) {
          url = data['website_url'].toLowerCase();
        } else {
          url = psl.get(new URL(data['website_url'].toLowerCase()).hostname);
        }
        // let url = (new URL(data['website_url'])['hostname']).replace("www.", "");
        if (data['resultsDocId'] !== undefined) {
          // console.log(this.resultDocRef);
          if (this.resultDocRef == undefined) {
            await this.getResultsCollectionRef(url, data['resultsDocId'])
          }

          if (this.resultDocRef !== undefined && (data.resultsStatus?.rankings == "completed" || data.resultsStatus?.rankings == "error") && this.rankings == false) {
            // console.log('rankings fetching');
            let overallRankingsAnalysisSnap = (await this.resultDocRef.collection('rankings').get());
            // if (overallRankingsAnalysisSnap.empty) {
            if (data.resultsStatus?.rankings == "error") {
              // console.log("No rankings present");
              this.rankings = true;
              this.reportData.next(
                {
                  name: "rankings",
                  analysis: this.overallRankingsAnalysis,
                  status: "error"
                  // results: {
                  //   organic: this.organic_keywords,
                  //   localpack: this.localPac_keywords,
                  //   featureSnippet: this.featuredSnippets_keywords,
                  //   paid: this.paid_keywords
                  // }
                }
              );
            }
            else {
              let overallRankingsAnalysisDoc = overallRankingsAnalysisSnap.docs[0];
              this.overallRankingsAnalysis = overallRankingsAnalysisDoc.data();

              // this.organic_keywords =( await overallRankingsAnalysisDoc.ref.collection('organic_keywords')
              //                         .get()).docs.map(doc=> doc.data());

              // this.localPac_keywords = (await overallRankingsAnalysisDoc.ref.collection('local_pack_keywords')
              //   .get()).docs.map(doc => doc.data());

              // this.featuredSnippets_keywords = ( await overallRankingsAnalysisDoc.ref.collection('featured_snippets').get()).docs.map(doc=> doc.data());

              // this.paid_keywords = ( await overallRankingsAnalysisDoc.ref.collection('paid_keywords').get()).docs.map(doc=> doc.data());
              // console.log("rankings fetched");
              this.rankings = true;
              this.reportData.next(
                {
                  name: "rankings",
                  analysis: this.overallRankingsAnalysis,
                  status: "completed"
                  // results: {
                  //   organic: this.organic_keywords,
                  //   localpack: this.localPac_keywords,
                  //   featureSnippet: this.featuredSnippets_keywords,
                  //   paid: this.paid_keywords
                  // }
                }
              );
              // if (this.rankings && this.competitors && this.toppages) {
              //   this.resumeFetching = true;
              // }
            }
          }
          if (this.resultDocRef !== undefined && data.resultsStatus?.localPack == true && this.localPack == false) {
            // console.log('fetching localpack');
            let overallRankingsAnalysisSnap = (await this.resultDocRef.collection('rankings').get());
            if (overallRankingsAnalysisSnap.empty) {
              // console.log("No Local Pack Result");
              this.localPack = true;
              this.reportData.next(
                {
                  name: "localPack",
                  localpack: this.localPac_keywords,
                }
              );
            }
            else {
              let overallRankingsAnalysisDoc = overallRankingsAnalysisSnap.docs[0];
              this.overallRankingsAnalysis = overallRankingsAnalysisDoc.data();
              this.localPac_keywords = (await overallRankingsAnalysisDoc.ref.collection('local_pack_keywords')
                .get()).docs.map(doc => doc.data());
              // console.log("localPack fetched");
              this.localPack = true;
              this.reportData.next(
                {
                  name: "localPack",
                  localpack: this.localPac_keywords,
                }
              );
              if (this.rankings && this.competitors && this.toppages) {
                this.resumeFetching = true;
              }
            }
          }
          if (this.resultDocRef !== undefined && (this.practiceInfo.resultsStatus?.competitors == "completed" || this.practiceInfo.resultsStatus?.competitors == "error") && this.competitors == false) {
            // console.log('competitors fetching');
            let overallCompititorAnalysisSnap = (await this.resultDocRef.collection('competitors_analysis').get());
            // if (overallCompititorAnalysisSnap.empty) {
            if (this.practiceInfo.resultsStatus?.competitors == "error") {
              // console.log("No competitors present");
              this.reportData.next({ name: "competitors", status: "error" })
            }

            else {
              let overallCompititorAnalysisDoc = overallCompititorAnalysisSnap.docs[0];
              this.overall_competitors_analysis = overallCompititorAnalysisDoc.data();

              this.competitors_results = (await overallCompititorAnalysisDoc.ref.collection('competitors_analysis-results')
                // .orderBy('metrics.organic.count', 'desc')
                .limit(10)
                .get()).docs.map(doc => doc.data());
              console.log('competitors fetched',this.competitors_results);
              this.competitors = true;
              this.reportData.next({ name: "competitors", analysis: this.overall_competitors_analysis, results: this.competitors_results, status: "completed" });
            }
            // this.competitors = true;
            // this.reportData.next({ name: "competitors", analysis: this.overall_competitors_analysis, results: this.competitors_results, status: "completed" });
            // if (this.rankings && this.competitors && this.toppages) {
            //   this.resumeFetching = true;
            // }
          }
          if (this.resultDocRef !== undefined && data.resultsStatus?.top_pages == true && this.toppages == false) {
            // console.log('top pages fetching', data.resultsStatus?.top_pages);
            let overallToppageAnalysisSnap = (await this.resultDocRef.collection('top-page').get());
            if (overallToppageAnalysisSnap.empty) {
              console.log("No top pages present");
            }
            else {
              let overallToppageAnalysisDoc = overallToppageAnalysisSnap.docs[0];
              this.overall_TopPages_analysis = overallToppageAnalysisDoc.data();
              this.top_pages_results = (await overallToppageAnalysisDoc.ref.collection('top-pages-results')
                .orderBy('metrics.organic.count', 'desc')
                .limit(10)
                .get()).docs.map(doc => doc.data());
              console.log("top pages fetched");
            }
            this.toppages = true;
            this.reportData.next({ name: "top-pages", analysis: this.overall_TopPages_analysis, results: this.top_pages_results });
            if (this.rankings && this.competitors && this.toppages) {
              this.resumeFetching = true;
            }
          }

          if (this.resultDocRef !== undefined && data.resultsStatus?.pageInsights == true && this.pageInsights == false && this.rankings) {
            // console.log('page insights fetching', data.resultsStatus?.pageInsights);
            let pageInsightsSnap = (await this.resultDocRef.collection('on_page_results')
              .where('type', '==', 'pageInsights').get());
            if (pageInsightsSnap.empty) {
              // console.log("No page insights present");
              this.pageInsights = true;
              this.reportData.next({ name: "pageInsights", analysis: {}, results: this.pageInsightsResults });
            }
            else {
              this.pageInsightsResults = pageInsightsSnap.docs[0].data();
              // console.log("page insights  fetched");
              this.pageInsights = true;
              this.reportData.next(
                { name: "pageInsights", analysis: {}, results: this.pageInsightsResults }
              );
            }
          }

          if (this.resultDocRef !== undefined && (data.resultsStatus?.onPageSummary == 'in-progress' || data.resultsStatus?.onPageSummary == 'completed') && this.onPage == false) {
            // console.log('on page summary fetching', data.resultsStatus?.onPageSummary);
            if (data.resultsStatus.onPageSummary == 'completed') {
              let onPageSnap = this.resultDocRef.collection('on_page_results')
                .where('type', '==', 'pageSummary')
                .onSnapshot(querySnap => {
                  console.log(querySnap);
                  if (querySnap.empty) {
                    querySnap.docChanges().forEach(change => {
                      console.log(change.type);
                      console.log(change.doc.data());
                      this.onPageSummary = querySnap.docs[0].data();
                      this.reportData.next({ name: "on-page", analysis: {}, results: this.onPageSummary, status: 'in-progress' });
                      this.onPage = true
                    })
                  }
                  else {
                    console.log(querySnap.docs);
                    this.onPageSummary = querySnap.docs[0].data();
                    console.log(this.onPageSummary);
                    this.reportData.next({ name: "on-page", analysis: {}, results: this.onPageSummary, status: 'completed' });
                    console.log("pushed next ");
                    this.onPage = true
                    // onPageSnap();
                  }
                })
            }
            else {
              // console.log("page summary in progress");
              this.reportData.next({ name: "on-page", analysis: {}, results: this.onPageSummary, status: 'in-progress' });

            }
            // let onPageSummarySnap = (await this.resultDocRef.collection('on_page_results').get());
            // if(onPageSummarySnap.empty){
            //   console.log("onPage Summary in progress");
            // }
            // else{
            //   let onPageSummaryDoc =onPageSummarySnap.docs[0];
            //   this.onPageSummary = onPageSummaryDoc.data();
            //   console.log("on page summary fetched");
            // }
            // this.reportData.next({name:"on-page",analysis:{},results:this.onPageSummary});
            // this.onPage = true;
          }
          if (this.resultDocRef !== undefined && data.resultsStatus?.competitorsReviews == true && this.competitorsReviews == false) {
            console.log('competitors Reviews fetching');
            let competitorsReviewsSnap = (await this.resultDocRef.collection('competitors_reviews').orderBy('user_ratings_total', 'desc').get());
            //let competitorsReviewsSnap = (await this.resultDocRef.collection('competitors_reviews').get());
            // .orderBy('user_ratings_total','desc')
            if (competitorsReviewsSnap.empty) {
              console.log("No competitors reviews present");
            }
            else {

              this.competitorReviewsResults = competitorsReviewsSnap.docs.map(doc => doc.data());

              console.log('competitors reviews fetched', this.competitorReviewsResults);
            }
            this.competitorsReviews = true;
            this.reportData.next({ name: "competitorsReviews", analysis: {}, results: this.competitorReviewsResults });

          }

        }

      }
      else {
        console.log("no document exist for listing");
        this.reportData.next({ name: "error", errorIn: 'rank-checker', erroMsg: 'rank-checker document not present' });
      }


    },
      err => {
        console.log("error", err);
        // this.reportData.next({name:"err",analysis:{},results:[]});
        return err;
      })

  }

  async reGenerateCompetitors(docId:string){
    return await firebase.functions().httpsCallable('reGenerateRankingReport')({
      "docId":docId
    });
  }

  async setCompetitors(seoId:string,resultId:string){
    await firebase.firestore().collection('seo').doc(seoId).collection('results').doc(resultId).onSnapshot(async(snapshot)=>{
      console.log(snapshot.data())
      this.resultDocRef = snapshot.ref;
      if (this.resultDocRef !== undefined) {
        let overallCompititorAnalysisSnap = (await this.resultDocRef.collection('competitors_analysis').get());
        let overallCompititorAnalysisDoc = overallCompititorAnalysisSnap.docs[0];
        this.overall_competitors_analysis = overallCompititorAnalysisDoc.data();
        // this.competitors_results = (await overallCompititorAnalysisDoc.ref.collection('competitors_analysis-results').get()).docs.map(doc => doc.data());
        this.competitors_results = (await overallCompititorAnalysisDoc.ref.collection('competitors_analysis-results')
        .where('ignored','==',false)
        .limit(10)
        .get()).docs.map(doc => doc.data());
        this.reportData.next({ name: "competitors", analysis: this.overall_competitors_analysis, results: this.competitors_results, status: "completed" });
      }
    });
  }

  async getIgnoredDomainList(){
    let docRef = await firebase.firestore().collection("generalSettings").doc('exclude_domain_list')
    let excludeDomainList = await (await docRef.get()).data().domains;
    return excludeDomainList;
  }

}
