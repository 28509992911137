// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDESCcJfBOKIPXA0Sxt3VEV1GXyTcVSzQU",
    authDomain: "mktgbot-181017.firebaseapp.com",
    databaseURL: "https://mktgbot-181017.firebaseio.com",
    projectId: "mktgbot-181017",
    storageBucket: "mktgbot-181017.appspot.com",
    messagingSenderId: "797002870076",
  },
  hostUrl: "https://mktg-rank-checker.herokuapp.com",
  apptURL: "http://appointmentdev.herokuapp.com",
  serverURL: "https://mktg-bot-dev-server.herokuapp.com",
  pythonServerUrl: "https://mktg-seo-service-dev.herokuapp.com",
  cloudFunctionServerUrl: "https://us-central1-mktgbot-181017.cloudfunctions.net",
  // pythonServerUrl:"http://localhost:5000",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
