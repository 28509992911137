import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/firestore";
import { Subject } from 'rxjs/internal/Subject';
import psl from "psl";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RankingReportV2Service {

  reportId: string;
  resultDocRef;
  public practiceInfo: any;

  overallRankingsAnalysis: {};
  rankings: boolean = false;

  //for storing competitors results
  competitors: boolean = false;
  overall_competitors_analysis = {};
  competitors_google_ranking = [];
  competitorsDomains: any[] = [];


  // creating new observable for listning updates of reportData
  private reportData = new Subject<any>();
  reportUpdated$ = this.reportData.asObservable();

  snapListner;
  pageInsightsFetched: boolean = false;
  pageInsightArray: any[];

  constructor(
    private http: HttpClient,
  ) { }

  async getResultsCollectionRef(url, resultsDocId) {
    let seoColSnap = await firebase.firestore().collection('seo').where('url', '==', url)
      .get();
    if (seoColSnap.empty) {
      this.reportData.next({ name: "error", errorIn: "resultDocError" });
    }
    else {
      let seoColRef = await seoColSnap.docs[0].ref;
      let snap = await seoColRef.collection('results').doc(resultsDocId).get()
      if (snap.exists) {
        this.resultDocRef = await snap.ref;
      }
      else {
        this.reportData.next({ name: "error", errorIn: "resultDocError" });
      }
    }
  }

  getResults(docId) {
    this.reportId = docId;
    this.rankings = false;
    this.competitors = false;
    this.pageInsightsFetched = false;
    this.snapListner = firebase.firestore().collection('rank-checker').doc(this.reportId).onSnapshot(async docsnap => {
      // console.log("listner added on snapshot",this.reportId);
      if (docsnap.exists) {
        let data = docsnap.data();
        this.practiceInfo = docsnap.data();
        let urlDomain = null;
        if (psl.isValid(data['website_url'])) {
          urlDomain = data['website_url'].toLowerCase();
        } else {
          urlDomain = psl.get(new URL(data['website_url'].toLowerCase()).hostname);
        }
        if (data['resultsDocId'] !== undefined) {
          if (this.resultDocRef == undefined) {
            await this.getResultsCollectionRef(urlDomain, data['resultsDocId'])
          }
          //google-rankings-domain
          if (this.resultDocRef !== undefined && (data.resultsStatus?.rankings == "completed" || data.resultsStatus?.rankings == "error") && this.rankings == false) {
            let overallRankingsAnalysisSnap = (await this.resultDocRef.collection('rankings').get());
            if (data.resultsStatus?.rankings == "error") {
              this.rankings = true;
              this.reportData.next(
                {
                  name: "rankings",
                  analysis: this.overallRankingsAnalysis,
                  status: "error"
                }
              );
            }
            else {
              let overallRankingsAnalysisDoc = overallRankingsAnalysisSnap.docs[0];
              this.overallRankingsAnalysis = overallRankingsAnalysisDoc.data();
              this.rankings = true;
              this.reportData.next(
                {
                  name: "rankings",
                  analysis: this.overallRankingsAnalysis,
                  status: "completed"
                }
              );
            }
          }
          else {
            console.log("Else ranking")
          }
          //google-rankings-competitor
          if (this.resultDocRef !== undefined && (this.practiceInfo.resultsStatus?.competitors == "completed" || this.practiceInfo.resultsStatus?.competitors == "error") && this.competitors == false) {
            let overallCompititorAnalysisSnap = (await this.resultDocRef.collection('competitors_analysis').get());
            if (this.practiceInfo.resultsStatus?.competitors == "error" || overallCompititorAnalysisSnap.empty) {
              this.competitors = true;
              this.reportData.next({ name: "competitors", status: "error" })
            }
            else {
              let overallCompititorAnalysisDoc = overallCompititorAnalysisSnap.docs[0];
              this.overall_competitors_analysis = overallCompititorAnalysisDoc.data();
              //google-ranking
              this.competitors = true;
              this.competitors_google_ranking = (await overallCompititorAnalysisDoc.ref.collection('competitors_analysis-results')
                .where('ignored', '==', false)
                .orderBy('weightedScore', 'desc').limit(3)
                .get()).docs.map(doc => doc.data());

              this.competitorsDomains = this.competitors_google_ranking.map(a => a["domain"]);
              this.competitorsDomains.push(urlDomain);
              // console.log(this.competitorsDomains);

              this.reportData.next({ name: "competitors", analysis: this.overall_competitors_analysis, results: this.competitors_google_ranking, status: "completed" });
              this.reportData.next({ name: "review", analysis: this.overall_competitors_analysis, results: this.competitors_google_ranking, status: "completed" });
            }
          }
          else {
            console.log("Else Competitors")
          }
          if (this.competitors == true && this.pageInsightsFetched == false) {
            this.pageInsightArray = []
            let pageInsightsListner = this.resultDocRef.collection('on_page_results')
              .where('type', '==', 'pageInsightsV2').where('domain', "in", this.competitorsDomains)
              .onSnapshot(async querySnap => {
                querySnap.docChanges().forEach(change => {
                  if (change.type == "added") {
                    this.pageInsightArray.push(change.doc.data());
                  }
                  if (this.pageInsightArray.length == 4) {
                    this.pageInsightsFetched = true;
                    this.pageInsightArray = this.pageInsightArray.sort(function (a, b) {
                      if ((a.score) < (b.score))
                        return 1;
                      else
                        return -1;
                    });
                    this.reportData.next({ name: "pageInsightNew", results: this.pageInsightArray, status: "completed" });
                    pageInsightsListner();
                  }
                });
              });
          }
        }
        else {
          console.log("Else");
        }

      }
      else {
        // console.log("no document exist for listing");
        this.reportData.next({ name: "error", errorIn: 'rank-checker', erroMsg: 'rank-checker document not present' });
      }
    },
      err => {
        //console.log("error", err);
        this.reportData.next({ name: "err", analysis: {}, results: [] });
        return err;
      })

  }

  async reprocessCompetitors_v2(docId: string) {
    console.log(docId);
    return this.http.get(`${environment.cloudFunctionServerUrl}/reprocessCompetitors_v2?rankcheckerId=${docId}`);
    // return this.http.get('https://us-central1-mktgbot-181017.cloudfunctions.net/reprocessCompetitors_v2?rankcheckerId='+docId)
  }

  async getIgnoredDomainList() {
    let docRef = await firebase.firestore().collection("generalSettings").doc('exclude_domain_list')
    let excludeDomainList = await (await docRef.get()).data().domains;
    return excludeDomainList;
  }
}
