import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MatInputModule} from '@angular/material/input';


@Component({
  selector: 'app-find-practice-details',
  templateUrl: './find-practice-details.component.html',
  styleUrls: ['./find-practice-details.component.scss']
})
export class FindPracticeDetailsComponent implements OnInit {

  @Input() firstFormGroup:FormGroup;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  options = {
    input:"Dentist",
    types: ['establishment'],
    location:'pune',
    componentRestrictions: { country: 'IN' }
    };

  
  constructor() { }

  ngOnInit(): void {
  }

  public handleAddressChange(address: Address) 
  {
    console.log(address);
  }

}
