<div style="text-align: center;">
  <div class="logo-div">
    <span>
      <img class="mktg-logo"  src="https://storage.googleapis.com/mktg-doctor-smm/Mktg-Doctor/Assets/Mktg-logo-combined-black.png" alt="">
    </span>
    
  </div>
  <div class="description">Elevate your business strategy with a complimentary practice report, revealing your rankings
    and competitive
    position in the local market. <br>
    No commitments, just valuable insights in under a minute!
  </div>
</div>


<div *ngIf="loading">
  <p-progressSpinner class="center"></p-progressSpinner>
</div>
<div [hidden]="loading">
  <div [hidden]="showResults" class="main">
    <mat-horizontal-stepper labelPosition='bottom' #stepper linear (selectionChange)="onStepChange($event)">
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>
      <mat-step [stepControl]="firstFormGroup" matStepperIcon="check">
        <ng-template matStepLabel>
          <mat-label class="desktop-only">Find Your Practice</mat-label>
        </ng-template>
        <mat-label class="mobile-view">
          <h5 style="text-align: center;">Find Your Practice</h5>
        </mat-label>
        <app-get-practice-details [firstFormGroup]="firstFormGroup" [country]="country_code"
          [isFirstFormGroupValid]="isFirstFormGroupValid" (emitPlacesApiObj)="getPlacesApiAddress($event)"
          (emitStatusOfButton)="checkStatus($event)" [specialityList]="specialityList"
          [practiceDetailsList]="practiceDetailsList">
        </app-get-practice-details>

        <div *ngIf="showGmbListing">
          <div *ngIf="!noLocation && !error else">
            <div style="padding:10px;font-size:20px;" *ngIf="(googleLocations && googleAddress && isLocation)">
              <div>Select Your Google Listing</div>
            </div>
            <div class="row" *ngIf="googleLocationRenderedStatus">
              <div class="row" *ngIf="(googleLocations && googleAddress && isLocation) else loading">
                <!-- <div style="padding:10px;font-size:20px;" *ngIf="(googleLocations && googleAddress && isLocation)">
                                <div>Select Your Google Listing</div>
                            </div>
                            <br> -->
                <app-google-listing [googleLocations]="googleLocations" [address]="googleAddress"
                  [previousSelectedLocation]="rankcheckerObj.googleLocations"
                  (googleListingEmittor)="getGoogleListing($event)"></app-google-listing>
              </div>
              <ng-template #loading>
                <div class="spinner">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- <div class="row" *ngIf="error">
                <span style="color:red;font-size: 20px;padding: 20px;text-align: center;">{{errorObj.error.error.message}}</span>
            </div> -->

        <div align="center" style="margin-top: 10px !important;">
          <button mat-raised-button color="primary" matStepperNext class="m-1" [disabled]="hidden">Next</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" matStepperIcon="done">
        <ng-template matStepLabel>
          <mat-label class="desktop-only">Confirm Your Practice</mat-label>
        </ng-template>
        <!-- <ng-template matStepLabel class="desktop-only">Confirm Your Practice</ng-template> -->
        <mat-label class="mobile-view">
          <h5 style="text-align: center;">Confirm Your Practice</h5>
        </mat-label>
        <app-confirm-practice-details *ngIf="languageResponse && country" [secondFormGroup]="secondFormGroup"
          [firstFormGroup]="firstFormGroup" [country_code]="country_code" [practiceInformation]="practiceInformation"
          [languageResponse]="languageResponse" [country]='country'>
        </app-confirm-practice-details>

        <div style="margin-top:50px;">
          <button mat-raised-button color="primary" matStepperPrevious class="prev">Previous</button>

          <button mat-raised-button color="primary" matStepperNext class="next"
            [disabled]="!secondFormGroup.valid">Next</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup" matStepperIcon="done">
        <ng-template matStepLabel>
          <mat-label class="desktop-only">Contact Information</mat-label>
        </ng-template>
        <mat-label class="mobile-view">
          <h5 style="text-align: center;">Contact Information</h5>
        </mat-label>
        <app-contact-info [languageResponse]="languageResponse" [thirdFormGroup]="thirdFormGroup"
          [countries]="countries" [rankingSttings]="rankingSttings">
        </app-contact-info>
        <div style="margin-top:10px;">
          <button mat-raised-button color="primary" matStepperPrevious class="prev">Previous</button>
          <button mat-raised-button color="primary" matStepperNext class="next" [disabled]="!thirdFormGroup.valid"
            (click)="saveContactInfo1()">Next</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div *ngIf="showResults && showVerification" class="container" style="padding: 20px;">
    <div *ngIf="rankingSttings.emailVerification">
      <app-otp-verification [screenName]="'email'" [value]="thirdFormGroup.controls['email'].value"
        [fullName]="thirdFormGroup.controls['fullName'].value"
        (otpVerificationStatus)="checkOtpVerificationStatus($event)"></app-otp-verification>
    </div>
    <div *ngIf="rankingSttings.phoneVerification">
      <app-otp-verification [screenName]="'phone number'" [value]="full_phone"
        (otpVerificationStatus)="checkOtpVerificationStatus($event)"></app-otp-verification>
    </div>
  </div>
  <div *ngIf="showResults && !showVerification">
    <app-rank-checker-results [rankings]="raningResults"></app-rank-checker-results>
  </div>
</div>
<p-confirmDialog #cd [closable]="false">
  <p-footer>
    <div align="center">
      <button type="button" class="btn btn-primary" (click)="cd.accept()">Continue</button>
      <button type="button" class="btn btn-light btn-outline-dark" (click)="cd.reject()">Start Over</button>
    </div>
  </p-footer>
</p-confirmDialog>