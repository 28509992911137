<body class="body">
  <div class="scroll-snap-container" [hidden]="!loading">
    <p-progressSpinner class="center"></p-progressSpinner>
    <div align="center" style="margin-top: 160px;font-size: 20px;font-weight: 600;"><b>Preparing your ranking results,
        Please wait</b>
    </div>
  </div>
  <div class="scroll-snap-container" [hidden]="loading">
    <div class="html-embed-2 w-embed">
      <style>
        /* scroll snap */
        .scroll-snap-container {
          scroll-snap-type: y mandatory;
          -ms-scroll-snap-type: mandatory;
          -ms-scroll-snap-points-y: snapInterval(0%, 100%);
        }

        .overview {
          scroll-snap-align: start;
        }

        .google-ranking {
          scroll-snap-align: start;
        }

        .reputation {
          scroll-snap-align: start;
        }

        .website-performance {
          scroll-snap-align: start;
        }

        /* hide scrollbars */
        .scroll-snap-container {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        .scroll-snap-container::-webkit-scrollbar {
          display: none;
        }
      </style>
    </div>
    <div class="header" (click)="move('header')">
      <div class="progressbar"></div>
      <div class="columns-4 w-row">
        <div class="column-11 w-col w-col-3">
          <div class="div-block-7"><img src="assets/images/Copy-of-mktg-doctor-logo-new-300x90.png" loading="lazy"
              alt="video placeholder" class="image-5">
          </div>
        </div>
        <div class="column-9 w-col w-col-6">
          <div class="div-block-16">
            <ul role="list" class="list">
              <li class="list-item" [ngClass]="{'active':isActive(0)}">
                <a href="#" (click)="scrollToElement(overview,0)" data-scroll-time="0"
                  data-w-id="b8e03877-5305-3dbe-42ec-d678dfcf23fb" class="link-block w-inline-block">
                  <div class="icon"><strong class="bold-text"></strong></div>
                  <div class="menu-item">Overview</div>
                </a>
              </li>
              <li class="list-item" [ngClass]="{'active':isActive(1)}">
                <a href="#" (click)="scrollToElement(googleRanking,1)" data-w-id="b8e03877-5305-3dbe-42ec-d678dfcf2409"
                  class="link-block w-inline-block">
                  <div class="icon"><strong></strong></div>
                  <div class="menu-item">Google ranking</div>
                </a>
              </li>
              <li class="list-item" [ngClass]="{'active':isActive(2)}">
                <a href="#" (click)="scrollToElement(reputation,2)" data-w-id="71154554-5428-516f-b9f0-e2f398bbbe5f"
                  class="link-block w-inline-block">
                  <div class="icon"><strong></strong></div>
                  <div class="menu-item">reputation</div>
                </a>
              </li>
              <li class="list-item" [ngClass]="{'active':isActive(3)}">
                <a href="#" (click)="scrollToElement(websitePerformance,3)"
                  data-w-id="b8e03877-5305-3dbe-42ec-d678dfcf2410" class="link-block w-inline-block">
                  <div class="icon"><strong></strong></div>
                  <div class="menu-item">website performance</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="column-10 w-col w-col-3" *ngIf="!isAdmin">
          <div class="div-block-42">
            <a href="#" class="bookappointmentpopup w-button">Book A call</a>
          </div>
        </div>
        <div class="column-10 w-col w-col-3" *ngIf="isAdmin" style="padding: 0px;">
          <div class="row" style="padding: 0.5px;width: 100%;">
            <div class="col-md-5" style="padding: 0.2px;">
              <a href="#" class="bookappointmentpopup w-button bookaCall" style="font-size: 12px">Book A call</a>
            </div>
            <div class="col-md-7" style="padding: 0.5px;">
              <button style="font-size: 12px;"
                [ngClass]="showReprocessCompetitorsActivity?'reprocessCompetitors':'disabledButton'"
                (click)="ReprocessCompetitors()" *ngIf="isAdmin"
                [disabled]="!showReprocessCompetitorsActivity">REPROCESS COMPETITORS</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div #overview id="overview" class="overview wf-section" (click)="move('overview')">
      <div class="columns-3 w-row">
        <div class="w-col w-col-6">
          <div class="practice-details-wrapper">
            <div class="div-block-33">
              <div class="subtitle">Practice Name</div>
              <p class="practicename">{{practiceInfoObj?.practiceName}}</p>
            </div>
            <div class="div-block-33">
              <div class="subtitle">Practice Website</div>
              <a href="{{practiceInfoObj?.website_url}}" class="practicedomain">{{practiceInfoObj?.website_url}}</a>
            </div>
            <div class="div-block-33">
              <div class="subtitle">Practice Address</div>
              <p class="practiceaddress">{{practiceInfoObj?.formatted_address}}</p>
              <h1 class="heading-3">rank checker report</h1>
              <p class="paragraph-11">by Mktg.Doctor</p>
            </div>
          </div>
        </div>
        <div class="w-col w-col-6">
          <div id="overall-scrore-wrapper" class="overall-scrore-wrapper">
            <div class="div-block-34">
              <div id="overallScore" class="div-block-50">
                <circle-progress class="desktopCircle" [percent]="(overallScore/3)" [showZeroOuterStroke]="false"
                  showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'" [radius]="100"
                  [titleFontSize]="60" titleFontWeight=600 [unitsFontSize]="60" [innerStrokeWidth]="10"
                  [showSubtitle]=false [outerStrokeWidth]="4" [outerStrokeColor]="'#4285F4'"
                  [innerStrokeColor]="'#CBCFFF'"></circle-progress>
                <circle-progress class="mobileCircle" [percent]="(overallScore/3)" [showZeroOuterStroke]="false"
                  showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'" [radius]="60"
                  [titleFontSize]="35" titleFontWeight=600 [unitsFontSize]="60" [innerStrokeWidth]="10"
                  [showSubtitle]=false [outerStrokeWidth]="4" [outerStrokeColor]="'#4285F4'"
                  [innerStrokeColor]="'#CBCFFF'"></circle-progress>
              </div>
              <h1 class="heading-4"><strong class="bold-text-4">Your Overall Score</strong></h1>
              <p class="paragraph-12">based on your Google ranking, reputation and website performance</p>
            </div>
          </div>
        </div>
      </div>
      <div class="div-block-44">
        <a href="#" data-w-id="b170cafe-47d7-162c-8ead-be7019eee411" class="button filled-btn hide-on-mobile w-button"
          (click)="scrollToElement(googleRanking,1)">Next</a>
      </div>
    </div>

    <div id="googleRanking" #googleRanking class="google-ranking wf-section" (click)="move('googleRanking')">
      <div class="columns w-row">
        <div class="column-2 w-col w-col-6">
          <div class="result outer-padding">
            <div class="div-block-41">
              <h1 class="sectiontitle">Google Ranking</h1>
              <div class="div-block-3" *ngFor=" let rank of googleRankingArray"
                [ngStyle]="rank.domain==practiceInfoObj.domain ?{'background-color':'#CBCFFF','font-weight':'600'}:{'background-color':'white'}">
                <p class="name" style="width: 5%;" *ngIf="rank.domain!=practiceInfoObj.domain && isAdmin">
                  <i class="pi pi-times-circle" *ngIf="!rank.ignoredDomain && isAdmin"
                    [matMenuTriggerFor]="rankingPopup" tooltipPosition="right" style="font-size: 1em;  cursor: pointer;"
                    matTooltip="Add domain to ignore list">
                  </i>
                  <i class="pi pi-undo" *ngIf="rank.ignoredDomain && isAdmin" tooltipPosition="right"
                    style="font-size: 1em;  cursor: pointer;" matTooltip="Remove from ignored domains"
                    (click)="removeDomainFromIgnoreList(rank)">
                  </i>
                </p>
                <mat-menu #rankingPopup="matMenu" x-position="after" y-position="below" overlapTrigger="false">
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'LOCAL')">
                    <span>Local Ignore List</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'GLOBAL')">
                    <span>Global Ignore List</span>
                  </button>
                </mat-menu>
                <p class=" name" [matTooltip]="rank.domain==practiceInfoObj.domain?'Your Practice':rank.name"
                  style="width: 95%;" [class.strikethrough]="rank.ignoredDomain && isAdmin">
                  {{rank.domain==practiceInfoObj.domain?'Your Practice':rank.name.length >= 60 ? (rank.name
                  | slice:0:60) + '...' :rank.name
                  }}</p>
                <div class="knob">
                  <circle-progress class="circle" [percent]="(rank.weightedScore)>=0?rank.weightedScore:0"
                    [showZeroOuterStroke]="false" showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'"
                    [radius]="20" [titleFontSize]="15" titleFontWeight=300 [unitsFontSize]="15" [innerStrokeWidth]="30"
                    [showSubtitle]=false [outerStrokeWidth]="3"
                    [outerStrokeColor]="((rank.weightedScore)>=0?rank.weightedScore:0)>85?'rgba(52,168, 83, 0.9)':((rank.weightedScore)>=0?rank.weightedScore:0)>50?'rgba(251,188,5,0.9)' : 'rgba(234,68,55,0.9)'"
                    [innerStrokeColor]="'#575EB6'">
                  </circle-progress>
                </div>
              </div>
            </div>
            <div class="div-block-15">
              <p class="calculation-details">
                <strong>How’s the score calculated?<br></strong>‍<br>
                Your Google ranking score is based on where you appear on Google compared to your top 3 competitors when
                patients search for:<br>
              </p>
              <div class="div-block-40"><img src="assets/images/google-logo_1google-logo.png" loading="lazy" height="22"
                  alt="" class="image">
                <div class="div-block-38"><img src="assets/images/search-icon_1search-icon.png" loading="lazy"
                    height="20" alt="" class="image-2"><img src="assets/images/mic-icon_1mic-icon.png" loading="lazy"
                    height="20" alt="" class="image-3">
                  <p class="paragraph-13">your <strong class="bold-text-5">specialty</strong> and <strong>city</strong>
                  </p>
                </div>
              </div>
              <div class="div-block-10">
                <a data-w-id="c4327836-1c9a-ed1d-1884-355361baa436" href="#"
                  class="button see-details see-details-btn w-button">see details</a>
              </div>
            </div>
          </div>
        </div>
        <div class="column w-col w-col-6">
          <div class="result-details outer-padding">
            <div class="page-performance-table">
              <div class="div-block-5">
                <div class="stacked-bar-graph">
                  <p-chart type="bar" [data]="basicData" [options]="basicOptions" height="60vh"></p-chart>
                </div>
                <div class="div-block-8">
                  <p>The 1st page of Google gets as much as 96.5% of traffic. The higher your website ranks on Google,
                    the more visibility and growth you can expect.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-buttons">
        <!-- <a href="#overview" data-w-id="29ebc540-c45f-3f5d-c18d-a950b3fcb065" class="button filled-btn w-button"><strong></strong></a>
        <a href="#reputation" data-w-id="c4327836-1c9a-ed1d-1884-355361baa466" class="button filled-btn common-width w-button">next</a> -->
        <a href="#" (click)="scrollToElement(overview,0)" data-w-id="29ebc540-c45f-3f5d-c18d-a950b3fcb065"
          class="button filled-btn w-button"><strong></strong></a>
        <a href="#" (click)="scrollToElement(reputation,2)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa466"
          class="button filled-btn w-button">next</a>
      </div>
      <div class="google-ranking-overlay">

        <div class="overlay-header">
          <div data-w-id="c4327836-1c9a-ed1d-1884-355361baa485" class="close-button" (click)="closeVideo(video1)">×
          </div>
          <h1 class="heading-23 visible-on-mobile">Rankings</h1>
        </div>
        <div class="overlay-screen">

          <h1 class="sectiontitle"><strong class="sectiontitle">Why rankings matter</strong></h1>
          <div class="result-details visible-on-mobile">
            <div class="page-performance-table">
              <div class="div-block-5">
                <div class="div-block-27"></div>
                <div class="div-block-8"></div>
              </div>
            </div>
          </div>
          <p class="paragraph-14">Rankings determine where a particular piece of content should appear on Google SERP
            (Search Engine Results Pages). The 1st position of SERP gets 30% of organic traffic, the 2nd position gets
            22%
            and 3rd position gets 15% of organic traffic. The amount of traffic you get from an organic ranking drops
            considerably as you move down below position number 3. Higher your position on SERP, better the growth
            potential of
            your medical practice.<br>‍<br>Learn about our proven system to turn around your medical practice and rank
            it on the
            top search results on Google.<br></p>
          <img src="assets/assets/images/rc-video-placeholder.PNG" loading="lazy"
            data-w-id="f8fbc6e8-4088-c782-f29f-69c6d900732b" sizes="100vw"
            srcset="assets/images/rc-video-placeholder.PNG 500w, assets/images/rc-video-placeholder.PNG 527w" alt=""
            class="image-4 imageMobileView" (click)="startVideo(video3)">
          <div class="html-embed w-embed w-iframe"><iframe width="100%" #video1
              src="https://www.youtube.com/embed/tpG6G9qMe_I?enablejsapi=1&playsinline=1" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""></iframe></div>
        </div>
      </div>
    </div>
    <div id="reputation" #reputation class="reputation wf-section" (click)="move('reputation')">
      <div class="columns w-row">
        <div class="column-2 w-col w-col-6 w-col-stack w-col-small-small-stack">
          <div class="result outer-padding">
            <div class="div-block-46">
              <h1 class="sectiontitle">Reputation</h1>
              <div class="div-block-3" *ngFor="let rank of reputationArray"
                [ngStyle]="rank.domain==practiceInfoObj.domain ?{'background-color':'#CBCFFF','font-weight':'600'}:{'background-color':'white'}">
                <p class="name" style="width: 5%;" *ngIf="rank.domain!=practiceInfoObj.domain  && isAdmin">
                  <i class="pi pi-times-circle" *ngIf="isAdmin" [matMenuTriggerFor]="reputationPopup"
                    tooltipPosition="right" style="font-size: 1em;  cursor: pointer;"
                    matTooltip="Add domain to ignore list">
                  </i>
                </p>
                <mat-menu #reputationPopup="matMenu" x-position="after" y-position="below" overlapTrigger="false">
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'LOCAL')">
                    <span>Local Ignore List</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'GLOBAL')">
                    <span>Global Ignore List</span>
                  </button>
                </mat-menu>
                <p class="name" [matTooltip]="rank.domain==practiceInfoObj.domain?'Your Practice':rank.name"
                  style="width: 95%;" [class.strikethrough]="rank.ignoredDomain && isAdmin">
                  {{rank.domain==practiceInfoObj.domain?'Your Practice':rank.name.length >= 60 ? (rank.name |
                  slice:0:60) + '...' :rank.name
                  }}</p>
                <div class="knob">
                  <circle-progress class="circle" [percent]="rank.relativeReviewScore>=0?(rank.relativeReviewScore):0"
                    [showZeroOuterStroke]="false" showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'"
                    [radius]="20" [titleFontSize]="15" titleFontWeight=300 [unitsFontSize]="15" [innerStrokeWidth]="30"
                    [showSubtitle]=false [outerStrokeWidth]="3"
                    [outerStrokeColor]="(rank.relativeReviewScore>=0?(rank.relativeReviewScore):0)>85?'rgba(52,168, 83, 0.9)':(rank.relativeReviewScore>=0?(rank.relativeReviewScore):0)>50?'rgba(251,188,5,0.9)' : 'rgba(234,68,55,0.9)'"
                    [innerStrokeColor]="'#575EB6'">
                  </circle-progress>
                </div>
              </div>
            </div>
            <div class="div-block-15">
              <p class="calculation-details"><strong>How’s the score calculated?<br></strong>‍<br>Your reputation score
                is based on your total Google reviews and the average star rating relative to your top 3
                competitors.<br></p>
              <div class="div-block-10">
                <a data-w-id="c4327836-1c9a-ed1d-1884-355361baa49c" href="#" class="button see-details-btn w-button">see
                  details</a>
              </div>
            </div>
          </div>
        </div>
        <div class="column w-col w-col-6 w-col-stack w-col-small-small-stack">
          <div class="result-details outer-padding">
            <div class="page-performance-table">
              <div class="div-block-5">
                <div class="review-table">
                  <h1 class="sectiontitle">Google Reviews</h1>
                  <div class="w-layout-grid grid-4-copy">
                    <div id="w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b30-df48c32b" class="div-block-30">
                      <p class="paragraph-7">Practice</p>
                    </div>
                    <div id="w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b33-df48c32b" class="div-block-30">
                      <p class="paragraph-7">Average Rating</p>
                    </div>
                    <div id="w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b36-df48c32b" class="div-block-30">
                      <p class="paragraph-7">Reviews</p>
                    </div>
                  </div>
                  <div class="w-layout-grid grid-3-copy" *ngFor="let review of reputationArray"
                    [ngStyle]="review.domain==practiceInfoObj.domain ?{'background-color':'#CBCFFF'}:{'background-color':'white'}">
                    <div class="review">
                      <p class="paragraph-6"
                        [matTooltip]="review.domain==practiceInfoObj.domain?'Your Practice':review.name">
                        {{review.domain==practiceInfoObj.domain?'Your Practice':review.name.length >= 60 ?
                        (review.name | slice:0:60) + '...' :review.name
                        }}
                      </p>
                    </div>
                    <div class="review borders">
                      <p class="text-block-7">{{review.rating}}</p>
                      <div class="text-block-8">/5</div>
                    </div>
                    <div id="w-node-_0117d8af-1a40-3c75-f22a-bf10470a4b44-df48c32b" class="review">
                      <star-rating [value]="(review['rating'])" [totalstars]="5" checkedcolor="#fabb05"
                        uncheckedcolor="#b0b0b0" size="24px" readonly="true"></star-rating>
                      <div class="text-block-9-copy">({{review.userRatingsTotal}} Reviews)</div>
                    </div>
                  </div>
                </div>
                <div class="div-block-8"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-buttons">
        <!-- <a href="#googleRanking" data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c3" class="button filled-btn w-button"><strong></strong></a>
        <a href="#websitePerformance" data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c5" class="button filled-btn common-width w-button">next</a> -->
        <a href="#" (click)="scrollToElement(googleRanking,1)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c3"
          class="button filled-btn w-button"><strong></strong></a>
        <a href="#" (click)="scrollToElement(websitePerformance,3)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c5"
          class="button filled-btn w-button">next</a>
      </div>
      <div class="reputation-overlay">
        <div class="overlay-header">
          <div data-w-id="c4327836-1c9a-ed1d-1884-355361baa50d" class="close-button" (click)="closeVideo(video2)">×
          </div>
          <h1 class="heading-23 visible-on-mobile">Reviews</h1>
        </div>
        <div class="overlay-screen">
          <h1 class="sectiontitle visible-on-mobile">Google Reviews</h1>
          <div class="new-practice-details" *ngFor="let review of reputationArray"
            [ngStyle]="review.domain==practiceInfoObj.domain ?{'background-color':'#CBCFFF'}:{'background-color':'#f8f8f8'}">
            <div class="text-block-6" [matTooltip]="review.domain==practiceInfoObj.domain?'Your Practice':review.name">
              {{review.domain==practiceInfoObj.domain?'Your Practice':review.name.length >= 60 ? (review.name |
              slice:0:60) + '...' :review.name
              }}
            </div>
            <div class="w-row">
              <div class="column-5 w-col w-col-2 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                <div class="text-block-7">{{review.rating}}</div>
                <div class="text-block-8">/5</div>
              </div>
              <div class="column-6 w-col w-col-10 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                <star-rating [value]="(review['rating'])" [totalstars]="5" checkedcolor="#fabb05"
                  uncheckedcolor="#b0b0b0" size="24px" readonly="true"></star-rating>
                <div class="text-block-9-copy">({{review.userRatingsTotal}} Reviews)</div>
              </div>
            </div>
          </div>
          <h1 class="sectiontitle">Why reviews are important</h1>
          <p class="overlay-content">It only takes 1-6 online reviews for new patients to form an opinion about your
            practice. A good number of positive reviews will improve your online reputation and help you acquire new
            patients for your practice.<br>&zwj;<br>Reviews also help you rank higher on Google’s local search rankings.
            Your Google reviews tell Google how your patients are rating your practice when they visit your office for
            services. And that affects your offline <strong>prominence</strong> score which is 1 of the 3 major factors
            that impact your Google local search rankings.<br></p><img
            src="https://assets.website-files.com/614862e228b4340c4d48c329/614b2cbee0d0dd7507d888e8_rc-video-placeholder-2.PNG"
            loading="lazy" data-w-id="27059832-8fad-08ae-761c-5c9260fb2aef" sizes="100vw"
            srcset="https://assets.website-files.com/614862e228b4340c4d48c329/614b2cbee0d0dd7507d888e8_rc-video-placeholder-2-p-500.png 500w, https://assets.website-files.com/614862e228b4340c4d48c329/614b2cbee0d0dd7507d888e8_rc-video-placeholder-2.PNG 564w"
            alt="rc-video-placeholder" class="image-6" (click)="startVideo(video4)">
          <div class="html-embed w-embed w-iframe"><iframe height="200px" width="100%" #video2
              src="https://www.youtube.com/embed/ZtdgGq2LI-A?enablejsapi=1" title="YouTube video player" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""></iframe></div>
        </div>
      </div>

    </div>
    <div id="websitePerformance" #websitePerformance class="website-performance wf-section"
      (click)="move('websitePerformance')">
      <div class="scroll-snap-container" style="background-color: #ebedf5;" [hidden]="pageInsightReady">
        <p-progressSpinner class="center"></p-progressSpinner>
        <div style="text-align: center; margin-top: 160px;font-size: 20px;font-weight: 600;">
          <h1 class="sectiontitle">Website Performance</h1>
          <p style="text-align: center;">Preparing your results,Please wait</p>
        </div>
      </div>
      <div class="columns w-row" [hidden]="!pageInsightReady">
        <div class="column-2 w-col w-col-6">
          <div class="result outer-padding">
            <div class="div-block-45">
              <h1 class="sectiontitle">Website Performance</h1>
              <div class="div-block-3" *ngFor="let rank of pageInsightNewObject"
                [ngStyle]="rank.domain==practiceInfoObj.domain ?{'background-color':'#CBCFFF','font-weight':'600'}:{'background-color':'white'}">
                <!-- <p class="name">{{rank.name}}</p> -->
                <p class="name" style="width: 5%;" *ngIf="rank.domain!=practiceInfoObj.domain  && isAdmin">
                  <i class="pi pi-times-circle" *ngIf="isAdmin" [matMenuTriggerFor]="performancePopup"
                    tooltipPosition="right" style="font-size: 1em;  cursor: pointer;"
                    matTooltip="Add domain to ignore list">
                  </i>
                </p>
                <mat-menu #performancePopup="matMenu" x-position="after" y-position="below" overlapTrigger="false">
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'LOCAL')">
                    <span>Local Ignore List</span>
                  </button>
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="addDomainToIgnoreList(rank,'GLOBAL')">
                    <span>Global Ignore List</span>
                  </button>
                </mat-menu>
                <p class="name" [matTooltip]="rank.domain==practiceInfoObj.domain?'Your Practice':rank.name"
                  style="width: 95%;" [class.strikethrough]="rank.ignoredDomain && isAdmin">
                  {{rank.domain==practiceInfoObj.domain?'Your Practice':rank.name.length >= 60 ? (rank.name |
                  slice:0:60) + '...' :rank.name
                  }}
                </p>
                <div class="knob">
                  <circle-progress class="circle"
                    [percent]="(rank?.lighthouseResult?.categories?.performance?.score>=0?rank?.lighthouseResult?.categories?.performance?.score:0)"
                    [showZeroOuterStroke]="false" showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'"
                    [radius]="20" [titleFontSize]="15" titleFontWeight=300 [unitsFontSize]="15" [innerStrokeWidth]="30"
                    [showSubtitle]=false [outerStrokeWidth]="3"
                    [outerStrokeColor]="(rank?.lighthouseResult?.categories?.performance?.score>=0?rank?.lighthouseResult?.categories?.performance?.score:0)>85?'rgba(52,168, 83, 0.9)':(rank?.lighthouseResult?.categories?.performance?.score>=0?rank.lighthouseResult?.categories?.performance?.score:0)>50?'rgba(251,188,5,0.9)' : 'rgba(234,68,55,0.9)'"
                    [innerStrokeColor]="'#575EB6'">
                  </circle-progress>

                </div>
              </div>
            </div>
            <div class="div-block-15">
              <p class="calculation-details"><strong class="bold-text-8">How’s the score
                  calculated?<br><br>‍</strong>This is Google’s PageSpeed Insights performance score which is calculated
                based on 6 metrics. A score above 90 is considered good.<br></p>
              <div class="div-block-10">
                <a data-w-id="2d953a27-5548-486f-91e6-b8a5969359a8" href="#" class="button see-details-btn w-button">see
                  details</a>
              </div>
            </div>
          </div>
        </div>
        <div class="column w-col w-col-6">
          <div class="result-details outer-padding">
            <h1 class="sectiontitle">Your Website Performance Parameters</h1>
            <div class="page-performance-table">
              <div class="div-block-21">
                <div class="total-page-score">
                  <circle-progress class="circle" [percent]="domainPageInsightScore>=0?domainPageInsightScore:0"
                    [showZeroOuterStroke]="false" showBackground=true backgroundPadding=-4 [backgroundColor]="'#ffffff'"
                    [radius]="50" [titleFontSize]="25" titleFontWeight=600 [unitsFontSize]="25" [innerStrokeWidth]="10"
                    [showSubtitle]=false [outerStrokeWidth]="4"
                    [outerStrokeColor]="(domainPageInsightScore>=0?domainPageInsightScore:0)>85?'#0F9D58':(domainPageInsightScore>=0?domainPageInsightScore:0)>50?'rgba(251,188,5,0.9)' : 'rgba(234,68,55,0.9)'"
                    [innerStrokeColor]="'#CBCFFF'"></circle-progress>
                </div>
              </div>
              <div class="div-block-20">
                <div class="w-layout-grid grid-5">
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFirst?.displayValue) >= 0 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 2,
                          'orange' :   parseInteger(pageInsightObj.cardFirst?.displayValue) >= 2 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 4 ,
                          'redSlow' :  parseInteger(pageInsightObj.cardFirst?.displayValue) >= 4}">
                        {{(pageInsightObj.cardFirst?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong class="bold-text-6">First Contentful Paint </strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSecond?.displayValue) >= 0 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 2,
                        'orange' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 2 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 4 ,
                        'redSlow' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 4}">
                        {{(pageInsightObj.cardSecond?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Time to Interactive</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardThree?.displayValue) >=0 && parseInteger(pageInsightObj.cardThree?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardThree?.displayValue) >=2 && parseInteger(pageInsightObj.cardThree?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardThree?.displayValue) >= 4}">
                        {{(pageInsightObj.cardThree?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Speed Index</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFour?.displayValue) >=0 && parseInteger(pageInsightObj.cardFour?.displayValue) < 2,
                        'orange' :parseInteger(pageInsightObj.cardFour?.displayValue) >=2 && parseInteger(pageInsightObj.cardFour?.displayValue) < 4 ,
                        'redSlow' : parseInteger(pageInsightObj.cardFour?.displayValue) >= 4}">
                        {{(pageInsightObj.cardFour?.displayValue).replace('ms','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Total Blocking Time</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFive?.displayValue) >=0 && parseInteger(pageInsightObj.cardFive?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardFive?.displayValue) >=2 && parseInteger(pageInsightObj.cardFive?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardFive?.displayValue) >= 4}">
                        {{(pageInsightObj?.cardFive?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Largest Contentful Paint</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSix?.displayValue) >=0 && parseInteger(pageInsightObj.cardSix?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardSix?.displayValue) >2 && parseInteger(pageInsightObj.cardSix?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardSix?.displayValue) >= 4}">
                        {{(pageInsightObj.cardSix?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Cumulative Layout Shift</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-buttons" [hidden]="!pageInsightReady">
        <!-- <a href="#" (click)="scrollToElement(reputation,2)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa58c"
          class="button filled-btn w-button">previous</a> -->
        <a href="#" (click)="scrollToElement(reputation,2)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa58c"
          class="button filled-btn w-button"><strong></strong></a>

        <a href="#" (click)="scrollToElement(overview,0)" data-w-id="c4327836-1c9a-ed1d-1884-355361baa58e"
          class="button filled-btn w-button">Top</a>
        <!-- <a href="#overview" data-w-id="c4327836-1c9a-ed1d-1884-355361baa58e" class="button filled-btn common-width w-button">Top</a> -->
      </div>
      <div class="website-performance-overlay" [hidden]="!pageInsightReady">
        <div class="overlay-header">
          <div data-w-id="c4327836-1c9a-ed1d-1884-355361baa5f5" class="close-button">×</div>
          <h1 class="heading-23 visible-on-mobile">Performance</h1>
        </div>
        <div class="overlay-screen">
          <h1 class="sectiontitle visible-on-mobile">Your Website Performance Parameters<br></h1>
          <div class="result-details visible-on-mobile">
            <div class="page-performance-table">
              <div class="div-block-21">
                <div class="total-page-score"></div>
              </div>
              <div class="div-block-20">
                <div class="w-layout-grid grid-5">
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFirst?.displayValue) >= 0 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 2,
                          'orange' :   parseInteger(pageInsightObj.cardFirst?.displayValue) >= 2 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 4 ,
                          'redSlow' :  parseInteger(pageInsightObj.cardFirst?.displayValue) >= 4}">
                        {{(pageInsightObj.cardFirst?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong class="bold-text-6">First Contentful Paint </strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSecond?.displayValue) >= 0 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 2,
                        'orange' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 2 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 4 ,
                        'redSlow' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 4}">
                        {{(pageInsightObj.cardSecond?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Time to Interactive</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardThree?.displayValue) >=0 && parseInteger(pageInsightObj.cardThree?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardThree?.displayValue) >=2 && parseInteger(pageInsightObj.cardThree?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardThree?.displayValue) >= 4}">
                        {{(pageInsightObj.cardThree?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Speed Index</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFour?.displayValue) >=0 && parseInteger(pageInsightObj.cardFour?.displayValue) < 2,
                        'orange' :parseInteger(pageInsightObj.cardFour?.displayValue) >=2 && parseInteger(pageInsightObj.cardFour?.displayValue) < 4 ,
                        'redSlow' : parseInteger(pageInsightObj.cardFour?.displayValue) >= 4}">
                        {{(pageInsightObj.cardFour?.displayValue).replace('ms','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Total Blocking Time</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFive?.displayValue) >=0 && parseInteger(pageInsightObj.cardFive?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardFive?.displayValue) >=2 && parseInteger(pageInsightObj.cardFive?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardFive?.displayValue) >= 4}">
                        {{(pageInsightObj?.cardFive?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Largest Contentful Paint</strong></div>
                  </div>
                  <div class="card">
                    <div class="score">
                      <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSix?.displayValue) >=0 && parseInteger(pageInsightObj.cardSix?.displayValue) < 2,
                          'orange' :parseInteger(pageInsightObj.cardSix?.displayValue) >2 && parseInteger(pageInsightObj.cardSix?.displayValue) < 4 ,
                          'redSlow' : parseInteger(pageInsightObj.cardSix?.displayValue) >= 4}">
                        {{(pageInsightObj.cardSix?.displayValue).replace('s','')}}
                      </div>
                    </div>
                    <div class="seconds">seconds</div>
                    <div class="paramter-title"><strong>Cumulative Layout Shift</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h1 class="sectiontitle">Why you should monitor website performance<br></h1>
          <p class="paragraph-15">Your website is the most important marketing asset for your practice and plays a major
            role in the overall patient experience. Many people use mobile devices to access the internet. Your score
            indicates how your website performs on a mobile device.<br>‍<br>Core Web Vitals are a set of signals
            critical to all web experiences that examine how fast your website loads and how quickly and efficiently a
            visitor can interact with your website. As of June of 2021 Google’s search algorithm and your website’s Core
            Web Vitals can positively or negatively impact your keyword rankings.<br></p>
        </div>
      </div>
    </div>
  </div>
  <div class="mob-fixed-buttons wf-section" [hidden]="loading">
    <div class="div-block-49" [hidden]="!(screenName == 'overview')">
      <a href="#" data-w-id="b170cafe-47d7-162c-8ead-be7019eee411" (click)="scrollToElement(googleRanking,1)"
        class="button-4 w-button">NEXT</a>
    </div>
    <div class="w-row" [hidden]="!(screenName == 'googleRanking')">
      <div class="column-7 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(overview,0)" class="button-3 w-button"
          data-w-id="29ebc540-c45f-3f5d-c18d-a950b3fcb065"><strong></strong></a>
      </div>
      <div class="column-8 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(reputation,2)" class="button-4 w-button"
          data-w-id="c4327836-1c9a-ed1d-1884-355361baa466">NEXT</a>
      </div>
    </div>
    <div class="w-row" [hidden]="!(screenName == 'reputation')">
      <div class="column-7 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(googleRanking,1)" class="button-3 w-button"
          data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c3"><strong></strong></a>
      </div>
      <div class="column-8 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(websitePerformance,3)" class="button-4 w-button"
          data-w-id="c4327836-1c9a-ed1d-1884-355361baa4c5">NEXT</a>
      </div>
    </div>
    <div class="w-row" [hidden]="!(screenName == 'websitePerformance')">
      <div class="column-7 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(reputation,2)" class="button-3 w-button"
          data-w-id="c4327836-1c9a-ed1d-1884-355361baa58c"><strong></strong></a>

      </div>
      <div class="column-8 w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
        <a href="#" (click)="scrollToElement(overview,0)" class="button-4 w-button"
          data-w-id="c4327836-1c9a-ed1d-1884-355361baa58e">TOP</a>
      </div>
    </div>
  </div>
  <div class="modal">
    <div class="modal-content">
      <a data-w-id="45415248-5b4f-a876-36b6-18b6db2074f7" href="#" class="close-modal"
        (click)="closeVideo(video3)">×</a>
      <div class="heading-22">How we ranked a dental practice on google (in just 3 months)</div>
      <div id="book-appointment-div" class="div-block-48">
        <div class="desk-view w-embed w-iframe"><iframe #video3 id="video-1" height="500" width="100%"
            src="https://www.youtube.com/embed/tpG6G9qMe_I?enablejsapi=1" title="YouTube video player" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""></iframe></div>
      </div>
    </div>
  </div>
  <div class="modal-for-video-2">
    <div class="modal-content-video-2">
      <a data-w-id="5e5435e1-6213-d835-977f-f35814ec5dae" href="#" class="close-modal-video-2"
        (click)="closeVideo(video4)">×</a>
      <div class="heading-22">Effectively manage patient reviews for your practice</div>
      <div id="book-appointment-div" class="modal-2-video-box">
        <div class="desk-view-video-2 w-embed w-iframe"><iframe height="500px" width="100%" #video4
            src="https://www.youtube.com/embed/ZtdgGq2LI-A?enablejsapi=1" id="video-2" title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""></iframe></div>
      </div>
    </div>
  </div>
  <!-- <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=614862e228b4340c4d48c329" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script> -->
  <!-- <script src="js/rank-checker-report-copy.js" type="text/javascript"></script> -->
  <!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
</body>