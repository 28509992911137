import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { RankCheckerComponent } from './rank-checker/rank-checker.component';
import { RankingReportLatestComponent } from './ranking-report-latest/ranking-report-latest.component';
import { RankingReportComponent } from './ranking-report/ranking-report.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: "",
    component: RankCheckerComponent
  },
  {
    path: "v1",
    component: RankCheckerComponent
  },
  {
    path: "v2",
    component: RankCheckerComponent
  },
  {
    path:  'ranking-report/:docId',
    component: RankingReportComponent
  },
  {
    path:  'ranking-report-v2/:docId',
    component: RankingReportLatestComponent
  }

];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes)
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
