<div *ngIf="rankings else Go">
  <p style="text-align: center;"><b> Your Google Rankings</b> </p>
  <!-- <div *ngIf="rankings.length>0" class="mat-elevation-z8" style="padding: 5px;"> -->

  <div style="padding: 10px;">

    <!-- <div class="scrolling-wrapper-flexbox">
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{ metricArray.organic_count}} </div>
            <div class="metric-caption kpi"> Organic count</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi" *ngIf="topResultCount;else zero_count"> {{ topResultCount }} </div>
            <ng-template #zero_count>
              <div class="metric kpi">0</div>
            </ng-template>
            <div class="metric-caption kpi"> Result count</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{ metricArray.etv }} </div>
            <div class="metric-caption kpi">Estimated Traffic Value(etv)</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{metricArray.pos1}} </div>
            <div class="metric-caption kpi"> Position 1</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{metricArray.pos2_3}} </div>
            <div class="metric-caption kpi"> Position 2_3</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{metricArray.pos4_10}} </div>
            <div class="metric-caption kpi"> Position 4_10</div>
          </mat-card-content>
        </mat-card>
        <mat-card class="metric-card">
          <mat-card-header>
            <mat-card-title class="metric-caption"></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="metric kpi"> {{metricArray.pos11_20}} </div>
            <div class="metric-caption kpi"> Position 11_20</div>
          </mat-card-content>
        </mat-card>
      </div> -->

    <p-table [columns]="cols" [value]="rankingDetails" [autoLayout]="true" [paginator]="true"
      [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10,20,50]" [rows]="rows" [resizableColumns]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header|titlecase }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td class="mid" *ngFor="let col of columns">
            <span *ngIf="isString(rowData[col.field]);else roudOfNumbers">
              {{rowData[col.field]}}
            </span>
            <ng-template #roudOfNumbers>
              <span *ngIf="col.field=='rank_absolute' || col.field=='rank_group';else fractions">
                {{rowData[col.field]}}
              </span>
              <ng-template #fractions>
                {{rowData[col.field]|number:'1.2-2'}}
              </ng-template>
            </ng-template>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- </div>
  <div class="row" *ngIf="rankings==undefined" style="text-align: center;height: 100%;;">
    <p-progressSpinner></p-progressSpinner>
  </div> -->
</div>
<ng-template #Go>
  <p-progressSpinner class="center"></p-progressSpinner>
  <div align="center" style="margin-top: 160px;font-size: 20px;"><b>Preparing your ranking results, Please wait</b>
  </div>
</ng-template>