import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RankCheckerService } from "../../_services/rank-checker.service";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Output, EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PracticeInformation } from "../../_models/interfaces";


@Component({
  selector: 'app-get-practice-details',
  templateUrl: './get-practice-details.component.html',
  styleUrls: ['./get-practice-details.component.scss']
})
export class GetPracticeDetailsComponent implements OnInit {
  @Input() firstFormGroup: FormGroup;
  @Input() specialityList: any;
  @Input() practiceDetailsList: any;
  @Input() country: string;
  @Input() isFirstFormGroupValid: boolean;
  // practiceDetailsList:any=[];
  @Output() emitPlacesApiObj = new EventEmitter<any>();
  @Output() emitStatusOfButton = new EventEmitter<any>();
  status = { isPracticeName: false, isSpeciality: false };
  public filteredOptions: Observable<string[]>;
  public specialityFilteredOptions: Observable<string[]>;
  public practiceFilteredOptions: Observable<string[]>;
  isLocation: boolean = false;

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild('addressInput') addressInput: ElementRef;

  options = {
    input: "",
    types: ['establishment'],
    // componentRestrictions: { country: 'US' }
  };
  speciality: any;
  adddressValue = "";
  locations: any;
  googleLocations: any;
  accessToken: any;
  address: any;
  selectedLocations: any;
  constructor(
    public rankCheckerService: RankCheckerService,
    // public practiceInterface  :PracticeInformation
  ) {

  }

  ngOnChanges() {
    // console.log("on Chnages occure");
    if (this.isFirstFormGroupValid) {
      this.status.isPracticeName = true;
      this.status.isSpeciality = true;
      this.emitStatusOfButton.emit(this.status);
    }
    // this.options.componentRestrictions.country = this.country;
    // if(this.placesRef)
    //   this.placesRef.reset();
  }

  async ngOnInit() {
    let result = await this.rankCheckerService.getSettings();
    this.specialityList = result.specialities;

    // console.log(this.firstFormGroup);

    // this.rankCheckerService.getSpecialtiesList().then(
    //   (result) => {
    //     this.specialityList = result.specialities;
    //   });

    this.specialityFilteredOptions = this.firstFormGroup.controls.speciality.valueChanges.pipe(
      startWith(''),
      map(val => this.filterspeciality(val))
    );

    this.practiceFilteredOptions = this.firstFormGroup.controls.practiceName.valueChanges.pipe(
      startWith(''),
      map(val => this.filterPractices(val))
    );

  }

  filterspeciality(value): string[] {
    const filterValue = value.toString().toLowerCase();
    return this.specialityList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0 || option.name.toLowerCase().includes(filterValue));
  }

  filterPractices(val): string[] {
    return this.practiceDetailsList.filter(option =>
      option.practiceName.toLowerCase().includes(val))
  }


  displayPracticeFn(practice: any) {
    if (practice) {
      return practice.practiceName + ", " + practice.streetAdress + ", " + practice.city + " ," + practice.state;
    }
    return "";
  }

  get f1() { return this.firstFormGroup.controls; }

  public handleAddressChange(address: Address) {
    this.firstFormGroup.value.practiceName = this.addressInput.nativeElement.value;
    this.firstFormGroup['gmbname'] = address.name;
    this.status.isPracticeName = true;
    // this.getGmbListing();

    this.emitStatusOfButton.emit(this.status);
    this.emitPlacesApiObj.emit(address);
  }

  getSpeciality(value) {
    // this.placesRef.reset();
    this.speciality = value;
    this.status.isSpeciality = true;
    this.emitStatusOfButton.emit(this.status);
  }

  onPracticeChange(searchValue: string): void {
    if (searchValue === "") {
      this.status.isPracticeName = false;
      this.emitStatusOfButton.emit(this.status);
    }
  }

  onSpecialityChange(searchValue: string): void {
    if (searchValue === "") {
      this.status.isSpeciality = false;
      this.emitStatusOfButton.emit(this.status);
      // } else {
      //   this.status.isSpeciality = true;
      //   this.speciality = searchValue;
      //   this.emitStatusOfButton.emit(this.status);
    }
  }

  displaySecialityFn(speciality: any) {
    if (speciality) {
      return speciality.name
    }
    return "";
  }

  // getGmbListing() {
  //   this.accessToken = localStorage.getItem('access-token');
  //   this.address = this.firstFormGroup['gmbname']
  //   console.log(this.accessToken);
  //   console.log(this.address);
  //   this.rankCheckerService.fetchGmbLocations(this.accessToken,this.address,this.speciality).then(data=>{
  //     console.log(data);
  //     this.locations = data['googleLocations'];
  //     this.googleLocations= this.locations.map(function(el) {
  //       var obj = Object.assign({}, el);
  //       obj.badgeHidden = true;
  //       return obj;
  //     });
  //     console.log(this.locations);
  //     console.log(this.googleLocations);
  //     this.isLocation = true;
  //   });
  // }

  // getGoogleListing(value)
  // {
  //   this.selectedLocations = value['selectedLocation'];
  //   if(!value['status'])
  //     this.status.isCardSelected = true;
  //   else
  //     this.status.isCardSelected = false;
  //   let data = {
  //     status:this.status,
  //     selectedLocation:this.selectedLocations
  //   }
  //   console.log(data);
  //   this.emitStatusOfButton.emit(data);
  // }

}
