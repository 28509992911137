<div *ngIf="iframe">
  <div style="margin-top: 160px;font-size: 20px;font-weight: 600;text-align: center;"><b>Here's the Rank Checker Report
      for your practice.</b></div>
  <div style="text-align: center;padding: 10px;"> <button
      style="border-radius:4px;padding: 10px;border: none;font-weight: 500;" (click)="openReportInNewTab()">Click here
      to view
      your report</button></div>
  <div style="font-size: 14px;font-weight: 400;text-align: center;">Your report has also been emailed
    to you.</div>
  <div style="font-size: 14px;font-weight: 500;text-align: center;">If you need help in understanding this report and
    improving your Google rankings further, <br>Click <a href="https://mktg.doctor/schedule-demo/"
      target="_blank">here</a> schedule a
    meeting with us.</div>
</div>
<div *ngIf="!iframe">
  <div *ngIf="loading">
    <p-progressSpinner class="center"></p-progressSpinner>
    <div align="center" style="margin-top: 160px;font-size: 20px;font-weight: 600;"><b>Preparing your ranking results,
        Please wait</b>
    </div>
  </div>


  <div *ngIf="!loading && !showError">
    <i class="fas fa-chevron-circle-up" (click)="goToSelectedSection('mainScreen')" id="upIcon"
      style="display: none;"></i>

    <!-- ----Main Screen-- -->
    <div class="pageDiv2" style="margin-top: 0px;" id="mainScreen" #main>
      <div class="row">
        <div class="col-md-5" style="text-align: center;margin-top:3.2rem;">
          <div>
            <img src="https://mktg.doctor/wp-content/uploads/2019/03/Copy-of-mktg-doctor-logo-new-300x120.png"
              id="mktgLogo">
          </div>
          <div style="margin: 0.5em 3em 3em;">
            <p style="font-size:xx-small;margin-top: -20px;">PRACTICE NAME</p>
            <p style="margin-top: -20px;">{{practiceInfoObj.practice_name}}</p>
            <p style="font-size:smaller;font-size:xx-small">PRACTICE ADRESS</p>
            <p style="font-size:16px;margin-top: -20px; ">{{practiceInfoObj.formatted_address}}</p>
            <div align="center">
              <button id="requestMoreInfoBtn" style="margin: 1rem;"
                onclick="window.open('https://mktg.doctor/schedule-demo/')">REQUEST MORE INFO</button>
            </div>
          </div>
        </div>
        <div class="col-md-7" style="padding:1rem;">
          <h3 style="text-align: center;">YOUR PRACTICE REPORT</h3>
          <p>This is a very comprehensive report of your practice prepared by Mktg.Doctor. Scroll page by page or use
            the
            quick links below to jump to any particular section.
          </p>
          <div>
            <div (click)="goToSelectedSection('practiceGoogleRanking')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Practice Google Rankings</span>
            </div>
            <div (click)="goToSelectedSection('localSearchRank')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Local Search Rankings</span>
            </div>
            <div (click)="goToSelectedSection('TopRankTable')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Top Pages by Ranking and Traffic</span>
            </div>
            <div (click)="goToSelectedSection('competitorReport')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Competitors Report</span>
            </div>
            <div (click)="goToSelectedSection('websiteAudit')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Website Audit</span>
            </div>
            <div (click)="goToSelectedSection('websitePerformance')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Website Performance</span>
            </div>
            <div (click)="goToSelectedSection('competitorsReviews')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Reviews</span>
            </div>
            <div (click)="goToSelectedSection('feedback')" id="menu">
              <i class="fas fa-angle-double-right"></i>
              <span id="menuLink">Feedback</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- -----Google Search Ranking--- -->

    <div class="pageDiv1" id="practiceGoogleRanking">
      <p class="reportHeading" style="font-weight: 600;">YOUR PRACTICE GOOGLE RANKINGS</p>
      <hr>
      <ng-template #errorpart>
        <h1 align=center>No ranking keywords</h1>
      </ng-template>
      <div *ngIf="this.rankingReportObj.status != 'error' ;else errorpart">
        <div style="background-color:#d9d2e9;" class="row text-center">
          <div class="col-md-4">
            <p style="font-size:1.5em;font-weight: 600;overflow-wrap: anywhere;">
              {{rankingReportObj?.analysis?.target}}</p>
            <p style="font-size:xx-small;margin-top: -20px;">WEBSITE DOMAIN </p>
          </div>
          <div class="col-md-2">
            <p style="font-size:1.5em;font-weight: 600;">{{totalCount | number:'1.0-0'}}</p>
            <p style="font-size:xx-small;margin-top: -20px;">TOTAL RANKING KEYWORDS</p>
          </div>
          <div class="col-md-3">
            <p style="font-size:1.5em;font-weight: 600;">{{estimateMonthlyTraficCount | number:'1.0-0'}}</p>
            <p style="font-size:xx-small;margin-top: -20px;">ESTIMATE MONTHLY TRAFFIC</p>
          </div>
          <div class="col-md-3">
            <p style="font-size:1.5em;font-weight: 600;">{{paidTrafficCost | currency:'USD':'symbol':'1.0-0'}}</p>
            <p style="font-size:xx-small;margin-top: -20px;">PAID TRAFFIC COST</p>
          </div>
        </div>
        <div class="row text-center" id="googleSearchMargin">
          <div class="col-md-5" style="margin-bottom: 1rem;">
            <p style="font-size: 15px;text-align: justify;">
              Your website is ranking for a total <b>{{firstPageKeyWordCount}} </b>keywords on Google’s 1st page.
              This is where the majority of search traffic is coming from.
              Additional <b>{{secondPageKeyWordCount}} </b>keywords are ranking on the second page and the rest on the
              third
              page
              and beyond. If you need help in understanding this report and improving your Google rankings
              further, reach out to us or schedule a meeting with us.
            </p>
            <div align="center">
              <button id="requestMoreInfoBtn"
                onclick="window.open('https://mktg.doctor/schedule-demo/','_blank')">REQUEST
                MORE INFO
              </button>
            </div>
          </div>

          <div class="col-md-7">
            <div class="container-fluid" class="row text-center">
              <div class="col-md-2">
                <p-timeline [value]="events">
                  <ng-template pTemplate="marker" let-event>
                    <span class="custom-marker p-shadow-2" style="text-align:left;font-size:1%">
                      <img *ngIf="event.image" id="googleLogo" src="assets/googlelogo.png" alt="image">
                      <button *ngIf="!event.image" id="eventButton">{{event.title}}</button>
                    </span>
                  </ng-template>
                </p-timeline>
              </div>
              <div class="col-md-10" id="cardSection">
                <div>
                  <div class="col-md-11">
                    <span class="p-input-icon-right">
                      <i color="blue" style="right:2.5rem;"><img id="micImg" src="assets/google_mic.png"
                          alt="image"></i>
                      <i style="color: #8E7CC3;" class="pi pi-search"></i>
                      <input type="text" disabled id="topic" style="border-radius:20px" size=70
                        class="form-control inputbox">
                    </span>
                  </div>
                </div>
                <br>
                <div class="container-fluid" class="row text-center" style="height:70px;background-color:#f7f7f7;"
                  id="googleRankDiv">
                  <div class="col-md-9">
                    <p id="cardParagraph"> NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONS 1 ON GOOGLE</p>
                  </div>
                  <div class="col-md-2">
                    <div id="organicCard">
                      <div align="center" style="font-size: 17px;font-weight: 600;margin-top: 20%;">
                        {{firstPositionCount}}
                      </div>
                      <span style="font-size: 1%;text-align: center;"> KEYWORDS</span>
                    </div>
                  </div>
                </div>
                <div class="container-fluid" class="row" style="height:70px;" id="googleRankDiv">
                  <div class="col-md-9">
                    <p id="cardParagraph">NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONS 2-3 ON GOOGLE</p>
                  </div>
                  <div class="col-md-2">
                    <div id="organicCard">
                      <div align="center" style="font-size: 17x;font-weight: 600;margin-top: 20%;">
                        {{secondTothirdtPositionCount}}
                      </div>
                      <span style="font-size: 1%;text-align: center;"> KEYWORDS</span>
                    </div>
                  </div>
                </div>
                <div class="container-fluid" class="row" style="height:70px;background-color:#f7f7f7;"
                  id="googleRankDiv">
                  <div class="col-md-9">
                    <p id="cardParagraph">NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONS 4-10 ON GOOGLE</p>
                  </div>
                  <div class="col-md-2">
                    <div id="organicCard">
                      <div align="center" style="font-size: 17px;font-weight: 600;margin-top: 20%;">
                        {{fourToTenPositionCount}}
                      </div>
                      <span style="font-size: 1%;text-align: center;"> KEYWORDS</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- ----card 2--Local Search Ranking--- -->

    <div class="pageDiv2" id="localSearchRank">
      <p class="reportHeading" style="text-align:center;font-weight: 600;">YOUR LOCAL SEARCH RANKINGS </p>

      <hr>

      <div *ngIf="localPackResults.length>0;else nolocalPackTemplate" class="row" style="margin: 20px;">
        <div class="col-md-5" style="padding-left: 2em;">
          <div class="row" style="padding: 10px;">
            <div id="googleLogoLocalSearch">
              <img style="width:40px;height:40px;padding: 4px;" src="assets/googlelogo.png" alt="image">
            </div>
            <div id="inputBoxLocalSearch">
              <span class="p-input-icon-right">
                <i color="blue" style="right:2.5rem;"><img id="micImg" src="assets/google_mic.png" alt="image"></i>
                <i style="color: #8E7CC3;" class="pi pi-search"></i>
                <input type="text" disabled id="topic" style="border-radius:20px" size=70 class="form-control inputbox">
              </span>
            </div>
          </div>

          <div class="container">
            <div class="row localPackRow" (click)="showLocalPackAtRank(0)"
              [ngStyle]="currentLocalPack==0?{'background-color':'#f7f7f7'}:{'background-color':'white'}"
              style="cursor:pointer;">
              <div class="col-md-1" style="padding-left: 0px;padding-right: 0px;">
                <i class="fas fa-map-marker-alt" style="font-size: 20px;margin-top: 1em;
                padding-right: 5px;"></i>
              </div>
              <div class="col-md-9" id="localParaTitleCol">
                <p id="localParaTitle">
                  NUMBER OF KEYWORDS IN LOCAL SEARCH
                </p>
              </div>
              <div class="col-md-2">
                <div class="card" id="localCardMain">
                  <span>{{localPackAtRank1.length+localPackAtRank2.length+localPackAtRank3.length}}</span>
                  <span style="font-size: 8px;"> KEYWORDS</span>
                </div>
              </div>
            </div>
            <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(1)"
              [ngStyle]="currentLocalPack==1?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
              <button id="localEventButton"
                [ngStyle]="currentLocalPack==1?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">A</button>
              <div class="col-md-9">
                <div id="localPara">{{practiceInfoObj.practice_name}}
                  <br>
                  <span style="color: #afafb3; display: flex;">
                    {{practiceInfoObj.ratings}} &nbsp;
                    <!-- <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true"
                    stars="5" [cancel]="false"></p-rating> -->
                    <star-rating [value]="(practiceInfoObj['ratings'])" [totalstars]="5" checkedcolor="#fabb05"
                      uncheckedcolor="#b0b0b0" size="15px" readonly="true"></star-rating>
                  </span>
                  <div>{{practiceInfoObj.gmbSpeciality}}</div>
                </div>
              </div>

              <div class="col-md-2">
                <div id="localCard" class="card">
                  <span>{{localPackAtRank1.length}}</span>
                  <span style="font-size: 8px;"> KEYWORDS</span>
                </div>
              </div>
            </div>
            <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(2)"
              [ngStyle]="currentLocalPack==2?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
              <button id="localEventButton"
                [ngStyle]="currentLocalPack==2?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">B</button>
              <div class="col-md-9">
                <p id="localPara">{{practiceInfoObj.practice_name}}
                  <!-- <br>
                <span style="color: #afafb3;">
                  {{practiceInfoObj.gmbSpeciality}}
                  <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true" stars="5" [cancel]="false"></p-rating>
                </span> -->
                </p>
              </div>
              <div class="col-md-2">
                <div id="localCard" class="card">
                  <span>{{localPackAtRank2.length}}</span>
                  <span style="font-size: 8px;"> KEYWORDS</span>
                </div>
              </div>
            </div>
            <div class="row localPackRow" id="localPackEvent" (click)="showLocalPackAtRank(3)"
              [ngStyle]="currentLocalPack==3?{'background-color':'#f7f7f7'}:{'background-color':'white'}">
              <button id="localEventButton"
                [ngStyle]="currentLocalPack==3?{'background-color':'#8E7CC3'}:{'background-color':'#e7e7e7'}">C</button>
              <div class="col-md-9">
                <p id="localPara">{{practiceInfoObj.practice_name}}
                  <!-- <br> -->
                  <!-- <span style="color: #afafb3;">
                  {{practiceInfoObj.gmbSpeciality}}
                  <p-rating [ngModel]="practiceInfoObj.ratings" readonly="true" stars="5" [cancel]="false"></p-rating>
                </span> -->
                </p>
              </div>

              <div class="col-md-2">
                <div id="localCard" class="card">
                  <span>{{localPackAtRank3.length}}</span>
                  <span style="font-size: 8px;"> KEYWORDS</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7" id="localRankResultTable">
          <div *ngIf="showLocalResults.length>0;else nolocalPackTemplate">
            <div class="container-fluid" class="row" id="topPageRow" *ngFor="let localRank of showLocalResults">
              <div class="col-md-4" id="topPage" id="domain">
                <span style="font-size: 16px;color:#7e84f2;">
                  {{localRank.keyword_data.keyword}}
                </span>
                <br>
                <span class="subscript">Keyword</span>
              </div>
              <div class="col-md-2" id="topPage" id="domain">
                <span [matTooltip]="localRank.ranked_serp_element.serp_item.rank_group == null ? 'No data ':null"
                  tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
                  {{localRank.ranked_serp_element.serp_item.rank_group==null?'NA':localRank.ranked_serp_element.serp_item.rank_group
                  }}
                </span>
                <br>
                <span class="subscript">Position</span>
              </div>
              <div class="col-md-2" id="topPage" id="domain">
                <span
                  [matTooltip]="localRank.keyword_data.keyword_info.monthly_searches[0].search_volume == null ? 'No data ':null"
                  tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
                  {{localRank.keyword_data.keyword_info.monthly_searches[0].search_volume==null?'NA':localRank.keyword_data.keyword_info.monthly_searches[0].search_volume}}
                </span>
                <br>
                <span class="subscript">Monthly Searchs</span>
              </div>
              <div class="col-md-2" id="topPage" id="domain">
                <span [matTooltip]="localRank.keyword_data.keyword_info.competition == null ? 'No data ':null"
                  tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
                  {{localRank.keyword_data.keyword_info.competition==null?'NA':localRank.keyword_data.keyword_info.competition|
                  number : '1.2-2'}}
                </span>
                <br>
                <span class="subscript">Competition</span>
              </div>
              <div class="col-md-2" id="topPage" id="domain">
                <span [matTooltip]="localRank.keyword_data.keyword_info.cpc == null ? 'No data ':null"
                  tooltipPosition="above" style="font-size: 20px; font-weight: 500;">
                  {{localRank.keyword_data.keyword_info.cpc == null? 'NA':
                  localRank.keyword_data.keyword_info.cpc|currency}}
                </span>
                <br>
                <span class="subscript">CPC</span>
              </div>
            </div>
          </div>
          <ng-template #nolocalPackTemplate>
            <div class="row text-center" id="noKeywordRankDiv"
              style="height:300px;text-transform: uppercase;font-weight: 500;">
              Currently you don't have any keywords ranking.
            </div>
          </ng-template>
        </div>
      </div>

      <!--if no local count-->
      <div *ngIf="localPackResults.length==0;else nolocalPackTemplate" class="row localpack1 ">

        <div class="col-md-5" id="noKeywordDiv">
          <div class="row" style="padding:10px;">
            <div style="width: 8%;">
              <img style="width:40px;height:40px;padding: 4px;" src="assets/googlelogo.png" alt="image">
            </div>
            <div style="width: 90%;padding-left: 3%;">
              <span class="p-input-icon-right">
                <i style="right:2.5rem;"><img id="micImg" src="assets/google_mic.png" alt="image"></i>
                <i style="color: #8E7CC3;" class="pi pi-search"></i>
                <input type="text" disabled id="topic" style="border-radius:20px" size=70 class="form-control inputbox">
              </span>
            </div>
          </div>
          <div class="container">
            <div class="row localPackRow" style="height: 6em;">
              <div class="col-md-1" style="padding-left: 0px;padding-right: 0px;">
                <i class="fas fa-map-marker-alt" style="font-size: 20px;margin-top: 1em;
                padding-right: 5px;"></i>
              </div>
              <div class="col-md-9" id="localParaTitleCol">
                <p id="localParaTitle">
                  NUMBER OF KEYWORDS IN LOCAL SEARCH
                </p>
              </div>
              <div class="col-md-2">
                <div class="card" id="localCardMain1">
                  <span>{{localPackAtRank1.length+localPackAtRank2.length+localPackAtRank3.length}}</span>
                  <span style="font-size: 8px;"> KEYWORDS</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="row text-center" id="noKeywordRankDiv"
            style="height:300px;text-transform: uppercase;font-weight: 500;">
            Currently you don't have any keywords ranking in Local search.
          </div>
        </div>
        <ng-template #nolocalPackTemplate>
          <div class="row text-center"
            style="height:300px;text-transform: uppercase;justify-content: center;align-items: center; font-weight: 500;">
            Currently you don't have any local rankings.
          </div>
        </ng-template>
      </div>
    </div>

    <!-- ----card 3--Table Top Pages rankings and traffic -- -->

    <div class=" pageDiv1" id="TopRankTable">
      <p class="reportHeading" style="font-weight:600">
        top pages by rankings and traffic
      </p>
      <hr>
      <div align="center" *ngIf="!toppagesReportObj?.results?.length>0">
        <p-progressSpinner></p-progressSpinner>
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>loading Top Pages Report,
            Please wait</b>
        </div>
      </div>

      <div *ngIf="toppagesReportObj?.results?.length>0">
        <div class="container-fluid" class="row" id="topPageRow" *ngFor="let toppage of toppagesReportObj.results">
          <div class="col-md-3" id="topPage" id="domain" style="overflow-wrap: anywhere;">
            <a [href]="toppage.page_address" target="_blank">{{filterTopDomains(toppage.page_address)}}</a>
          </div>
          <div class="col-md-3" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{toppage.metrics.organic.count+toppage.metrics.paid.count|number:'1.0-0'}}
            </span>
            <br><span class="subscript">TOTAL RANKINGS KEYWORDS</span>
          </div>
          <div class="col-md-3" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{toppage.metrics.organic.etv|number:'1.0-0'}}
            </span>
            <br><span class="subscript">ESTIMATED MONTHLY TRAFFIC</span>
          </div>
          <div class="col-md-3" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{toppage.metrics.organic.estimated_paid_traffic_cost|currency:'USD':'symbol':'1.0-0'}}
            </span>
            <br><span class="subscript">MONTHLY COST OF PAID TRAFFIC</span>
          </div>
        </div>
      </div>

    </div>

    <!-- ----card 4--COMPETITORS REPORT -- -->

    <div class="pageDiv2" id="competitorReport">
      <div class="row" style="height: 100%;width: 100%;">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <p style="text-align:center;font-weight:600" class="reportHeading">COMPETITORS REPORT</p>
        </div>
        <div class="col-md-4" style="text-align: right;padding-right: 5px;">
          <button style="margin: 1rem 0rem;text-align: right;" mat-raised-button [ngClass]="showReprocessCompetitorsActivity?'reprocessCompetitors':'disabledButton'"
          (click)="ReprocessCompetitors()" *ngIf="isAdmin && competitorsReportObj.status" [disabled]="!showReprocessCompetitorsActivity">Reprocess Competitors</button>
        </div>

      </div>
      <hr>
      <div align="center" *ngIf="!competitorsReportObj.status">
        <p-progressSpinner></p-progressSpinner>
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>loading Competitors Report,
            Please wait</b>
        </div>
      </div>
      <!-- <div align="center" *ngIf="competitorsReportObj?.results?.length==0"> -->
      <div align="center" *ngIf="this.competitorsReportObj.status == 'error' ">
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>No Competitor found</b>
        </div>
      </div>
      <!-- <div *ngIf="competitorsReportObj?.results?.length>0"> -->
      <div *ngIf="this.competitorsReportObj.status == 'completed' ">
        <div class="container-fluid" class="row" id="topPageRow" style="background-color: #d9d2e9;border-radius: 10px;">
          <div class="col-md-2" id="topPage" id="domain">
            <span style="font-size: 18px; font-weight: 500;color:#7e84f2;overflow-wrap: anywhere;">
              {{competitorsReportObj.analysis?.target}}
            </span>
            <span class="material-icons" (click)="newTab(practiceInfoObj.website_url)"
              style="font-size: 0.70em;  cursor: pointer;">
              open_in_new
            </span>
            <!-- <i class="pi pi-times-circle" *ngIf="isAdmin" (click)="confirm(practiceInfoObj.website_url)"
              style="font-size: 0.70em;  cursor: pointer;;padding-left:5px" pTooltip="Add domain to ignore list" >
            </i> -->
            <br><span class="subscript">YOUR DOMAIN</span>
          </div>
          <div class="col-md-2" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitorsReportObj.analysis?.score |number:'1.0-0'}}
            </span>
            <br><span class="subscript">SCORE</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitorsReportObj.analysis?.avg_position |number:'1.0-0'}}
            </span>
            <br><span class="subscript">AVERAGE RANK</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{totalCount |number:'1.0-0'}}
            </span>
            <br><span class="subscript">TOTAL RANKINGS KEYWORDS</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{paidTrafficCost|currency:'USD':'symbol':'1.0-0'}}
            </span>
            <br><span class="subscript">PAID TRAFFIC COST</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{estimateMonthlyTraficCount|number:'1.0-0'}}
            </span>
            <br><span class="subscript">ESTIMATED MONTHLY TRAFFIC</span>
          </div>

          <details [open] class="acordionForMobile">
            <summary style="font-size: 20px;padding-left: 10px;"></summary>
            <div class="row">
              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{competitorsReportObj.analysis?.avg_position |number:'1.0-0'}}
                </span>
                <br><span class="subscript">AVERAGE RANK</span>
              </div>

              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{totalCount |number:'1.0-0'}}
                </span>
                <br><span class="subscript">TOTAL RANKINGS KEYWORDS</span>
              </div>

              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{paidTrafficCost|currency:'USD':'symbol':'1.0-0'}}
                </span>
                <br><span class="subscript">PAID TRAFFIC COST</span>
              </div>

              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{estimateMonthlyTraficCount|number:'1.0-0'}}
                </span>
                <br><span class="subscript">ESTIMATED MONTHLY TRAFFIC</span>
              </div>
            </div>
          </details>
        </div>

        <div class="container-fluid" class="row " *ngFor="let competitor of competitorsReportObj.results;let i = index"
          id="topPageRow">
          <div class="col-md-2" id="topPage" id="domain">
            <span style="font-size: 18px; font-weight: 500;color:#7e84f2;overflow-wrap: anywhere" [class.strikethrough]="competitor.ignoredDomain && isAdmin">
              {{competitor.domain}}
            </span>
              <!-- addDomainToIgnoreList(competitor.website)   -->
            <span class="material-icons" (click)="newTab(competitor.website)"
              style="font-size: 0.70em; padding-top: 10%;  cursor: pointer;">
              open_in_new
            </span>
            <i class="pi pi-times-circle" *ngIf="isAdmin && !competitor.ignoredDomain" (click)="addDomainToIgnoreList(competitor.domain,i)" tooltipPosition="right"
              style="font-size: 0.70em;  cursor: pointer;;padding-left:5px" matTooltip="Add domain to ignore list">
            </i>
            <br><span class="subscript">WEBSITE DOMAIN</span>
          </div>
          <div class="col-md-2" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitor.score |number:'1.0-0'}}
            </span>
            <br><span class="subscript">SCORE</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitor.avg_position |number:'1.0-0'}}
            </span>
            <br><span class="subscript">AVERAGE RANK</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitor.totalCount |number:'1.0-0'}}
              <!-- {{competitor.full_domain_metrics.organic.count+competitor.full_domain_metrics.paid.count |number:'1.0-0'}} -->
            </span>
            <br><span class="subscript">TOTAL RANKINGS KEYWORDS</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitor.estimated_paid_traffic_cost|currency:'USD':'symbol':'1.0-0'}}
              <!-- {{competitor.full_domain_metrics.organic.estimated_paid_traffic_cost|currency:'USD':'symbol':'1.0-0'}} -->
            </span>
            <br><span class="subscript">PAID TRAFFIC COST</span>
          </div>

          <div class="col-md-2 acordionForDesk" id="topPage">
            <span style="font-size: 20px; font-weight: 500;">
              {{competitor.etv|number:'1.0-0'}}
              <!-- {{competitor.full_domain_metrics.organic.etv|number:'1.0-0'}} -->
            </span>
            <br><span class="subscript">ESTIMATED MONTHLY TRAFFIC</span>
          </div>

          <details [open] class="acordionForMobile">
            <summary style="font-size: 20px;padding-left: 10px;"></summary>

            <div class="row">
              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{competitor.avg_position |number:'1.0-0'}}
                </span>
                <br><span class="subscript">AVERAGE RANK</span>
              </div>
              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{competitor.totalCount |number:'1.0-0'}}
                  <!-- {{competitor.full_domain_metrics.organic.count+competitor.full_domain_metrics.paid.count |number:'1.0-0'}} -->
                </span>
                <br><span class="subscript">TOTAL RANKINGS KEYWORDS</span>
              </div>

              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{competitor.estimated_paid_traffic_cost|currency:'USD':'symbol':'1.0-0'}}
                  <!-- {{competitor.full_domain_metrics.organic.estimated_paid_traffic_cost|currency:'USD':'symbol':'1.0-0'}} -->
                </span>
                <br><span class="subscript">PAID TRAFFIC COST</span>
              </div>

              <div class="col-md-12" id="topPage1" style="text-align: center;">
                <span style="font-size: 20px; font-weight: 500;">
                  {{competitor.etv|number:'1.0-0'}}
                  <!-- {{competitor.full_domain_metrics.organic.etv|number:'1.0-0'}} -->
                </span>
                <br><span class="subscript">ESTIMATED MONTHLY TRAFFIC</span>
              </div>
            </div>
          </details>
        </div>
      </div>

    </div>

    <!-- ----Website Audit -- -->
    <div class="pageDiv" id="websiteAudit">
      <p style="text-align:center;font-weight:600" class="reportHeading">WEBSITE AUDIT</p>
      <hr>
      <div align="center" *ngIf="!onpageReportObj?.domain_info && onPageSummaryStatus == undefined">
        <p-progressSpinner></p-progressSpinner>
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>loading Website Audit Report,Please wait</b>
        </div>
      </div>

      <div *ngIf="onPageSummaryStatus == 'in-progress'  then  crawlingblock "></div>
      <ng-template #crawlingblock>
        <div style="text-align: center;">
          <p-progressSpinner></p-progressSpinner>
          <div style="font-size: 15px;font-weight: 300;"><b>Website crawling started please wait</b>
          </div>
        </div>
      </ng-template>

      <div *ngIf="onpageReportObj?.domain_info" [style]="{'text-align':'center','padding':'10px'}">
        <div class="container-fluid" style="background-color:#d9d2e9;border-radius: 10px;" class="row">
          <div class="col-md-12">
            <div class="container-fluid" class="row">
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;overflow-wrap: anywhere;">
                  {{onpageReportObj.domain_info.main_domain}}
                </span>
                <br><span class="subscript">WEBSITE DOMAIN</span>
                <br>
                <span *ngIf="onpageReportObj.domain_info.checks.ssl" style="color:green;font-size: 28px;"
                  class="material-icons">
                  lock
                </span>
                <span style="color:red; font-size: 28px;" *ngIf="!onpageReportObj.domain_info.checks.ssl"
                  class="material-icons">
                  lock_open
                </span>
                <br>
                <span *ngIf="onpageReportObj.domain_info.checks.ssl" style="font-size: 8px;">WEBSITE IS SECURE</span>
                <span *ngIf="!onpageReportObj.domain_info.checks.ssl" style="font-size: 8px;">WEBSITE IS NOT
                  SECURE</span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;">
                  {{onpageReportObj.domain_info.ip}}
                </span>
                <br><span class="subscript">IP ADDRESS</span>
                <br>
                <span class="material-icons" style="color:green;font-size: 28px;"
                  *ngIf="onpageReportObj.domain_info.checks.robots_txt">
                  check_circle
                </span>
                <span class="material-icons" style="color:red;" *ngIf="!onpageReportObj.domain_info.checks.robots_txt">
                  <i class="fas fa-times-circle" style="font-size:28px"></i>
                </span>
                <br>
                <span *ngIf="onpageReportObj.domain_info.checks.robots_txt" style="font-size: 8px;">ROBOT.TXT
                  DETECTED</span>
                <span *ngIf="!onpageReportObj.domain_info.checks.robots_txt" style="font-size: 8px;">ROBOT.TXT NOT
                  DETECTED</span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;">
                  {{onpageReportObj.domain_info.server}}
                </span>
                <br><span class="subscript">SERVER
                </span>
                <br>
                <span sitemap class="material-icons" style="color:green;font-size: 28px;"
                  *ngIf="onpageReportObj.domain_info.checks.sitemap">
                  check_circle
                </span>
                <span sitemap class="material-icons" style="color:red;"
                  *ngIf="!onpageReportObj.domain_info.checks.sitemap">
                  <i class="fas fa-times-circle" style="font-size:28px"></i>
                </span>
                <br>
                <span *ngIf="onpageReportObj.domain_info.checks.sitemap" style="font-size: 8px;">
                  SITEMAP DETECTED
                </span>
                <span *ngIf="!onpageReportObj.domain_info.checks.sitemap" style="font-size: 8px;">
                  SITEMAP NOT DETECTED
                </span>
              </div>
            </div>
            <div class="container-fluid" class="row">
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;">{{onpageReportObj.domain_info.total_pages}}</span>
                <br>
                <span style="font-size: 8px;"> TOTAL CRAWLED PAGES</span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;">{{onpageReportObj.page_metrics.links_internal}}</span>
                <br>
                <span style="font-size: 8px;"> EXTERNAL LINKS</span>
              </div>
              <div class="col-md-4">
                <span style="font-size: 20px; font-weight: 600;">{{onpageReportObj.page_metrics.links_external}}</span>
                <br>
                <span style="font-size: 8px;"> INTERNAL LINKS</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" class="row" id="auditTitleDiv" style="padding-top:2rem ;">
          <div class="col-md-6">
            <div class="container-fluid" class="row">
              <div class="col-md-3" align=center>
                <b>STATUS</b>
              </div>
              <div class="col-md-6" align=start style="margin-left: -3em;">
                <b>PARAMETER</b>
              </div>
              <div class="col-md-3" align=center>
                <b>PAGE COUNT</b>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="container-fluid" class="row">
              <div class="col-md-3" align=center>
                <b>STATUS</b>
              </div>
              <div class="col-md-6" align=start style="margin-left: -3em;">
                <b>PARAMETER</b>
              </div>
              <div *ngIf="onpageReportObj.page_metrics.checks[checkMarkArray[0].id]>0" class="col-md-3" align=center>
                <b>PAGE COUNT</b>
              </div>
            </div>
          </div>
        </div>
        <div class="auditTiitleForMobileView">
          <div class="auditTiitleForMobileView" style=" display: flex;
        justify-content: space-between;
        padding-left: 20%;
        padding-top: 5%;
        margin-bottom: -5%;" class="row">
            <div>
              <b>
                PARAMETER
              </b>
            </div>
            <div>
              <b>
                PAGES
              </b>
            </div>
          </div>
        </div>
        <div class="container-fluid" class="row" id="websiteAudit1" style="padding-top:2rem ;">
          <div class="col-md-6">
            <div *ngFor="let content of crossMarkArray">
              <div class="container-fluid" class="row">
                <div class="col-md-3" align=center>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]>0" class="material-icons"
                    style="color: red;" id="auditIcon">
                    close
                  </span>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id] <= 0" class="material-icons"
                    style="color:green;" id="auditIcon">
                    check
                  </span>
                </div>
                <div class="col-md-6" align=start id="auditTitle">
                  <span>{{content.name | titlecase }}</span>
                  <br>
                  <span class="descriptionDesk"
                    *ngIf="onpageReportObj.page_metrics.checks[content.id]>0">{{content.negativeDesc}}</span>
                  <span class="descriptionDesk"
                    *ngIf="onpageReportObj.page_metrics.checks[content.id]<=0">{{content.positiveDesc}}</span>
                </div>
                <div class="col-md-3" *ngIf="onpageReportObj.page_metrics.checks[content.id]>0" align=center>
                  <span id="auditId">{{onpageReportObj.page_metrics.checks[content.id]}}</span>
                </div>
                <div class="col-md-3" class="descriptionMobile" style="text-align:start">
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]>0">{{content.negativeDesc}}</span>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]<=0">{{content.positiveDesc}}</span>
                </div>
              </div>
              <br>
            </div>
          </div>
          <div class="col-md-6">
            <div *ngFor="let content of checkMarkArray">
              <div class="container-fluid" class="row">
                <div class="col-md-3" align=center>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]>0" class="material-icons"
                    style="color: red;" id="auditIcon">
                    close
                  </span>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id] <= 0" class="material-icons"
                    style="color:green;" id="auditIcon">
                    check
                  </span>
                </div>
                <div class="col-md-6" align=start id="auditTitle">
                  <span>{{content.name | titlecase }}</span>
                  <br>
                  <span class="descriptionDesk"
                    *ngIf="onpageReportObj.page_metrics.checks[content.id]>0">{{content.negativeDesc}}</span>
                  <span class="descriptionDesk"
                    *ngIf="onpageReportObj.page_metrics.checks[content.id]<=0">{{content.positiveDesc}}</span>
                </div>
                <div class="col-md-3" *ngIf="onpageReportObj.page_metrics.checks[content.id]>0" align=center>
                  <span id="auditId">{{onpageReportObj.page_metrics.checks[content.id]}}</span>
                </div>
                <div class="col-md-3" class="descriptionMobile" style="text-align:start">
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]>0">{{content.negativeDesc}}</span>
                  <span *ngIf="onpageReportObj.page_metrics.checks[content.id]<=0">{{content.positiveDesc}}</span>
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>


      <!-- else end -->
    </div>

    <!-- website performance screen started from here-->
    <div class="pageDivNew" id="websitePerformance">
      <p style="text-align:center;font-weight:600" class="reportHeading">WEBSITE PERFORMANCE</p>
      <hr>
      <div align="center" *ngIf="!pageInsightObj?.websiteImage">
        <p-progressSpinner></p-progressSpinner>
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>loading Website Performance Report,
            Please wait</b>
        </div>
      </div>
      <div class="row" *ngIf="pageInsightObj.websiteImage" style="padding:3%3%3%3%;">
        <div class="col-md-5">
          <img src={{pageInsightObj.websiteImage}} width="100%" height="75%">
        </div>
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-4" id="cardmobileview">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
                <p class="subscriptNew" style="text-align:center;">
                  <b>{{pageInsightObj.cardFirst?.title}}</b>
                </p>
                <center>
                  <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFirst?.displayValue) >= 0 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 2,
                             'orange' :   parseInteger(pageInsightObj.cardFirst?.displayValue) >= 2 && parseInteger(pageInsightObj.cardFirst?.displayValue) < 4 ,
                             'redSlow' :  parseInteger(pageInsightObj.cardFirst?.displayValue) >= 4}">
                    {{(pageInsightObj.cardFirst?.displayValue).replace('s','')}}
                  </div>
                </center>
                <center>
                  <div class="subscript">seconds</div>
                </center><br>
                <center>
                  <div class="subscriptNew1">
                    First Contentful Paint marks the time at which the first text or image is painted
                  </div>
                  <span id="learnMoreLink">
                    <a href="https://web.dev/first-contentful-paint/?utm_source=lighthouse&utm_medium=unknown"
                      target="_blank">Learn More</a>
                  </span>
                </center>
              </p-card>
            </div>
            <div class="col-md-4" id="cardmobileview">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}" styleClass="p-card-shadow">
                <p class="subscriptNew" style="text-align:center;"><b>{{pageInsightObj.cardSecond?.title}}</b></p>
                <center>
                  <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSecond?.displayValue) >= 0 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 2,
                             'orange' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 2 && parseInteger(pageInsightObj.cardSecond?.displayValue) < 4 ,
                             'redSlow' : parseInteger(pageInsightObj.cardSecond?.displayValue) >= 4}">
                    {{(pageInsightObj.cardSecond?.displayValue).replace('s','')}}
                  </div>
                </center>
                <center>
                  <div class="subscript">seconds</div>
                </center><br>
                <center>
                  <div class="subscriptNew1">Time to interactive is the amount of time it takes for the page to become
                    fully interactive</div>
                  <span id="learnMoreLink">
                    <a href="https://web.dev/interactive/?utm_source=lighthouse&utm_medium=unknown" target="_blank">
                      Learn More</a>
                  </span>
                </center>
              </p-card>
            </div>
            <div class="col-md-4" id="cardmobileview3">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
                <p class="subscriptNew" style="text-align:center;"><b>{{pageInsightObj.cardThree?.title}}</b></p>
                <center>
                  <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardThree?.displayValue) >=0 && parseInteger(pageInsightObj.cardThree?.displayValue) < 2,
                             'orange' :parseInteger(pageInsightObj.cardThree?.displayValue) >=2 && parseInteger(pageInsightObj.cardThree?.displayValue) < 4 ,
                             'redSlow' : parseInteger(pageInsightObj.cardThree?.displayValue) >= 4}">
                    {{(pageInsightObj.cardThree?.displayValue).replace('s','')}}
                  </div>
                </center>
                <center>
                  <div class="subscript">seconds</div>
                </center><br>
                <center>
                  <div class="subscriptNew1">Speed Index shows how quickly the contents of a page are visibly
                    populated.</div>
                  <span id="learnMoreLink"><a
                      href="https://web.dev/speed-index/?utm_source=lighthouse&utm_medium=unknown" target="_blank">
                      Learn More</a>
                  </span>
                </center>
              </p-card>
            </div>
          </div>
          <div class="row" style="padding-top: 20px;">
            <div class="col-md-4" id="cardmobileview4">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
                <div>
                  <p class="subscriptNew" style="text-align:center;"><b>{{pageInsightObj.cardFour?.title}}</b></p>
                  <center>
                    <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFour?.displayValue) >=0 && parseInteger(pageInsightObj.cardFour?.displayValue) < 2,
                               'orange' :parseInteger(pageInsightObj.cardFour?.displayValue) >=2 && parseInteger(pageInsightObj.cardFour?.displayValue) < 4 ,
                               'redSlow' : parseInteger(pageInsightObj.cardFour?.displayValue) >= 4}">
                      {{(pageInsightObj.cardFour?.displayValue).replace('ms','')}}
                    </div>
                  </center>
                  <center>
                    <div class="subscript">miliseconds</div>
                  </center><br>
                  <center>
                    <div class="subscriptNew1">Sum of all time periods between FCP and Time to Interactive, when
                      task
                      length exceeded 50ms.</div>
                    <span id="learnMoreLink"><a
                        href="https://web.dev/lighthouse-total-blocking-time/?utm_source=lighthouse&utm_medium=unknown"
                        target="_blank">
                        Learn More</a>
                    </span>
                  </center>
                </div>
              </p-card>
            </div>
            <div class="col-md-4" id="cardmobileview">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
                <p class="subscriptNew" style="text-align:center;"><b>{{pageInsightObj.cardFive?.title}}</b></p>
                <center>
                  <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardFive?.displayValue) >=0 && parseInteger(pageInsightObj.cardFive?.displayValue) < 2,
                             'orange' :parseInteger(pageInsightObj.cardFive?.displayValue) >=2 && parseInteger(pageInsightObj.cardFive?.displayValue) < 4 ,
                             'redSlow' : parseInteger(pageInsightObj.cardFive?.displayValue) >= 4}">
                    {{(pageInsightObj.cardFive?.displayValue).replace('s','')}}
                  </div>
                </center>
                <center>
                  <div class="subscript">seconds</div>
                </center><br>
                <center>
                  <div class="subscriptNew1">Largest Contentful Paint marks the time at which the largest text or
                    image
                    is
                    painted.</div>
                  <span id="learnMoreLink"><a
                      href="https://web.dev/lighthouse-largest-contentful-paint/?utm_source=lighthouse&utm_medium=unknown"
                      target="_blank">
                      Learn More</a>
                  </span>
                </center>
              </p-card>
            </div>
            <div class="col-md-4" id="cardmobileview">
              <p-card [style]="{'height':'15rem','box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),
                              0 2px 10px -1px rgba(0, 0, 0, 0.12)'}">
                <p class="subscriptNew" style="text-align:center;"><b>{{pageInsightObj.cardSix?.title}}</b></p>
                <center>
                  <div [ngClass]="{ 'greenfast' :parseInteger(pageInsightObj.cardSix?.displayValue) >=0 && parseInteger(pageInsightObj.cardSix?.displayValue) < 2,
                             'orange' :parseInteger(pageInsightObj.cardSix?.displayValue) >2 && parseInteger(pageInsightObj.cardSix?.displayValue) < 4 ,
                             'redSlow' : parseInteger(pageInsightObj.cardSix?.displayValue) >= 4}">
                    {{(pageInsightObj.cardSix?.displayValue).replace('s','')}}
                  </div>
                </center>
                <center>
                  <div class="subscript">seconds</div>
                </center><br>
                <center>
                  <div class="subscriptNew1">Cumulative Layout Shift measures the movement of visible elements
                    within
                    the
                    viewport.</div>
                  <span id="learnMoreLink"><a href="https://web.dev/cls/?utm_source=lighthouse&utm_medium=unknown"
                      target="_blank">
                      Learn More</a>
                  </span>
                </center>
              </p-card>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- Competitors Reviews Started From Here-->
    <div class="pageDiv1" id="competitorsReviews">
      <p style="text-align:center;font-weight:600" class="reportHeading">Competitors Google Reviews</p>
      <hr>
      <!-- <div align="center" *ngIf="!competitorsReportObj.results"> -->
      <div align="center" *ngIf="!practiceInfoObj.reviews">
        <!-- <div align="center" *ngIf="!competitorsReviewObject?.results?.length>0"> -->
        <p-progressSpinner></p-progressSpinner>
        <div align="center" style="font-size: 15px;font-weight: 300;"><b>loading Competitors Reviews,
            Please wait</b>
        </div>
      </div>
      <div class="row competitorsReviewCol6" *ngIf="practiceInfoObj.reviews">
        <!-- <div class="row competitorsReviewCol6" *ngIf="competitorsReviewObject.results && practiceInfoObj"> -->
        <div class="col-md-6" id="reviewTable" style="padding-left:0px;">
          <div class="container-fluid" class="row" id="topPageRow"
            (click)="displayReviewsOfPractice();currentSeletedReview = -1"
            [ngStyle]="currentSeletedReview==-1?{'background-color':'#d9d2e9','border-radius': '10px;'}:{'background-color':'#f7f7f7'}">

            <div class="col-md-6" id="competitorsReviewDiv" id="domain">
              <span style="font-size: 18px; font-weight: 500;overflow-wrap: anywhere;">
                {{this.rankingReportObj?.analysis?.target}}
                <br><span class="subscript">YOUR DOMAIN</span>
              </span>
            </div>

            <div class="col-md-3" id="topPage">
              <span style="font-size: 20px; font-weight: 500;">
                {{practiceInfoObj.user_ratings_total}}
              </span>
              <br><span class="subscript">TOTAL REVIEWS</span>
            </div>
            <div class="col-md-3" id="topPage">
              <span style="font-size: 20px; font-weight: 500;">
                {{practiceInfoObj.ratings}}
              </span>
              <br><span class="subscript">TOTAL RATINGS</span>
            </div>
            <!-- <div class="col-md-2" id="competitorsReviewDiv" id="domain" style="padding: 2%;">
            <span class="material-icons" (click)="newTab(practiceInfoObj.website_url)">
              open_in_new
            </span>
          </div> -->
          </div>
          <div class="container-fluid" class="row text-center" id="topPageRow" id="reviewRow"
            *ngFor="let competitorsReview of competitorsReviewObject.results ;let i = index"
            (click)="displayReviews(competitorsReview);currentSeletedReview = i"
            [ngStyle]="currentSeletedReview==i?{'background-color':'#d9d2e9','border-radius': '10px;'}:{'background-color':'#f7f7f7'}">

            <div class="col-md-6" id="competitorsReviewDiv" id="domain">
              <span style="font-size: 18px; font-weight: 500;overflow-wrap: anywhere;">
                {{(competitorsReview['domain'])}}
                <br><span class="subscript">WEBSITE DOMAIN</span>
              </span>
              <ng-template #NoDomain style="font-size: 20px; font-weight: 500;">
                <span style="font-size: 20px; font-weight: 500;">No Domain</span>
              </ng-template>
            </div>

            <div class="col-md-3" id="competitorsReviewDiv">
              <span style="font-size: 20px; font-weight: 500;"
                *ngIf="competitorsReview['user_ratings_total'] else NoUserRating">
                {{competitorsReview['user_ratings_total']}}
              </span>
              <br><span class="subscript">TOTAL REVIEWS</span>
              <ng-template #NoUserRating style="font-size: 20px; font-weight: 500;">
                <span style="font-size: 20px; font-weight: 500;">0</span>
              </ng-template>
            </div>
            <div class="col-md-3" id="competitorsReviewDiv">
              <span style="font-size: 20px; font-weight: 500;" *ngIf="competitorsReview['rating'] else NoRating">
                {{competitorsReview['rating']}}
              </span>
              <ng-template #NoRating style="font-size: 20px; font-weight: 500;">
                <span style="font-size: 20px; font-weight: 500;">0</span>
              </ng-template>
              <br><span class="subscript">TOTAL RATINGS</span>
            </div>
            <!-- <div class="col-md-2" id="competitorsReviewDiv" id="domain" style="padding: 2%;">
            <span class="material-icons" (click)="newTab(competitorsReviewObject.results[i]['website'])">
              open_in_new
            </span>
          </div> -->
          </div>
        </div>
        <div class="col-md-6" *ngIf="reviews else NoReviews" id="reviewCards">
          <div *ngIf="reviews.length>0 else NoReviews">
            <div class="reviewDiv" *ngFor="let content of reviews">
              <p-card
                [style]="{'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'}"
                styleClass="p-card-shadow">
                <p-header>
                  <div class="row">
                    <div class="col-1 hidePadding" *ngIf="content!=undefined">
                      <img src={{content.profile_photo_url}} id="profilePhotoCss">
                    </div>

                    <div class="col-11 hidePadding" id="authorInfo">
                      <div>
                        {{(content['author_name'])}}
                      </div><br>
                      <div style="margin-top: -2em;">
                        <star-rating [value]="(content['rating'])" [totalstars]="5" checkedcolor="#fabb05"
                          uncheckedcolor="#b0b0b0" size="24px" readonly="true"></star-rating>
                        <!-- <p-rating [ngModel]="content.rating" readonly="true" stars="5" [cancel]="false"></p-rating> -->
                        <b style="padding-top:8px;margin-top: -2em;">{{(content.relative_time_description)}}</b>
                      </div>
                    </div>
                  </div>
                </p-header>
                <p-card-content>
                  <!-- {{(content['text'])}} -->
                  <div class="contentText" [class.show]="content.show">
                    {{(content['text'])}}
                  </div>
                  <div *ngIf="content.text.length>200" style="text-align: center;font-weight: 500;font-size: 16px;">
                    <a (click)="showMoreContent(content)" id="readMoreBtn">{{ content.show ? 'Read Less': 'Read More'
                      }}</a>
                  </div>
                </p-card-content>
              </p-card>
            </div>
          </div>
        </div>
        <ng-template #NoReviews>
          <div style="padding-top: 20%;font-size: 20px;" align=center>
            <b>No Reviews for this domain</b>
          </div>
        </ng-template>
      </div>

    </div>

    <!-- feedback Screen-->
    <div class="pageDiv2" id="feedback">
      <p style="text-align:center;font-weight:600" class="reportHeading">Feedback</p>
      <hr>
      <div *ngIf="feedbackSubmitted">
        <div align="center">
          <h1>
            Thank you for submting your feedback
          </h1>
          <a (click)="editFeedBAck()">click here to edit feedback</a>
        </div>
      </div>
      <div class="row" [hidden]="feedbackSubmitted" style="margin:1%">
        <div class="col-md-6">
          <img src="assets/feedback_img.png" height="auto" width="100%" alt="image" style="height: fit-content;">
        </div>
        <div class="col-md-6" style="padding-right: 4%;height: 25em;">
          <div>
            <div align="center" style="margin: 2%;">
              <b>Help us to improve more. Your feedback is valuable to us.</b>
            </div>
            <div class="row" class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <textarea [(ngModel)]="feedbackText" [rows]="12" pInputTextarea
                  placeholder="Please Enter Your Feedback Here"></textarea>
              </div>
            </div>
            <br>
            <div align="center">
              <button id="requestMoreInfoBtn" type="submit" (click)="submitFeedback()"
                style="padding: 8px 10px 20px 10px;">Submit</button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <!--Error screen-->

  <div *ngIf="!loading && showError">
    <div>
      <div align="center" style="margin-top: 160px;font-size: 20px;">
        <div>
          <b>Error in getting ranking report. Would you like to try again?</b>
        </div>
        <div class="row" style="padding: 10px; text-align: center; display: block;">
          <span style="padding: 10px;">
            <p-button (onClick)="getRankings()"> Yes </p-button>
          </span>
          <span style="padding: 10px;">
            <p-button (onClick)="startOver()"> No </p-button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog> -->
