import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RankCheckerService } from '../_services/rank-checker.service';
import { RankingReportService } from '../_services/ranking-report.service';
import { StarRatingComponent } from 'ng-starrating';
import * as moment from 'moment';
import {ConfirmationService} from 'primeng/api';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ranking-report',
  templateUrl: './ranking-report.component.html',
  styleUrls: ['./ranking-report.component.scss'],
})
export class RankingReportComponent implements OnInit, OnDestroy {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  public docId: string = 'a';
  colorclass: string = "";
  selectedHero: string = 'hero';
  onPageSummaryStatus: string;
  firstPageKeyWordCount: number;
  secondPageKeyWordCount: number;
  totalCount: number;
  estimateMonthlyTraficCount: number;
  paidTrafficCost: number;
  firstPositionCount: number;
  secondTothirdtPositionCount: number;
  fourToTenPositionCount: number;
  loading: boolean = true;
  rankingReportObj: any = {};
  competitorsReportObj: any = {};
  toppagesReportObj: any = {};
  practiceInfoObj: any = {};
  events: any = [];
  localPackResults = [];
  totalLocalPackResults: number = 0;
  localPackAtRank1: any = [];
  localPackAtRank2: any = [];
  localPackAtRank3: any = [];
  showLocalResults: any = [];
  showError: boolean = false;
  currentLocalPack: any = 0;
  competitorsReviewObject: any = [];
  moment: any = moment;
  reviews: any;
  show: boolean = false;
  showShortDesciption = true
  rankingFlag: boolean = false;
  competitorsFlag: boolean = false;
  topPagesFlag: boolean = false;
  currentSeletedReview = -1;
  panelOpenState: boolean = false;
  dummyArray1: any = [
    "Practice Google Rankings",
    "Website Audit",
    "Local Search Rankings",
  ];
  dummyArray2 = [
    "Website Performance",
    "Top Pages by Ranking and Traffic",
    "Reviews",
  ];

  dummyArray3 = [
    "Competitors Report",
    "Loverall Score",
    "Recommendations"];
  pageInsightObj: any = {};
  onpageReportObj: any = {};
  onpageReportObjCheckContent: any = [];
  checkMarkArray: any = [];
  crossMarkArray: any = [];
  content: any;
  feedbackText: any;
  feedbackSubmitted: boolean = false;
  iframe: boolean;
  userType:string;
  isAdmin: any;
  showReprocessCompetitorsActivity: boolean;
  constructor(
    public route: ActivatedRoute,
    private rankingReportService: RankingReportService,
    private rankCheckerService: RankCheckerService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private _snackBar: MatSnackBar,
  ) {
    if (window.location !== window.parent.location) {
      this.iframe = true;
    }
    this.rankingReportService.reportUpdated$.subscribe(report => {

      this.practiceInfoObj = this.rankingReportService.practiceInfo;
      console.log(this.practiceInfoObj );
      if (report.name == 'error') {
        //console.log(report);
        if (report.errorIn == 'rank-checker') {
          this.router.navigateByUrl('');
        }
        else if (report.errorIn == 'resultDocError') {
          this.loading = false;
          this.showError = true
        }
      }
      if (report.name == 'rankings') {
        this.rankingReportObj = report;
        //console.log(this.rankingReportObj);
        //console.log(this.rankingReportObj.status)
        this.countKeyworkds();
        this.rankingFlag = true;
        this.loading = false
      }

      if (report.name == 'localPack') {
        //console.log(report);
        this.localPackResults = report.localpack;
        this.filterLocalPackResults();
      }

      if (report.name == 'competitors') {
        if (this.practiceInfoObj.reviews)
          this.reviews = this.practiceInfoObj.reviews;
        this.competitorsReportObj = report;
        //console.log(this.competitorsReportObj)
        //console.log(this.competitorsReportObj.status);
        if (this.competitorsReportObj.status != "error") {
          // if (this.practiceInfoObj.reviews)
          //   this.reviews = this.practiceInfoObj.reviews;
          // this.competitorsReportObj.maxRankedKeyword = report.results[0].full_domain_metrics.organic.count + report.results[0].full_domain_metrics.paid.count;
          // this.competitorsReportObj.minRankedKeyword = report.results[report.results.length - 1].full_domain_metrics.organic.count + report.results[report.results.length - 1].full_domain_metrics.paid.count;
          // this.competitorsReportObj.maxAvgPosition = 0;
          // this.competitorsReportObj.minAvgPosition = 100;
          // this.competitorsReportObj.results.forEach((data) => {
          //   if (data.avg_position > this.competitorsReportObj.maxAvgPosition)
          //     this.competitorsReportObj.maxAvgPosition = data.avg_position;
          //   if (data.avg_position < this.competitorsReportObj.minAvgPosition)
          //     this.competitorsReportObj.minAvgPosition = data.avg_position;
          // });
          // report.analysis.overall_analysis.score = (((this.totalCount / this.competitorsReportObj.maxRankedKeyword) * 100) / 2) + (((this.competitorsReportObj.minAvgPosition / report.analysis.overall_analysis.avg_position) * 100) / 2);
          // this.competitorsReportObj.results.forEach((data) => {
          //   data.score = ((((data.full_domain_metrics.organic.count + data.full_domain_metrics.paid.count) / this.competitorsReportObj.maxRankedKeyword) * 100) / 2) + (((this.competitorsReportObj.minAvgPosition / data.avg_position) * 100) / 2);
          //   data.newScore = ((((data.full_domain_metrics.organic.count + data.full_domain_metrics.paid.count) / this.competitorsReportObj.maxRankedKeyword) * 100) / 2) + (((this.competitorsReportObj.maxAvgPosition / data.avg_position) * 100) / 2);
          // })
          let newArr = this.competitorsReportObj.results.sort(function (a, b) {
            return b.score - a.score;
          });
          this.competitorsReviewObject.results = Object.assign([], this.competitorsReportObj.results);
          this.competitorsReviewObject.results.sort(function (a, b) {
            // //console.log(b['user_ratings_total'] || isNaN(b['user_ratings_total']));
            if (!b.hasOwnProperty('user_ratings_total') || isNaN(b['user_ratings_total']))
              return -1;
            if (!a.hasOwnProperty('user_ratings_total') || isNaN(a['user_ratings_total']))
              return 1;
            return b['user_ratings_total'] - a['user_ratings_total']

          });
          //console.log(this.competitorsReviewObject.results);
          //console.log(this.competitorsReportObj);
          this.setIgnoreDomainList()
          this.competitorsFlag = true;
        }
      }

      if (report.name == 'top-pages') {
        this.toppagesReportObj = report;
        this.topPagesFlag = true;
        //console.log(this.toppagesReportObj);
      }
      if (report.name == 'pageInsights') {
        this.pageInsightObj = report.results;
        this.pageInsightObj.websiteImage = this.pageInsightObj?.results?.lighthouseResult?.audits["final-screenshot"]?.details?.data;
        this.pageInsightObj.cardFirst = this.pageInsightObj?.results?.lighthouseResult?.audits["first-contentful-paint"];
        this.pageInsightObj.cardSecond = this.pageInsightObj?.results?.lighthouseResult?.audits["interactive"];
        this.pageInsightObj.cardThree = this.pageInsightObj?.results?.lighthouseResult?.audits["speed-index"];
        this.pageInsightObj.cardFour = this.pageInsightObj?.results?.lighthouseResult?.audits["total-blocking-time"];
        this.pageInsightObj.cardFive = this.pageInsightObj?.results?.lighthouseResult?.audits["largest-contentful-paint"];
        this.pageInsightObj.cardSix = this.pageInsightObj?.results?.lighthouseResult?.audits["cumulative-layout-shift"];
        //console.log(report.results);
      }
      if (report.name == 'on-page') {
        //console.log(report);
        try {
          this.onpageReportObj = report?.results?.results?.tasks[0]?.result[0];
          //console.log(this.onpageReportObj);
          this.onPageSummaryStatus = report?.status;
          //console.log(report?.status);
          //console.log("status is:->", this.onPageSummaryStatus);
          this.onpageReportObjCheckContent = [
            {
              id: "no_description",
              name: "No Description",
              positiveDesc: "There is no page with an empty or absent description meta tag",
              negativeDesc: "Pages with an empty or absent description meta tag available"
            },
            {
              id: "duplicate_meta_tags",
              name: "Duplicate Meta Tags",
              positiveDesc: "There is no page with more than one meta tag of the same type available",
              negativeDesc: "Pages with more than one meta tag of the same type available for canonical pages only"
            },
            {
              id: "no_encoding_meta_tag",
              name: "No Encoding Meta Tag",
              positiveDesc: "There is no page without Content-Type information",
              negativeDesc: "Pages without Content-Type informative only if the encoding is not explicit in the Content-Type header for example: Content-Type: text/html; charset=utf8"
            },
            {
              id: "large_page_size",
              name: "Large Page Size",
              positiveDesc: "There is no page that has the size exceeding 256 kbytes",
              negativeDesc: "Pages that have the size exceeding 256 kbytes"
            },
            {
              id: "small_page_size",
              name: "Small Page Size",
              positiveDesc: "There is no page that has the size smaller than 1024 bytes",
              negativeDesc: "Pages that have the size smaller than 1024 bytes"
            },
            {
              id: "is_http",
              name: "IS Http",
              positiveDesc: "There is no page with http protocol",
              negativeDesc: "Pages with the http protocol"
            },
            {
              id: "title_too_long",
              name: "Title Too Long",
              positiveDesc: "There is no page with the content of title tag exceeding 65 characters",
              negativeDesc: "Pages with the content of title tag exceeding 65 characters"
            },
            {
              id: "no_title",
              name: "No Title",
              positiveDesc: "There is no page with empty or absent title tags",
              negativeDesc: "Pages with empty or absent title tags"
            },
            {
              id: "title_too_short",
              name: "Title Too Short",
              positiveDesc: "There is no page with titles shorter than 30 characters",
              negativeDesc: "The number of pages that have titles shorter than 30 characters"
            },
            {
              id: "duplicate_title_tag",
              name: "Duplicate Title Tag",
              positiveDesc: "There is no page that has more than one title tag",
              negativeDesc: "Pages that have more than one title tag"
            },
            {
              id: "no_h1_tag",
              name: "No H1 Tag",
              positiveDesc: "There is no page with empty or absent h1 tags",
              negativeDesc: "Pages with empty or absent h1 tags"
            },
            {
              id: "no_favicon",
              name: "No Favicon",
              positiveDesc: "There is no page without favicon",
              negativeDesc: "Pages with no favicon"
            },
            {
              id: "no_image_alt",
              name: "No Image Alt",
              positiveDesc: "There is no image without alt tag",
              negativeDesc: "Images without alt tag"
            },
            {
              id: "no_image_title",
              name: "No Image Title",
              positiveDesc: "There is no image without title tag",
              negativeDesc: "Images without title tag"
            },
            {
              id: "seo_friendly_url",
              name: "Seo Friendly Url",
              positiveDesc: "All pages have SEO-friendly urls",
              negativeDesc: "Pages without SEO-friendly urls"
            },
            {
              id: "high_waiting_time",
              name: " High Waiting Time Url",
              positiveDesc: "There is no page with high waiting time exceeding 1.5 seconds",
              negativeDesc: "Pages with high waiting time exceeding 1.5 seconds"
            },
            {
              id: "high_loading_time",
              name: "High Loading Time",
              positiveDesc: "There is no page with high loading time exceeding 3 seconds",
              negativeDesc: "Pages with high loading time exceeding 3 seconds"
            },
            {
              id: "is_broken",
              name: "Is Broken",
              positiveDesc: "There is no page with 404 response codes",
              negativeDesc: "Pages with 404 response codes"
            },
            {
              id: "no_doctype",
              name: "No Doctype",
              positiveDesc: "There is no page without the DOCTYPE declaration",
              negativeDesc: "Pages without the DOCTYPE declaration"
            },
            {
              id: "deprecated_html_tags",
              name: "Deprecated Html Tags",
              positiveDesc: "There is no page with deprecated HTML tags",
              negativeDesc: "Pages with deprecated HTML tags"
            },
          ];
          let checkMarkArrayLocal = [];
          let crossArryLocal = []
          for (let data of this.onpageReportObjCheckContent) {
            this.content = data;
            if (this.onpageReportObj.page_metrics.checks[this.content.id] <= 0) {
              this.content.icon = true;
              checkMarkArrayLocal.push(this.content);
            }
            if (this.onpageReportObj.page_metrics.checks[this.content.id] > 0) {
              this.content.icon = false;
              crossArryLocal.push(this.content)
            }
          }
          this.crossMarkArray = [];
          this.checkMarkArray = [];
          this.onpageReportObjCheckContent = crossArryLocal.concat(checkMarkArrayLocal)
          for (let data of this.onpageReportObjCheckContent) {
            if (this.crossMarkArray.length < 10)
              this.crossMarkArray.push(data);
            else
              this.checkMarkArray.push(data);
          }
        }
        catch
        {
          this.onpageReportObj = [];
        }
      }
      if (report.name == 'competitorsReviews') {
        this.competitorsReviewObject = report;
        //console.log(this.competitorsReviewObject);
        this.reviews = this.practiceInfoObj.reviews;


        if (this.competitorsReviewObject.results) {
          for (var i = 0; i < this.competitorsReviewObject.results.length; i++) {
            if (this.competitorsReviewObject.results[i]['user_ratings_total']) {
              //console.log(this.competitorsReviewObject.results[i]['user_ratings_total']);
            }
            else {
              this.competitorsReviewObject.results[i]['user_ratings_total'] = null;
            }
          }
        }

        if (this.practiceInfoObj.reviews)
          this.reviews = this.practiceInfoObj.reviews;
        //sort reviews desending order;

        let newArr = this.competitorsReviewObject.results.sort(function (a, b) {
          if (!b.hasOwnProperty('user_ratings_total'))
            return -1;
          if (!a.hasOwnProperty('user_ratings_total'))
            return 1;
          return b['user_ratings_total'] - a['user_ratings_total']

        });
        //console.log(newArr);
        //this.reviews = this.competitorsReviewObject['results'][0]['results']['reviews'];
        //console.log(this.reviews);
      }
      // if (this.rankingFlag && this.topPagesFlag && this.competitorsFlag)
      //   this.loading = false

    });
  }

  async setIgnoreDomainList(){
    let excludeDomainList = await this.rankingReportService.getIgnoredDomainList()
    this.competitorsReportObj.results.map(competitor=>{
          if(excludeDomainList.includes(competitor.domain)){
            competitor.ignoredDomain = true;
            this.showReprocessCompetitorsActivity = true;
          }
          else{
            competitor.ignoredDomain = false;
          }
    })
    console.log(this.competitorsReportObj)
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);

    this.route.paramMap.subscribe(params => {
      this.docId = params.get('docId');
      let resp = this.rankingReportService.getResults(this.docId);
    });
    this.events = [
      { title: "G", data: "NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONON GOOGLE", image: "assets/googlelogo.png" },
      { title: '1', data: "NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONON GOOGLE" },
      { title: "2-3", data: "NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONON GOOGLE" },
      { title: "4-10", data: "NUMBER OF KEYWORDS YOUR WEBSITE IS RANKING FOR IN POSITIONON GOOGLE" },
    ];
    this.checkFeedBack();
    this.getQueryParameter();
  }

  getQueryParameter(){
    this.route.queryParams
    .subscribe(params => {
      if(params.admin){
        let parameterValueOfAdmin = params.admin.toLowerCase();
        if(parameterValueOfAdmin == 'true'){
          this.isAdmin = true;
        }
        else{
          this.isAdmin = false;
        }
      }
      else{
        this.isAdmin = false;
        //console.log("Parametr for admin not passes");
      }
    }
  );
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  onScroll() {
    // up arrow condition
    if (window.pageYOffset > 1000) {
      document.getElementById('upIcon').style.display = 'block';
    }
    else {
      document.getElementById('upIcon').style.display = 'none';
    }
  }

  displayReviews(competitorsReview) {
    this.reviews = competitorsReview['reviews'];
    //sort reviews desending order;
    if (this.reviews && this.reviews.length > 0) {
      this.reviews.sort(function (a, b) {
        return b.rating - a.rating;
      });
    }
    //console.log(this.reviews)
  }

  displayReviewsOfPractice() {
    this.reviews = this.practiceInfoObj.reviews;
    //sort reviews desending order;
    this.reviews.sort(function (a, b) {
      return a.rating - b.rating;
    });
  }

  onRate($event: { oldValue: number, newValue: number, starRating: StarRatingComponent }) {
    alert(`Old Value:${$event.oldValue},
      New Value: ${$event.newValue},
      Checked Color: ${$event.starRating.checkedcolor},
      Unchecked Color: ${$event.starRating.uncheckedcolor}`);
  }

  countKeyworkds() {
    const organic = this.rankingReportObj?.analysis?.metrics?.organic;
    this.firstPageKeyWordCount = (organic ? organic.pos_1 : 0) + (organic ? organic.pos_2_3 : 0) + (organic ? organic.pos_4_10 : 0);
    this.secondPageKeyWordCount = organic ? organic.pos_11_20 : 0;
    this.totalCount = organic ? organic.count : 0;
    this.estimateMonthlyTraficCount = organic ? organic.etv : 0;
    // this.estimateMonthlyTraficCount = Math.floor(this.estimateMonthlyTraficCount);
    this.paidTrafficCost = organic ? organic.estimated_paid_traffic_cost : 0;
    this.firstPositionCount = organic ? organic.pos_1 : 0;
    this.secondTothirdtPositionCount = organic ? organic.pos_2_3 : 0;
    this.fourToTenPositionCount = organic ? organic.pos_4_10 : 0;
  }

  filterTopDomains(url) {
    let l = new URL(url)
    if (l.pathname == '/')
      return l.href.replace(/\/$/, '');
    else
      return l.pathname.replace(/\/$/, '');
  }

  filterLocalPackResults() {
    this.totalLocalPackResults = this.localPackResults.length;
    //console.log(this.totalLocalPackResults);

    this.showLocalResults = this.localPackResults.sort((a, b) =>
      b.keyword_data.keyword_info.monthly_searches[0].search_volume - a.keyword_data.keyword_info.monthly_searches[0].search_volume)
    // //console.log(this.showLocalResults);

    this.localPackAtRank1 = this.localPackResults.filter(a => a.ranked_serp_element.serp_item.rank_group == 1);
    // //console.log(this.localPackAtRank1);
    this.localPackAtRank2 = this.localPackResults.filter(a => a.ranked_serp_element.serp_item.rank_group == 2);
    // //console.log(this.localPackAtRank2);
    this.localPackAtRank3 = this.localPackResults.filter(a => a.ranked_serp_element.serp_item.rank_group == 3);
    // //console.log(this.localPackAtRank3);

  }

  getRankings() {
    this.loading = true;
    this.showError = false;
    let data = {
      docId: this.docId,
      domain:this.practiceInfoObj?.website_url ,
      country: this.practiceInfoObj?.address.country
    }
    this.rankCheckerService.generateRankings(data).then(
      resp => {
        //console.log(resp)
      });
  }
  startOver() {
    this.router.navigateByUrl("/");
  }

  showLocalPackAtRank(rank: any) {

    if (rank == 0) {
      this.currentLocalPack = 0;
      this.localPackResults.sort((a, b) =>
        b.keyword_data.keyword_info.monthly_searches[0].search_volume -
        a.keyword_data.keyword_info.monthly_searches[0].search_volume)
      this.showLocalResults = this.localPackResults;
      //console.log(this.showLocalResults);

    }

    else if (rank == 1) {
      this.currentLocalPack = 1;
      this.localPackAtRank1.sort((a, b) =>
        b.keyword_data.keyword_info.monthly_searches[0].search_volume -
        a.keyword_data.keyword_info.monthly_searches[0].search_volume)
      this.showLocalResults = this.localPackAtRank1;
      //console.log(this.showLocalResults);
    }

    else if (rank == 2) {
      this.currentLocalPack = 2;
      this.localPackAtRank2.sort((a, b) =>
        b.keyword_data.keyword_info.monthly_searches[0].search_volume -
        a.keyword_data.keyword_info.monthly_searches[0].search_volume)
      this.showLocalResults = this.localPackAtRank2;
      //console.log(this.showLocalResults);
    }

    else if (rank == 3) {
      this.currentLocalPack = 3;
      this.localPackAtRank3.sort((a, b) =>
        b.keyword_data.keyword_info.monthly_searches[0].search_volume -
        a.keyword_data.keyword_info.monthly_searches[0].search_volume)
      this.showLocalResults = this.localPackAtRank3;
      //console.log(this.showLocalResults);
    }
  }


  goToSelectedSection(selectedSection) {
    //console.log(selectedSection);
    document.getElementById(selectedSection).scrollIntoView();
  }

  showMoreContent(content) {
    content.show = !content.show;
  }

  parseInteger(displayvalue) {
    return parseInt(displayvalue);
  }
  checkStatus() {
    if (this.onPageSummaryStatus == "completed") {
      return true;
    }
    else {
      return false;
    }
  }
  submitFeedback() {
    if (this.feedbackText) {
      const feedback: any = {
        date: new Date(),
        feedbackText: this.feedbackText
      }
      this.rankCheckerService.getPreviousResult(this.docId).then(
        async (result) => {
          result.feedback = feedback;
          await this.rankCheckerService.updatePracticeDetails1(this.docId, result);
        }
      );
      this.feedbackSubmitted = true;
    }
  }

  editFeedBAck() {
    this.feedbackSubmitted = false;
  }

  checkFeedBack() {
    this.rankCheckerService.getPreviousResult(this.docId).then(
      (result) => {
        if (result.feedback) {
          this.feedbackText = result.feedback.feedbackText;
          this.feedbackSubmitted = true;
        }
      }
    );
  }
  newTab(url) {
    //console.log(url)
    window.open(url);
  }

  sortReviews(arr, key) {
    return (function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0
      }
      if (a[key] > b[key]) {
        return -1;
      }
      else if (a[key] < b[key]) {
        return 1;
      }
      else {
        return 0;
      }
    });
  }

  checkVal(val) {
    //console.log(isNaN(val));
    //console.log(val);
    return isNaN(val);
  }

  openReportInNewTab() {
    window.open(window.location.href, "_blank");
  }

 async addDomainToIgnoreList(url:string,index:number){
      this.competitorsReportObj.results[index]['ignoredDomain'] = true
      let result = await this.rankCheckerService.addDomainToIgnoreList(url);
      if(result['status'] =='ok'){
        this.openSnackBar("Domain added to ignore list  successfully");
        this.showReprocessCompetitorsActivity = true;
      }
      if(result['status'] =='exists'){
        this.openSnackBar("Domain already exists");
      }
  }

  confirm(domain:string) {
    console.log(domain);
    if(domain){
      this.confirmationService.confirm({
          message: 'Are you sure that you want to add this domain to ignore list?',
          accept: () => {
              // this.addDomainToIgnoreList(domain);
          },
          reject: () =>{
            // //console.log("NO");
            // this.addDomainToIgnoreList(url);
          }
      });
    }
    else{
      this.openSnackBar("Domain not exists");
    }
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, '', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  async ReprocessCompetitors(){
    console.log("reprocess competitors");
    this.competitorsReportObj.status = false;
    this.showReprocessCompetitorsActivity = false;
    let response = await (await this.rankingReportService.reGenerateCompetitors(this.docId))['data'];
    console.log(response);
    if('seoId' in response && 'resultsId' in response){
      this.rankingReportService.setCompetitors(response['seoId'],response['resultsId'])
    }
  } 
}
