<form [formGroup]="secondFormGroup" fxLayout="column" class="p-2" autocomplete="off" autofill="off">
  <ng-template matStepLabel>Confirm Practice Details
  </ng-template>

  <!-- <section class="py-3" fxFlex="80%">
    <mat-form-field class="mx-2" fxFlex="100%">
      <input matInput type="text" placeholder="Practice Name" formControlName="practiceName" required>
      <mat-error *ngIf="f2.practiceName.invalid && (f2.practiceName.dirty || f2.practiceName.touched)">Please Enter
        practice Details
      </mat-error>
    </mat-form-field>
  </section> -->

  <div class="row out">
    <div class="col">
      <mat-form-field class="col1">
        <input matInput type="text" placeholder="Practice Name" formControlName="practiceName" required>
        <mat-error *ngIf="f2.practiceName.invalid && (f2.practiceName.dirty || f2.practiceName.touched)">Please Enter
          practice Details
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <!-- <section class="py-3" fxFlex="80%">
    <mat-form-field class="mx-2" fxFlex="100%">
      <input matInput type="text" placeholder="Street Adress" formControlName="streetAdress" required>
      <mat-error *ngIf="f2.streetAdress.invalid && (f2.streetAdress.dirty || f2.streetAdress.touched)">Please Enter
        Street Adress
      </mat-error>
    </mat-form-field>
  </section> -->

  <div class="row out">
    <div class="col">
      <mat-form-field class="col1">
        <input matInput type="text" placeholder="Street Adress" formControlName="streetAdress" required>
        <mat-error *ngIf="f2.streetAdress.invalid && (f2.streetAdress.dirty || f2.streetAdress.touched)">Please Enter
          Street Adress
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row out">
    <div class="col">
      <mat-form-field class="col1">
        <input matInput type="text" placeholder="City" formControlName="city" required>
        <mat-error *ngIf="f2.city.invalid && (f2.city.dirty || f2.city.touched)">Please Enter City Name</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- <section class="py-3" fxFlex="80%"> -->
  <div class="row out">
    <div class="col-md-6">
      <mat-form-field class="col1">
        <input matInput type="text" placeholder="State " formControlName="state" required>
        <mat-error *ngIf="f2.state.invalid && (f2.state.dirty || f2.state.touched)">Please Enter State Name</mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="col2">
        <input matInput type="text" placeholder="{{country_code == 'IN'?'Pincode':'Zip Code'}}" formControlName="zipCode" required>
        <mat-error *ngIf="f2.zipCode.invalid && (f2.zipCode.dirty || f2.zipCode.touched)">
          Please Enter Valid zip Code
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- </section> -->
  <!-- <section class="py-3" fxFlex="80%">
    <mat-form-field class="mx-2" fxFlex="50%">
      <input matInput type="text" placeholder="Country " formControlName="country" required>
      <mat-error *ngIf="f2.country.invalid && (f2.country.dirty || f2.country.touched)">Please Enter Country Name
      </mat-error>
    </mat-form-field>
    &nbsp;
    <mat-form-field class="mx-2" fxFlex="50%">
      <input matInput type="text" placeholder="Website" formControlName="website" required>
      <mat-error *ngIf="f2.website.invalid && (f2.website.dirty || f2.website.touched)">
        Please Enter Website
      </mat-error>
    </mat-form-field>
  </section> -->
  <div class="row out">
    <div class="col-md-6">
      <mat-form-field class="col1">
        <!-- <input matInput type="text" placeholder="Country " formControlName="country" required>
        <mat-error *ngIf="f2.country.invalid && (f2.country.dirty || f2.country.touched)">Please Enter Country Name
        </mat-error> -->
        <mat-label>Country</mat-label>
        <mat-select formControlName="country" (selectionChange)="getCountry($event.value)">
          <mat-option *ngFor="let content of languageResponse.resp" [value]="content['location_name']">{{content['location_name']}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6">
      <mat-form-field class="col2">
        <input matInput type="text" placeholder="Website" formControlName="website" required>
        <mat-error *ngIf="f2.website.invalid && (f2.website.dirty || f2.website.touched)">
          Please Enter Website
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row out" *ngIf="showDropDownForLanguage">
    <div class="col">
      <mat-form-field class="col2">
        <mat-label>Select the Language</mat-label>
        <mat-select (selectionChange)="getLanguage($event.value)" [value]="available_languages[0]['language_name']">
          <mat-option *ngFor="let content of available_languages" [value]="content['language_name']">{{content['language_name']}}</mat-option>
        </mat-select>
      </mat-form-field>
    <!-- </div> -->
    <!-- <div class="row out" *ngIf="!languageaAndLocationInfo['hideDropDown']">
      <div class="col">
        <mat-form-field class="mx-2" fxFlex="100%">
            <input type="text" placeholder="Select Location Name" aria-label="Number" matInput
                [matAutocomplete]="auto" (input)="onLanguageNameChange($event.target.value)" formControlName="language">
            <mat-autocomplete (optionSelected)='getLanguageName($event.option.value)' autoActiveFirstOption
                #auto="matAutocomplete">
                <mat-option *ngFor="let option of languageaAndLocationInfo.contentOfLanguage;let i=index" [value]="option.language_name">
                    {{option.language_name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
      </div> -->
      <!-- <div class="col-md-6">
        <mat-form-field class="mx-2" fxFlex="100%">
          <input type="text" placeholder="Select Language Code" aria-label="Number" matInput
              [matAutocomplete]="auto1" (input)="onLanguageCodeChange($event.target.value)" required>
          <mat-autocomplete (optionSelected)='getLanguageCode($event.option.value)' autoActiveFirstOption
              #auto1="matAutocomplete">
              <mat-option *ngFor="let option of languageaAndLocationInfo.contentOfLanguage" [value]="option.language_code">
                  {{option.language_code}}
              </mat-option>
          </mat-autocomplete>
      </mat-form-field>
      </div> -->
    </div>
  </div>
</form>
