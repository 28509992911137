<form [formGroup]="thirdFormGroup" fxLayout="column" class="p-2" autocomplete="off" autofill="off">
  <section class="py-3" fxFlex="80%">
    <mat-form-field class="mx-2" fxFlex="100%">
      <input matInput type="text" placeholder="Full Name" formControlName="fullName" required>
      <mat-error *ngIf="f3.fullName.invalid && (f3.fullName.dirty || f3.fullName.touched)">This is
        required field
      </mat-error>
    </mat-form-field>
  </section>
  <section class="py-3" fxFlex="80%">
    <mat-form-field class="mx-2" fxFlex="100%">
      <mat-label *ngIf="rankingSttings?.emailVerification">Email (Email Verification)</mat-label>
      <mat-label *ngIf="!rankingSttings?.emailVerification">Email</mat-label>
      <input matInput type="email" formControlName="email" required>
      <!-- <span matSuffix *ngIf="rankingSttings?.emailVerification && f3.email.value?.length==0"
        style="color: #949494;">Email
        Verification</span> -->
      <mat-error *ngIf="f3.email.invalid && (f3.email.dirty || f3.email.touched)">Please Enter
        Valid Email
      </mat-error>
    </mat-form-field>
  </section>
  <section class="py-3" fxFlex="80%">
    <!-- <div class="mx-2" fxFlex="5%" class='countryCode'>
      <button style="width:5px;" mat-button matTooltip="Change country code" [matMenuTriggerFor]="belowMenu"><span
          style="font-size: 16px;">{{thirdFormGroup.controls.countryCode.value}}</span><span class="material-icons">
          keyboard_arrow_down
        </span></button>
      <div style="max-height: 200px;overflow: hidden;">
        <mat-menu #belowMenu="matMenu" yPosition="below" autoActiveFirstOption>
          <!-- <div>
            <input type="text" id="searchInput" pInputText placeholder="Search by Country"
              (keyup)="searchCountry($event)" (click)="$event.stopPropagation()" formControlName="countryCodeNumber"
              size="30">
          </div> --
          <div style="height: 200px;
          overflow-y: auto;">
            <button *ngFor="let country of searchedCountries;let i = index;trackBy: trackByFn"
              (click)="slectedCountry(country.dial_code)" mat-menu-item>{{country.name}}
              {{country.dial_code}}</button>
          </div>
        </mat-menu>
      </div>
    </div> -->
    <div class="mx-2" fxFlex="5%" class='countryCode'>
      <button style="width:5px;" mat-button matTooltip="Change country code" [matMenuTriggerFor]="belowMenu"><span
          style="font-size: 16px;">{{thirdFormGroup.controls.countryCode.value}}</span><span class="material-icons">
          keyboard_arrow_down
        </span></button>
      <div style="max-height: 200px;overflow: hidden;">
        <mat-menu #belowMenu="matMenu" yPosition="below" autoActiveFirstOption>
          <div>
            <input type="text" id="searchInput" #searchInput1 (blur)="searchInput1.focus()" pInputText
              placeholder="Search by Country / Code" (keyup)="searchCountry($event)" (click)="$event.stopPropagation()"
              formControlName="countryCodeNumber" size="30" style="margin-left: 7px; padding: 5px;">
          </div>
          <div style="height: 200px;
              overflow-y: auto;">
            <button *ngFor="let country of searchedCountries;let i = index;trackBy: trackByFn"
              (click)="slectedCountry(country.dial_code)" mat-menu-item>{{country.name}}
              {{country.dial_code}}</button>
          </div>
        </mat-menu>
      </div>
    </div>
    <!--
      <mat-menu #belowMenu="matMenu" yPosition="below">
        <input type="search" style="display: block;width:auto;" pInputText placeholder="Country Code"
          (click)="$event.stopPropagation()" formControlName="countryCodeNumber" size="30">
        <div style="height: 200px;">
          <button *ngFor="let country of filteredCountries | async" (click)="slectedCountry(country.dial_code)"
            mat-menu-item>{{country.name}}
            {{country.dial_code}}</button>
        </div>
      </mat-menu>
      <mat-form-field class="mx-2" fxFlex="20%">
      <input type="search"  placeholder="Country Code" aria-label="Number" matInput formControlName="countryCode"
        [matAutocomplete]="auto" required>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let country of filteredCountries | async" [value]="country.dial_code">
          {{country.name}} {{country.dial_code}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="f3.countryCode.invalid && (f3.countryCode.dirty || f3.countryCode.touched)">
        Please Enter or Select Proper Country Code
      </mat-error>
    </mat-form-field> -->

    <!-- <mat-form-field class="mx-2" fxFlex="5%">
            <mat-select  placeholder="Country Code" formControlName="countryCode">
                <mat-label>Country Code</mat-label>
                <mat-option *ngFor="let code of country_calling_codes" [value]="code">
                    {{code}}
                </mat-option>
            </mat-select>
        </mat-form-field  <div></di> -->
    <div class="mx-2" id="cellNumber">
      <mat-form-field class="mx-2" fxFlex="100%">
        <mat-label *ngIf="rankingSttings?.phoneVerification">Cell (Text Verification)</mat-label>
        <mat-label *ngIf="!rankingSttings?.phoneVerification">Cell</mat-label>
        <input (click)="$event.stopPropagation()" matInput type="text" formControlName="cellNumber" required>
        <!-- <mat-hint>Text Verification</mat-hint> -->
        <!-- <span matSuffix *ngIf="rankingSttings?.phoneVerification && f3.cellNumber.value?.length==0"
          style="color: #949494;">Text Verification</span> -->
        <mat-error *ngIf="f3.cellNumber.invalid && (f3.cellNumber.dirty || f3.cellNumber.touched)">
          Please Enter 10 Digit Cell Number
        </mat-error>
      </mat-form-field>
    </div>

  </section>
  <section class="py-3" fxFlex="80%">
    <!-- <div class="mx-2" fxFlex="100%"> -->
    <div class="row">
      <div style="padding-left: 15px;"></div>
      <mat-checkbox formControlName="declaration" required style="padding-left: 10px;">
        Verification code sent to email or cell.
      </mat-checkbox>
      <!-- <div style="padding-left: 15px;margin-top: -0.2em;">
        Verification code sent to email or cell number.
      </div> -->
    </div>
    <!-- </div> -->
  </section>
</form>
