import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from "@angular/router";


import { RankCheckerComponent } from "./rank-checker/rank-checker.component";
import { ConfirmPracticeDetailsComponent } from "./rank-checker/confirm-practice-details/confirm-practice-details.component";
import { ContactInfoComponent } from "./rank-checker/contact-info/contact-info.component";
import { FindPracticeDetailsComponent } from "./rank-checker/find-practice-details/find-practice-details.component";
import { GetPracticeDetailsComponent } from "./rank-checker/get-practice-details/get-practice-details.component";
import { MatStepperModule } from "@angular/material/stepper";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from '@angular/material/badge';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from "primeng/inputtext";
import { CardModule } from "primeng/card";
import { TableModule } from "primeng/table";
import { TooltipModule } from 'primeng/tooltip';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { HttpClientModule } from '@angular/common/http';
import firebase from "firebase/app";
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OTPVerificationComponent } from './otp-verification/otp-verification.component';
import { GoogleListingComponent } from './rank-checker/google-listing/google-listing.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgOtpInputModule } from 'ng-otp-input';
import { RankCheckerResultsComponent } from './rank-checker/rank-checker-results/rank-checker-results.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { RankCheckerService } from './_services/rank-checker.service';
import { RankingReportComponent } from './ranking-report/ranking-report.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TimelineModule } from 'primeng/timeline';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
//import { RatingModule } from 'primeng/rating';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
// import {RatingModule} from 'primeng/rating';
import { RatingModule } from 'ng-starrating';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {ChartModule} from 'primeng/chart';
import {KnobModule} from 'primeng/knob';

import { RankingReportLatestComponent } from './ranking-report-latest/ranking-report-latest.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    RankCheckerComponent,
    ContactInfoComponent,
    FindPracticeDetailsComponent,
    GetPracticeDetailsComponent,
    ConfirmPracticeDetailsComponent,
    OTPVerificationComponent,
    GoogleListingComponent,
    RankCheckerResultsComponent,
    RankingReportComponent,
    RankingReportLatestComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    NgOtpInputModule,
    MatBadgeModule,
    TimelineModule,
    MatExpansionModule,
    // MatGridListModule,
    // MatSlideToggleModule,
    // MatButtonToggleModule,
    // MatRadioModule,
    MatAutocompleteModule,
    // MatCheckboxModule,
    MatStepperModule,
    ReactiveFormsModule,
    // MatProgressBarModule,
    // FileUploadModule,
    // MatNativeDateModule,
    // MatTableModule,
    // MatSortModule,
    // MatExpansionModule,
    // MatDatepickerModule,
    // MatDividerModule,
    MatListModule,
    MatTooltipModule,
    MatSnackBarModule,
    // MatChipsModule,
    // MatProgressSpinnerModule,
    // MatPaginatorModule,
    TooltipModule,
    MatCardModule,
    ProgressSpinnerModule,
    InputTextModule,
    TableModule,
    CardModule,
    GooglePlaceModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    DialogModule,
    MatProgressSpinnerModule,
    AccordionModule,
    // SkeletonModule,
    SkeletonModule,
    CarouselModule,
    RatingModule,
    InputTextareaModule,
    AutoCompleteModule,
    KnobModule,
    ChartModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#1b2246",
      innerStrokeColor: "#7f86e7",
      animationDuration: 300,
      showUnits: false,
    })
  ],
  providers: [
    DialogService,
    RankCheckerService,
    ConfirmationService
  ],
  bootstrap: [AppComponent],
  // exports: [
  //   MatBadgeModule,
  // ]
})
export class AppModule { }
