<div *ngIf="!showConfirmDetails">
    <div class="col" *ngIf="googleLocations else NoLocation">
        <div class="row ouside">
            <div class="plan" *ngFor="let content of googleLocations,let i=index">
                <p-card  matBadgeSize="medium"  [matBadgeHidden]="content.badgeHidden" matBadge="done" matBadgePosition="after"
                [style]="{
                  width: '250px',
                  height:'260px',
                  'box-shadow': '0 1px 10px 0 rgba(0, 0, 0, 0.3),0 1px 10px 0 rgba(0, 0, 0, 0.17),0 2px 10px -1px rgba(0, 0, 0, 0.12)'
                  }"
                styleClass="p-card-shadow">
                  <p-header style="height: 20%;">
                    <div class="row">
                      <div class="col-2 loc1"><span class="material-icons">location_on</span></div>
                      <div class="col-8 loc2" [pTooltip]="content.location.locationName" tooltipPosition="top"><b>{{content.location.locationName}}</b></div>
                    </div>
                  </p-header>
                  <p-card-content style="height: 60%;">
                    <div class="row" *ngIf="address.length else showNoAddress">
                      <span class="content" *ngIf="address[i]!=undefined else showNoAddressIn" [pTooltip]="address[i]" tooltipPosition="top"><b>Address: </b>{{address[i]}}</span>
                      <ng-template #showNoAddressIn>
                        <b style="padding:10px;">No Address for this location</b>
                    </ng-template>
                    </div>
                    <ng-template #showNoAddress>
                        <b style="padding:0px;">No Address for this location</b>
                    </ng-template>
                  </p-card-content>
                  <p-footer style="height: 20%;">
                    <center><button mat-raised-button type="button" (click)="selectCard(content,i)" *ngIf="content['badgeHidden']">Add</button></center>
                    <center><button mat-raised-button type="button" (click)="selectCard(content,i)" *ngIf="!content['badgeHidden']">Remove</button></center>
                  </p-footer>
                </p-card>
            </div>
        </div>
        <!-- <div class="row">
          <div class="showdetails">
            <button mat-raised-button color="primary"  [disabled]="hidden" (click)="showForm()">Show</button>
          </div>
        </div> -->
    </div>
  </div>
  <ng-template #NoLocation>
    <div class="center">
      No Location
    </div>
  </ng-template>