import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  @Input() thirdFormGroup: FormGroup;
  @Input() countries: any;
  @Input() rankingSttings: any;
  @Input() languageResponse:any;

  searchedCountries: any;
  filteredCountries: Observable<string[]>;
  constructor() {

  }

  focustoinput1() {
    //this.matmenu.closeMenu();
    document.getElementById('input1').focus();
  }

  ngOnInit(): void {
    this.thirdFormGroup.addControl('countryCodeNumber', new FormControl(''));
    this.filteredCountries = this.countries;
    this.filteredCountries = this.thirdFormGroup.controls.countryCodeNumber.valueChanges.pipe(
      map(val => this.filterCountry(val))
    );
    this.filteredCountries.pipe(
      map(() =>
        this.countries.filter(item => item.name.toLowerCase().indexOf(item) > -1)))
  }

  ngOnChanges() {
    let responseCountries= [];
    console.log(this.languageResponse);
    if(this.languageResponse){
      for(var i=0;i<this.languageResponse.resp.length;i++){
        responseCountries.push(this.languageResponse.resp[i].location_name);
      }
      this.searchedCountries  = this.countries.filter(function(item){
        return responseCountries.indexOf(item.name) > -1;
      });
      // var name=[];
      // for(var i=0;i<this.searchedCountries.length;i++){
      //   name.push(this.searchedCountries[i].name);
      // }
      // let countries  = ["Bolivia", "Myanmar (Burma)", "Czechia", "South Korea", "Venezuela"]
      // console.log(responseCountries);
      // console.log(this.searchedCountries);
      // var arr = responseCountries.filter(function(item){
      //   return name.indexOf(item) === -1;
      // });
      // console.log(arr);
      // this.searchedCountries.push({code: "BOL",dial_code: "+591",name: "Bolivia"});
      // this.searchedCountries.push({code: "MMR",dial_code: "+95",name: "Myanmar (Burma)"});
      // this.searchedCountries.push({code: "KOR",dial_code: "+82",name: "South Korea"});
      // this.searchedCountries.push({code: "VEN",dial_code: "+58",name: "Venezuelaa"});
      // this.searchedCountries.push({code: "CZE",dial_code: "+420",name: "Czechia"});
      // console.log(responseCountries);
      // console.log(this.searchedCountries);
    };

  }
  validateCellNumber() {
    if (!Number(this.thirdFormGroup.controls.cellNumber.value)) {
      let str = this.thirdFormGroup.controls.cellNumber.value
      str = str.substring(0, str.length - 1);
      this.thirdFormGroup.controls.cellNumber.patchValue(str);
    }

  }

  searchCountry(event) {
    // event.preventDefault();
    let val = event.target.value;
    this.searchedCountries = this.countries.filter((option) => {
      // return form.name.toLowerCase().indexOf(str.toLowerCase()) != -1;
      return option.name.toLowerCase().includes(val) || option.dial_code.toLowerCase().includes(val);
    });
  }

  trackByFn(index, item) {
    return index;
  }

  filterCountry(val) {
    val = val.toLowerCase()
    return this.countries.filter(option =>
      option.name.toLowerCase().includes(val) || option.dial_code.toLowerCase().includes(val));
  }
  slectedCountry(countryCode) {
    this.thirdFormGroup.controls.countryCode.patchValue(countryCode);
  }

  setScrollTop() {
    var setScrollTop = document.getElementById('items');
    setScrollTop.scrollTop = 0
  }

  get f3() { return this.thirdFormGroup.controls; }
}
